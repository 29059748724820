import React from "react";
import TagBox, { ITagBoxOptions } from 'devextreme-react/tag-box';
import './multiSelect.scss';
import { ApplyValueMode, SelectAllMode } from "devextreme/common";
import { MultiTagPreparingEvent, SelectAllValueChangedEvent, SelectionChangedEvent } from "devextreme/ui/tag_box";
import { template } from "devextreme/core/templates/template";
import { DxElement, UserDefinedElement } from "devextreme/core/element";

interface ITagMultiSelect extends ITagBoxOptions {
    /**
 * Specifies how the UI component applies values.
 */
    applyValueMode?: ApplyValueMode;
    /**
     * A Boolean value specifying whether or not to hide selected items.
     */
    hideSelectedItems?: boolean;
    /**
     * Specifies the limit on displayed tags. On exceeding it, the UI component replaces all tags with a single multi-tag that displays the number of selected items.
     */
    maxDisplayedTags?: number;
    /**
     * A Boolean value specifying whether or not the UI component is multiline.
     */
    multiline?: boolean;
    /**
     * A function that is executed before the multi-tag is rendered.
     */
    onMultiTagPreparing?: ((e: MultiTagPreparingEvent) => void);
    /**
     * A function that is executed when the &apos;Select All&apos; check box value is changed. Applies only if showSelectionControls is true.
     */
    onSelectAllValueChanged?: ((e: SelectAllValueChangedEvent) => void);
    /**
     * A function that is executed when a list item is selected or selection is canceled.
     */
    onSelectionChanged?: ((e: SelectionChangedEvent) => void);
    /**
     * Specifies the mode in which all items are selected.
     */
    selectAllMode?: SelectAllMode;
    /**
     * Gets the currently selected items.
     */
    selectedItems?: Array<string | number | any>;
    /**
     * Specifies the text displayed at the &apos;Select All&apos; check box.
     */
    selectAllText?: string;
    /**
     * Specifies whether the drop-down button is visible.
     */
    showDropDownButton?: boolean;
    /**
     * Specifies the maximum filter query length in characters.
     */
    maxFilterQueryLength?: number;
    /**
     * Specifies whether the multi-tag is shown without ordinary tags.
     */
    showMultiTagOnly?: boolean;
    /**
     * Specifies a custom template for tags.
     */
    tagTemplate?: template | ((itemData: any, itemElement: DxElement) => string | UserDefinedElement);
    /**
     * Specifies the selected items.
     */
    value?: Array<string | number | any>;
    /**
    * Specifies the data field whose values should be displayed.
    */
    displayExpr?: string | ((item: any) => string);

}

const MultiSelectTag = ({ className,value, displayExpr,dataSource, ...props }: ITagMultiSelect) => {
    return (
        <div className="common-multi-select">
            <TagBox
                displayExpr={displayExpr} 
                dataSource={dataSource}
                className={"basic-ui-select"} 
                value={value}
                {...props}
                />
        </div>
    )
}

export default MultiSelectTag;