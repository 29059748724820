import React, { useCallback, useEffect, useState } from 'react';
import DataGrid, { Column, HeaderFilter, LoadPanel, Pager, Paging, Scrolling, Sorting } from 'devextreme-react/data-grid';
import DynamicPagination from "../Pagination/Pagination";
import { Tooltip } from '@material-ui/core';
import Icon from '../Icon/Icon';
import './DynamicTableList.scss';
import { CheckBox } from 'devextreme-react';

interface DynamicTableListProps {
    dataSource?: any;
    columnAutoWidth?: boolean;
    hoverStateEnabled?: boolean;
    showBorders?: boolean;
    enabled?: any;
    columns?: any;
    focusedRowEnabled?: boolean;
    columnMinWidth?: number;
    mode?: boolean;
    visible?: boolean,
    allowColumnReordering?: boolean;
    wordWrapEnabled?: boolean;
    showPagination?: boolean;
    currentPageNo?: number;
    totalPage?: number;
    isLessthanTotalElement?: boolean
    totalRecord?: number;
    pageSize?: number;
    goToPreviousPage?: any;
    goToNextPage?: any;
    changePageSize?: any;
    columnList?: any;
    orderIndexedFields?: any;
    onSortingData?: any;
    fromComponent?: any;
    showCustomActions?: boolean;
    customActionColumnFormatters?: ((value: any) => React.ReactNode)[];
    showCheckBoxAction?: boolean;
    checkBoxColumnName?: string | number;
    checkBoxKeyExpr?: string;
    checkBoxOnChange?: (checkBoxEvent: any, item: any) => void;
}

const DynamicTableList: React.FC<DynamicTableListProps> = ({
    dataSource,
    columnList,
    columnAutoWidth,
    hoverStateEnabled = true,
    showBorders = true,
    enabled = false,
    columns,
    focusedRowEnabled = false,
    mode = false,
    visible = false,
    allowColumnReordering = false,
    wordWrapEnabled = true,
    columnMinWidth,
    showPagination = false,
    currentPageNo,
    goToPreviousPage,
    goToNextPage,
    totalPage,
    changePageSize,
    totalRecord,
    isLessthanTotalElement,
    orderIndexedFields,
    onSortingData,
    showCustomActions,
    customActionColumnFormatters,
    showCheckBoxAction,
    checkBoxColumnName,
    checkBoxKeyExpr,
    checkBoxOnChange,
    ...props
}
) => {
    const [order, setOrder] = useState<any>('desc');
    const [orderBy, setOrderBy] = useState<any>();
    const [orderedData, setOrderedData] = useState<any>(orderIndexedFields);
    const [columnSortState, setColumnSortState] = useState<any>({});


    const headerRenderColumnByName = useCallback((headerDbName: string, headerName: string, sort = false) => {
        const sortState = columnSortState[headerDbName] || { order: 'desc', isActive: false };
        if (sort === true) {
            const HeaderRenderComponent = () => {
                return (
                    <div
                        className="table-sorts"
                    >
                        {headerName}
                        <Tooltip title={sortState.order === 'asc' ? "ascending" : "descending"}>
                            <div className="sort-icon">
                                <Icon
                                    size="14px"
                                    onClick={() => handleSorting(headerDbName, (sortState.order === "asc" ? "desc" : "asc"))}
                                    iconName={sortState.order === "asc" ? "arrowup" : "arrowdown"}
                                    className={`arrowicons ${sortState.isActive ? 'active' : ''}`}
                                />
                            </div>
                        </Tooltip>
                    </div>
                )
            }
            return HeaderRenderComponent;
        }
        const DefaultHeaderRendererComponent = () => {
            return (
                <div className='grid-header'>{headerName}</div>
            )
        }
        return DefaultHeaderRendererComponent;
    }, [order, orderBy]);

    const handleSorting = (orderBy: any, order: string): any => {
        setOrder(order);
        setOrderBy(orderBy);
        const updatedSortState = { ...columnSortState };
        updatedSortState[orderBy] = {
            order,
            isActive: true,
        };

        setColumnSortState(updatedSortState);

        const data = orderIndexedFields?.map((item: any) => ({ ...item, order: 'desc' }));

        const index = data?.findIndex((obj: any) => obj.orderBy === orderBy);
        data[index].order = order === 'desc' ? 'desc' : 'asc';
        const index1 = data.findIndex((obj: any) => obj.isActive);
        if (index1 > -1) {
            data[index1].isActive = false;
        }
        data[index].isActive = true;
        setOrderedData(data);
    };

    useEffect(() => {
        if (order && orderBy && onSortingData && orderedData) {
            onSortingData(order, orderBy, orderedData)
        }
    }, [order, orderBy]);

    const checkBoxFormatter = useCallback((value) => {
        return (
            <CheckBox
                value={(value?.data[checkBoxKeyExpr] || false)}
                onValueChange={(checkBoxEvent: any) => checkBoxOnChange(checkBoxEvent, value)}
                iconSize={18}
            />
        )
    }, [checkBoxKeyExpr, checkBoxOnChange]);
    const gridKey = dataSource ? dataSource.length : 0;
    return (
        <>
            <DataGrid
                className={`planning-grid ${props.fromComponent === 'BPMComponent' ? 'bpm-table' : props.fromComponent === 'EnvelopeListComponent' ? 'envelopelist-table' : props.fromComponent === 'FileListComponent' ? 'filelist-table' : ''}`}
                key={gridKey}
                dataSource={dataSource}
                columnAutoWidth={columnAutoWidth}
                hoverStateEnabled={hoverStateEnabled}
                showBorders={showBorders}
                height='100%'
                focusedRowEnabled={focusedRowEnabled}
                allowColumnReordering={allowColumnReordering}
                wordWrapEnabled={wordWrapEnabled}
                allowColumnResizing={true}
                columnResizingMode="widget"
                columnMinWidth={columnMinWidth}
                scrolling={{
                    mode: 'standard',
                    rowRenderingMode: 'standard',
                    showScrollbar: 'always',
                    useNative: false
                }}
                {...props}
            >
                <LoadPanel enabled={enabled} />
                {/* <Scrolling mode='virtual' /> */}
                <Sorting mode='none' />
                <HeaderFilter visible={visible} />
                <Paging enabled={false} />
                {
                    columnList ? columnList.map((columnObject: any) => {
                        return (
                            <Column
                                headerCellRender={headerRenderColumnByName(columnObject.db, columnObject.name, columnObject.sort)}
                                key={columnObject.name}
                                dataField={columnObject.db}
                                alignment='left'
                                {...columnObject}
                            />
                        )
                    }) : null
                }
                {
                    showCheckBoxAction ?
                        <Column
                            dataField={checkBoxColumnName ? checkBoxColumnName : "Select"}
                            cellRender={checkBoxFormatter}
                        /> : null
                }
                {
                    showCustomActions ?
                        customActionColumnFormatters?.map((ColumnFormatter) => {
                            return (
                                <Column alignment='left' dataField={'Actions'} cellRender={ColumnFormatter} />
                            )
                        })
                        : null
                }


            </DataGrid>
            {
                showPagination &&
                <DynamicPagination
                    currentPageNo={currentPageNo}
                    goToPreviousPage={goToPreviousPage}
                    goToNextPage={goToNextPage}
                    totalPage={totalPage}
                    dataLength={dataSource?.length || 0}
                    isLessthanTotalElement={isLessthanTotalElement}
                    changePageSize={changePageSize}
                    totalRecord={totalRecord}
                />
            }
        </>
    );
};

export default React.memo(DynamicTableList);
