import React from "react";
import "./userProfileIcon.scss";

interface IUserProfileIconProps {
    title?: string | null;
    height?: string | number;
    width?: string | number;
    id?: string;
    style?: React.CSSProperties;
    className?: string;
    onClickHandler?: (clickEvent: any) => void;
}

function UserProfileIcon({ title = "", height, width, id, style, className, onClickHandler, ...props }: IUserProfileIconProps) {
    const customStyle = { height: height, width: width, ...style };

    return (
        <div style={{ ...customStyle, ...style }} className={`profile-icon-container ${className ? className : ""}`}>
            <div onClick={onClickHandler} className="profile-icon-circle" aria-hidden='true'><span>{title}</span></div>
        </div>
    );
}

export default React.memo(UserProfileIcon);