import { MESSAGE_ERRORS, MESSAGE_ERRORS_CLEAR, MESSAGE_SUCCESS, MESSAGE_HIDE } from '../types/messageTypes';

const initialState = {
  errors: null,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case MESSAGE_ERRORS:
      return action.payload;
    case MESSAGE_ERRORS_CLEAR:
      return {
        ...state,
        errors: null
      };
    case MESSAGE_SUCCESS:
      return {
        ...state,
        success: action.payload
      };
    case MESSAGE_HIDE:
      return {
        success: null,
        errors: null
      }
    default:
      return state;
  }
}
