import * as React from 'react';
export function EyeOopen({ fill = '#fff', width = 25, height = 25, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" id={id} onClick={onClick}>
            <g fill="none" fill-rule="evenodd">
                <rect fill="#0078D4" width="20" height="20" rx="3" />
                <path d="M10 7C8.09 7 6.357 8.045 5.078 9.743a.396.396 0 0 0 0 .473C6.357 11.916 8.09 12.96 10 12.96c1.91 0 3.643-1.045 4.922-2.743a.396.396 0 0 0 0-.473C13.643 8.045 11.91 7 10 7zm.137 5.08a2.105 2.105 0 0 1-2.236-2.237 2.108 2.108 0 0 1 1.962-1.961 2.105 2.105 0 0 1 2.236 2.236 2.115 2.115 0 0 1-1.962 1.961zm-.063-.97A1.13 1.13 0 0 1 8.87 9.907a1.137 1.137 0 0 1 1.057-1.058 1.13 1.13 0 0 1 1.203 1.203 1.137 1.137 0 0 1-1.057 1.058z" fill="#FFF" fill-rule="nonzero" />
            </g>
        </svg>
    )
}