import isEmpty from '../../utils/validation/is-empty';

import { AUTH_SET_CURRENT_USER, AUTH_CHANGE_PASSWORD } from '../types/authTypes';

const initialState = {
  isAuthenticated: false,
  isPasswordChange: false,
  user: {},
  admin: false
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case AUTH_SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.user
      };
    case AUTH_CHANGE_PASSWORD:
      return {
        ...state,
        isPasswordChange: action.payload
      }
    default:
      return state;
  }
}
