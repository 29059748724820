import * as React from 'react';

export function Wbincandescent({ fill = '#0078d4', width, height, className }: any) {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <g fill="#0078D4" fill-rule="nonzero">
                <path d="M15 4.321a.938.938 0 0 1-.938-.937V.938a.938.938 0 0 1 1.876 0v2.446c0 .517-.42.937-.938.937zM23.214 7.724a.938.938 0 0 1-.663-1.601l1.73-1.73a.938.938 0 1 1 1.327 1.326l-1.73 1.73a.94.94 0 0 1-.664.275zM29.063 15.938h-2.447a.938.938 0 0 1 0-1.876h2.447a.938.938 0 0 1 0 1.876zM24.944 25.881a.93.93 0 0 1-.663-.275l-1.73-1.73a.938.938 0 1 1 1.327-1.326l1.73 1.73a.938.938 0 0 1-.664 1.601zM5.056 25.881a.938.938 0 0 1-.663-1.601l1.73-1.73a.938.938 0 1 1 1.327 1.326l-1.73 1.73a.935.935 0 0 1-.664.275zM3.384 15.938H.938a.938.938 0 0 1 0-1.876h2.446a.938.938 0 0 1 0 1.876zM6.786 7.724a.93.93 0 0 1-.662-.275l-1.73-1.73A.938.938 0 1 1 5.72 4.392l1.73 1.73a.938.938 0 0 1-.664 1.602zM18.75 26.25v1.563c0 1.2-.988 2.187-2.188 2.187h-3.125c-1.05 0-2.187-.8-2.187-2.55v-1.2h7.5zM20.512 8.2c-2.05-1.662-4.75-2.312-7.387-1.75-3.313.688-6 3.388-6.688 6.7-.7 3.4.575 6.775 3.3 8.838.738.55 1.25 1.4 1.425 2.387v.012c.025-.012.063-.012.088-.012h7.5c.025 0 .038 0 .063.012v-.012c.175-.95.737-1.825 1.6-2.5A8.732 8.732 0 0 0 23.75 15a8.715 8.715 0 0 0-3.238-6.8zm-.825 7.425a.944.944 0 0 1-.937-.938c0-1.9-1.538-3.437-3.438-3.437a.944.944 0 0 1-.937-.938c0-.512.425-.937.938-.937a5.324 5.324 0 0 1 5.312 5.313.944.944 0 0 1-.938.937z" />
                <path d="M11.162 24.375h.088c-.025 0-.063 0-.088.012v-.012zM18.813 24.375v.012c-.025-.012-.038-.012-.063-.012h.063z" />
            </g>
        </svg>

    );
}
