import themes from "devextreme/ui/themes";
import { CookieStorage } from 'cookie-storage';
import { refreshTheme } from "devextreme/viz/themes"
const cookies = new CookieStorage();




class ThemeService {

    darkThemeHandler() {
        document.documentElement.style.setProperty('--url-color', '#fff');
        document.documentElement.style.setProperty('--header-title-color', '#fff');
        document.documentElement.style.setProperty('--font-color-default', '#fff');
        document.documentElement.style.setProperty('--background-color-default', '#363640');
        document.documentElement.style.setProperty('--background--default-color', '#000000');
        document.documentElement.style.setProperty('--border-color-default', '#515159');
        document.documentElement.style.setProperty('--icon-color-default', 'rgba(255, 255, 255, .54)');
        document.documentElement.style.setProperty('--tabs-background-color', '#363640');
        document.documentElement.style.setProperty('--tabs-bottom-color', 'rgb(97, 97, 97)');
        document.documentElement.style.setProperty('--main-background-color', '#363640');
        document.documentElement.style.setProperty('--table-header-background', '#363640');
        document.documentElement.style.setProperty('--table-body-background', '#363640');
        document.documentElement.style.setProperty('--sub-table-background', '#363640');
        document.documentElement.style.setProperty('--table-color', '#fff');
        document.documentElement.style.setProperty('--drawer-text-color', '#fff');
        document.documentElement.style.setProperty('--table-body-header', '#49444d');
        document.documentElement.style.setProperty('--table-body-row', '#363640');
        document.documentElement.style.setProperty('--nested--table-header', '#363640');
        document.documentElement.style.setProperty('--react-google-chart-background', '#363640');
        document.documentElement.style.setProperty('--table-body-border-bottom', '#24232a');
        document.documentElement.style.setProperty('--table-header-border-bottom', '#5a5a5a');
        document.documentElement.style.setProperty('--select-box-border-color', '#49444d');
        document.documentElement.style.setProperty('--select-shcema-background-color', '#000')
        document.documentElement.style.setProperty('--active-sidebar-color', '#fff');
    }

    lightThemeHandler() {
        document.documentElement.style.setProperty('--url-color', '#000');
        document.documentElement.style.setProperty('--header-title-color', '#0078d4');
        document.documentElement.style.setProperty('--font-color-default', '#000');
        document.documentElement.style.setProperty('--background-color-default', '#fff');
        document.documentElement.style.setProperty('--background--default-color', '#efefef');
        document.documentElement.style.setProperty('--border-color-default', '#0000001F');
        document.documentElement.style.setProperty('--icon-color-default', 'rgba(0, 0, 0, 0.54)');
        document.documentElement.style.setProperty('--tabs-background-color', '#fff');
        document.documentElement.style.setProperty('--tabs-bottom-color', 'rgb(224, 224, 224)');
        document.documentElement.style.setProperty('--main-background-color', 'rgb(250, 250, 250)');
        document.documentElement.style.setProperty('--table-header-background', '#EFF2F7');
        document.documentElement.style.setProperty('--table-body-background', '#FBFCFF');
        document.documentElement.style.setProperty('--sub-table-background', '#f0f4fa');
        document.documentElement.style.setProperty('--table-color', '#495057');
        document.documentElement.style.setProperty('--drawer-text-color', '#222222');
        document.documentElement.style.setProperty('--table-body-header', '#e8ebf0');
        document.documentElement.style.setProperty('--nested--table-header', '#dbe1ea');
        document.documentElement.style.setProperty('--table-body-row', '#eef0f4');
        document.documentElement.style.setProperty('--react-google-chart-background', '#fff');
        document.documentElement.style.setProperty('--table-body-border-bottom', '#fff');
        document.documentElement.style.setProperty('--table-header-border-bottom', '#fff');
        document.documentElement.style.setProperty('--select-box-border-color', '#979797');
        document.documentElement.style.setProperty('--select-shcema-background-color', '#efefef')
        document.documentElement.style.setProperty('--active-sidebar-color', '#ef5777')
    }

    getCurrentTheme() {
        return themes.current();
    }

    setCurrentTheme(themeName: string) {
        document.cookie = "dx-theme=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        cookies.setItem("dx-theme", themeName);
        cookies.setItem("theme", themeName);

        if (themeName.split('.').includes('dark')) {
            this.darkThemeHandler()
        }
        else {
            this.lightThemeHandler()
        }
        return themes.current(themeName);
    }



    public getThemeNameById(themeName: string | null, arrayOfThemes: Array<any>) {
        let selectedtheme = arrayOfThemes[0];
        arrayOfThemes.forEach((item, index) => {
            if (item.value === themeName)
                selectedtheme = item;
        });
        return selectedtheme;
    }


    initializeTheme() {
        //IntializeTheme from cookies if available else set default theme material.blue.light
        document.cookie = "dx-theme=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        const themeName = cookies.getItem("theme");
        if (themeName) {
            if (themeName.split('.').includes('dark')) {
                this.darkThemeHandler()
            }
            else {
                this.lightThemeHandler()
            }
            themes.current(themeName);
        }
        else {
            cookies.setItem("dx-theme", "material.blue.light");
            cookies.setItem("theme", "material.blue.light");
            themes.current('material.blue.light');
        }
        this.refreshTheme();
    }

    initialized(callback: Function) {
        themes.initialized(callback);
    }

    isThemeDark() {
        const isDarkMode = themes.current().split('.').includes('dark');
        return isDarkMode;
    }
    refreshTheme() {
        return refreshTheme();
    }


    // For setting themes directly
    setMaterialBlueLight() {
        const value = 'material.blue.light'
        this.lightThemeHandler();
        return themes.current(value);
    }
    setMaterialBlueDark() {
        const value = 'material.blue.dark'
        this.darkThemeHandler();
        return themes.current(value);
    }
    setMaterialBlueCompactLight() {
        const value = 'material.blue.compact.light'
        this.lightThemeHandler();
        return themes.current(value);
    }
    isMaterial(theme: string) {
        return themes.isMaterial(theme)
    }

}
export default ThemeService