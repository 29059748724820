import React from "react";

// Images
import { Api, Leadership } from "../../../../../../../assets/svg";

// Scss
import './ProductDocument.scss';


const products = [
    { id: 1, "productDocument": "API Document", icon: <Api /> },
    { id: 2, "productDocument": "Admin Document", icon: <Leadership /> }
]


interface IDocumentProps {
    onClick?: (lDocument: any) => void;
    title?: string
}


function ProductDocument({ title, onClick, ...props }: IDocumentProps) {
    return (
        <div className="product-document">
            <div className="title">Documentation</div>
            <div className="middle">
                {
                    products.map((item, index) => (
                        <div className="wrapper" key={index} aria-hidden='true' onClick={() => onClick(item.productDocument)}>
                            <div className="icon-wrapper">{item.icon}</div>
                            <p className="product-title">{item.productDocument}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ProductDocument;