import * as React from 'react';

export function AccountTreeIcons({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg id={id} className={className} style={{ cursor: 'pointer' }} width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g fill="none" fill-rule="evenodd">
                <rect fill="#0078D4" width="30" height="30" rx="5" />
                <path d="M19.364 9.545c0 .602.489 1.091 1.09 1.091h1.455c.602 0 1.091-.489 1.091-1.09V8.09C23 7.489 22.51 7 21.91 7h-1.455c-.602 0-1.091.49-1.091 1.09h-2.91c-.801 0-1.454.653-1.454 1.455v4.728h-2.182v-.728c0-.802-.653-1.454-1.454-1.454h-2.91c-.801 0-1.454.652-1.454 1.454v2.91c0 .802.653 1.454 1.455 1.454h2.909c.801 0 1.454-.652 1.454-1.454v-.728H15v4.728c0 .802.653 1.454 1.455 1.454h2.909c0 .602.489 1.091 1.09 1.091h1.455c.602 0 1.091-.49 1.091-1.09v-1.455c0-.602-.49-1.091-1.09-1.091h-1.455c-.602 0-1.091.489-1.091 1.09h-2.91v-4.727h2.91c0 .602.489 1.091 1.09 1.091h1.455c.602 0 1.091-.49 1.091-1.09v-1.455c0-.602-.49-1.091-1.09-1.091h-1.455c-.602 0-1.091.49-1.091 1.09h-2.91V9.546h2.91z" fill="#FFF" fill-rule="nonzero" />
            </g>
        </svg>

    );
}
