import React, { useEffect } from 'react'
import './new-sub-header.scss';
import { DropDownButton, TextBox } from 'devextreme-react';
import { Tooltip as Tooltips } from 'devextreme-react';
import Buttons from '../commonui/Button';
import Icon from '../commonui/Icon/Icon';
import { BackArrow } from '../../../../assets/svg';

interface NewSubHeaderProps {
  headingText: string;
  showFilter?: boolean;
  filterHeading?: string;
  filterArray?: any;
  userStatus?: any;
  filterUserStatus?: any;
  showSearch?: boolean;
  searchLabel?: string;
  searchKey?: any;
  onCancelSearch?: any;
  onSearchData?: any;
  showBtn?: boolean;
  btnText?: string;
  blurBtn?: boolean,
  onButtonClick?: any;
  btnText2?: string;
  showBtn2?: boolean;
  onButtonClick2?: (arg: any) => void;
  btnText3?: string;
  showBtn3?: boolean;
  onButtonClick3?: (arg: any) => void;
  isbackEnabled?: boolean;
  onBackButtonClick?: () => void;
}

NewSubHeaderComponent.defaultProps = {
  showFilter: false,
  blurBtn: false,
  isbackEnabled: false
}
export default function NewSubHeaderComponent(props: NewSubHeaderProps) {
  const [userStatus, setUserStatus] = React.useState('');
  const [searchData, setSearchData] = React.useState<any>(props.searchKey || '');

  const onChangeUserStatus = (status: any) => {
    setUserStatus(status);
  }

  const handleChangeSearch = (text: any) => {
    setSearchData(text.event.target.value);
    props.onSearchData(text.event.target.value);
  };
  useEffect(() => {

  }, [searchData]);
  useEffect(() => {
    if (userStatus) {
      props.filterUserStatus(userStatus);
    }
  }, [userStatus]);
  return (
    <div className='main-header'>
      <div className='main-title'>
        {
          props.isbackEnabled ? <BackArrow onClick={props.onBackButtonClick} /> : null
        }
        <span className='title'>{props.headingText}</span>
      </div>
      <div className="subheader-filter">
        {props.showFilter &&
          <div className="user-filter">
            <span className="statuslabel">{props.filterHeading} </span>
            <div className="filter-dd dx-theme-background-color">
              <DropDownButton
                stylingMode='outlined'
                dataSource={props.filterArray}
                text={props.userStatus}
                onItemClick={(e) => onChangeUserStatus(e.itemData)}
                dropDownOptions={{ width: '120px', minWidth: '120px' }}

              />
            </div>
          </div>
        }
        {props.showSearch &&
          <TextBox
            stylingMode='outlined'
            className={'search-header dx-theme-background-color'}
            placeholder={`Search ${props.searchLabel}`}
            value={props.searchKey}
            onInput={handleChangeSearch}


            buttons={[
              {
                name: 'cancel',
                location: 'after',
                options: {
                  onClick: () => props.onCancelSearch(true),
                  icon: 'search',
                  stylingMode: 'text',
                },
              },
            ]}
          />
        }

        {props.showBtn3 && <>
          <Tooltips
            target='#my-button3'
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            position={'bottom'}
            hideOnOutsideClick={true}
            contentRender={() => <span>{props.btnText3}</span>}
          />
          <Buttons
            id='my-button3'
            className={`btn-primarys ${props.blurBtn === false && "compare"}`}
            // className={"btn-primary"}
            type='default'
            stylingMode='outlined'
            text={props.btnText3}
            onClick={() => props.onButtonClick3(true)}
          />
        </>}

        {props.showBtn && <>
          <Tooltips
            target='#my-button'
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            position={'bottom'}
            hideOnOutsideClick={true}
            contentRender={() => <span>{props.btnText}</span>}
          />
          <Buttons
            id='my-button'
            type='default'
            text={props.btnText}
            color='#fff'
            onClick={() => props.onButtonClick(true)}
            disabled={props.blurBtn}
          />
        </>}

        {props.showBtn2 && <>
          <Tooltips
            target='#my-button2'
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            position={'bottom'}
            hideOnOutsideClick={true}
            contentRender={() => <span>{props.btnText2}</span>}
          />
          <Buttons
            id='my-button2'
            className={"btn-primary"}
            type='default'
            text={props.btnText2}
            color='#fff'
            onClick={() => props.onButtonClick2(true)}
            disabled={props.blurBtn}
          />
        </>}


      </div>
    </div>
  )
}
