import * as React from 'react';

export function Home({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path fill="#FFB74F" d="M16.88 8.77V18h-5.295v-5.595H8.648V18H3.121V8.77L10 3.074z" />
                <path fill="#FF7D3C" d="M17.167 5.936V2.032H14.27v1.505L10 0 0 8.282l2.095 2.931L10 4.667l7.905 6.546L20 8.282z" />
                <path fill="#FF9A00" d="M16.88 8.77V18h-5.295v-5.595H10V3.074z" />
                <path fill="#FF4E19" d="m20 8.282-2.095 2.931L10 4.667V0l4.27 3.537V2.032h2.897v3.904z" />
            </g>
        </svg>
    );
}
