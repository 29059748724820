import * as React from 'react';

export function SettingCluster({ fill = '#fff', width = 15, height = 15, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M11.172 3.516H.586a.586.586 0 1 1 0-1.172h10.586a.586.586 0 1 1 0 1.172z" fill="#57555C" />
                <path d="M19.414 3.516H15.86a.586.586 0 1 1 0-1.172h3.555a.586.586 0 1 1 0 1.172z" fill="#3C3A41" />
                <g fill="#57555C">
                    <path d="M4.14 10.586H.587a.586.586 0 1 1 0-1.172H4.14a.586.586 0 1 1 0 1.172zM19.414 10.586H8.828a.586.586 0 1 1 0-1.172h10.586a.586.586 0 1 1 0 1.172zM11.172 17.656H.586a.586.586 0 1 1 0-1.172h10.586a.586.586 0 1 1 0 1.172z" />
                </g>
                <path d="M19.414 17.656H15.86a.586.586 0 1 1 0-1.172h3.555a.586.586 0 1 1 0 1.172z" fill="#3C3A41" />
                <path d="M6.484 12.93A2.933 2.933 0 0 1 3.554 10a2.933 2.933 0 0 1 2.93-2.93A2.933 2.933 0 0 1 9.414 10a2.933 2.933 0 0 1-2.93 2.93z" fill="#549CFF" />
                <g fill="#3C3A41">
                    <path d="M11.172 16.484H10v1.172h1.172a.586.586 0 1 0 0-1.172zM19.414 9.414H10v1.172h9.414a.586.586 0 1 0 0-1.172zM11.172 2.344H10v1.172h1.172a.586.586 0 1 0 0-1.172z" />
                </g>
                <g fill="#1689FC">
                    <path d="M13.516 5.86a2.933 2.933 0 0 1-2.93-2.93A2.933 2.933 0 0 1 13.516 0a2.933 2.933 0 0 1 2.93 2.93 2.933 2.933 0 0 1-2.93 2.93zM13.516 20a2.933 2.933 0 0 1-2.93-2.93 2.933 2.933 0 0 1 2.93-2.93 2.933 2.933 0 0 1 2.93 2.93 2.933 2.933 0 0 1-2.93 2.93z" />
                </g>
            </g>
        </svg>
    );
}
