import DropDownButton from "devextreme-react/drop-down-button";
import React from "react";
import "./themeSwitcher.scss";
import ThemeService from "../../../../services/theme-services";
import { CookieStorage } from 'cookie-storage';
import DataSource from "devextreme/data/data_source";
import "./themeSwitcher.scss";

const cookies = new CookieStorage();

interface ThemeSwitcherComponentInterface {

};

interface ThemeSwitcherComponentStateTypes {
    theme: any
}

class ThemeSwitcherComponent extends React.PureComponent<ThemeSwitcherComponentInterface, ThemeSwitcherComponentStateTypes> {
    themeService = new ThemeService();
    profileSettings: Array<any> =
        [
            { id: 5, name: 'Blue Light', value: 'material.blue.light', icon: 'MyIcon3', Category: 'Material Design' },
            { id: 6, name: 'Blue Dark', value: 'material.blue.dark', icon: 'MyIcon4', Category: 'Material Design' },
            { id: 7, name: 'Blue Light Compact', value: 'material.blue.compact.light', icon: 'MyIcon5', Category: 'Material Design' },
            { id: 8, name: 'Blue Dark Compact', value: 'material.blue.compact.dark', icon: 'MyIcon6', Category: 'Material Design' },

            // { id: 1, name: 'Light', value: 'generic.light', icon: 'MyIcon1', Category: 'Generic' },
            // { id: 2, name: 'Dark', value: 'generic.dark', icon: 'MyIcon2', Category: 'Generic' },
            // { id: 3, name: 'Light Compact', value: 'generic.light.compact', icon: 'MyIcon1', Category: 'Generic Compact' },
            // { id: 4, name: 'Dark Compact', value: 'generic.dark.compact', icon: 'MyIcon2', Category: 'Generic Compact' },
        ];

    constructor(props) {
        super(props)
        this.state = {
            theme: this.themeService.getThemeNameById(cookies.getItem("theme"), this.profileSettings),
        }
    }

    onItemClick = (event: any) => {
        document.cookie = "dx-theme=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        if (this.themeService.getCurrentTheme().split('.')[0] !== event.itemData.value.split('.')[0]) {
            this.themeService.setCurrentTheme(event.itemData.value);
            window.location.reload();
        }
        else {
            this.themeService.setCurrentTheme(event.itemData.value);
        }

        this.themeService.refreshTheme();
        this.setState(state => ({ theme: event.itemData.name }));
        // if (this.themeService.getCurrentTheme().split('.')[0] !==="")

    }

    render(): React.ReactNode {
        return (
            <div className="mainContainer">
                <span className="theme-text">Theme </span>
                <DropDownButton
                    splitButton={false}
                    useSelectMode={true}
                    items={this.profileSettings}
                    displayExpr="name"
                    keyExpr="id"
                    width={220}
                    onItemClick={this.onItemClick}
                    text={this.state.theme.name}
                    icon={this.state.theme.icon}
                />
            </div>
        )
    }
}

export default ThemeSwitcherComponent;