import React from "react";
import Icon from "../../../Icon/Icon";
import Card from "../../../Cards/Card";
import './ProductCard.scss';
import { TickerCard } from "../../../ticker-card/TickerCard";
import { Crossplatform, DataSearching } from "../../../../../../../assets/svg";
const data = [
    { id: 1, "productList": "Platform", icon: <Crossplatform /> },
    { id: 2, "productList": "FiVe", icon: <DataSearching /> }
];


interface IProductProps {
    onClick?: (pDirectory: any) => void;
}


function ProductCard({ onClick, ...props }: IProductProps) {
    return (
        <div className="product-card">
            <div className="product">Products</div>
            <div className="middle">
                {
                    data.map((item, index) => (
                        <div className="wrapper" key={index}>
                            <div className="icon-wrapper" aria-hidden='true' onClick={() => onClick(item.productList)}>{item.icon}</div>
                            <p className="product-title">{item.productList}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ProductCard;