import * as React from 'react';

export function DeleteIcons({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g fill="none" fill-rule="evenodd">
                <rect fill="#F55" width="30" height="30" rx="5" />
                <g fill="#FFF" fill-rule="nonzero">
                    <path d="M9.889 21.222c0 .982.795 1.778 1.778 1.778h7.11c.983 0 1.779-.796 1.779-1.778V10.556H9.889v10.666zM18.333 7.889 17.444 7H13l-.889.889H9v1.778h12.444V7.889z" />
                </g>
            </g>
        </svg>


    );
}
