import React, { useCallback, useState } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import './loader.scss';

interface ILoaderProps {
    loadPanelVisible?: boolean;
    shadingColor?: string;
    showIndicator?: boolean;
    position?: 'bottom' | 'center' | 'left' | 'left bottom' | 'left top' | 'right' | 'right bottom' | 'right top' | 'top';
    shading?: boolean;
    showPane?: boolean;
    closeOnOutsideClick?: boolean;
}
const Loader = ({ loadPanelVisible = false, shadingColor = "rgba(0,0,0,0.4)", showIndicator = true, position = "center", shading = true, showPane = true, closeOnOutsideClick = false }: ILoaderProps) => {
    return (
        <LoadPanel
            shadingColor={shadingColor}
            position={position}
            visible={loadPanelVisible}
            showIndicator={showIndicator}
            shading={shading}
            showPane={showPane}
            closeOnOutsideClick={false}
        // indicatorSrc
        />
    )
}

export default React.memo(Loader);


