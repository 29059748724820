import React, { useState } from "react";
import { Tabs } from 'devextreme-react';
import { ITabsOptions } from "devextreme-react/tabs";
import './Tabs.scss';

export interface ITabsProps extends ITabsOptions {
    dataSource?: any,
    selectedIndex?: any,
    onItemClick?: any,
    itemRender?: (...params: any) => React.ReactNode,
    onValueChange?: () => void
}

function Tab({ className, dataSource, selectedIndex, onItemClick, itemRender, children, ...props }: ITabsProps) {
    return (
        <Tabs
            className={className}
            dataSource={dataSource}
            selectedIndex={selectedIndex}
            onItemClick={onItemClick}
            itemRender={itemRender}
            {...props}
        >
            {children}
        </Tabs>
    )
}
export default Tab;