import * as React from 'react';

export function DataSearching({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="2.7vw" height="2.7vw" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.462 6.154H20.954L18.292.846A1.538 1.538 0 0 0 16.923 0H1.538C.688 0 0 .689 0 1.538v30.77c0 .85.689 1.538 1.538 1.538h25.524l4.615 4.616a3.077 3.077 0 0 0 4.338 0l.908-.908a3.077 3.077 0 0 0 .477-3.708h1.062c.85 0 1.538-.689 1.538-1.538V7.692c0-.85-.689-1.538-1.538-1.538zM3.077 3.077h12.892l1.539 3.077H3.077V3.077zm0 27.692V9.231h33.846v21.538h-2.446L31.4 27.692a3.077 3.077 0 0 0-3.6-.523l-.97-1.015A9.23 9.23 0 1 0 20 29.23a9.077 9.077 0 0 0 4.4-1.123l1.215 1.23c-.237.44-.364.931-.369 1.431H3.076zM20 26.154a6.154 6.154 0 1 1 0-12.308 6.154 6.154 0 0 1 0 12.308zm13.846 10.138-5.523-5.523.908-.907 5.523 5.523-.908.907z" fill="#0078D4" fill-rule="nonzero" />
        </svg>

    );
}
