import * as React from 'react';

export function Creates({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg id={id} className={className} style={{ cursor: 'pointer' }} width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g fill="none" fill-rule="evenodd">
                <rect fill="#0078D4" width="30" height="30" rx="5" />
                <path d="m16.655 10.34 3.005 3.005-7.712 7.711a.303.303 0 0 1-.14.08l-3.431.855a.302.302 0 0 1-.288-.08.305.305 0 0 1-.08-.288l.855-3.43a.307.307 0 0 1 .08-.141l7.71-7.712zm1.91-1.91c.573-.573 1.573-.574 2.147 0l.858.858c.287.287.445.668.445 1.074 0 .405-.158.787-.445 1.073l-1.051 1.051-3.005-3.004z" fill="#FFF" fill-rule="nonzero" />
            </g>
        </svg>

    );
}
