import * as React from 'react';

export function AddNewRecords({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>add</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="2-Walacor-Dashboard-Update_v2" transform="translate(-122.000000, -575.000000)" fill="#0078D4" fill-rule="nonzero">
                    <g id="add" transform="translate(122.000000, 575.000000)">
                        <path d="M9.99947553,5.00078671 C7.24212015,5.00078671 4.99973776,7.2431691 4.99973776,10.0005245 C4.99973776,12.7578798 7.24212015,15.0002622 9.99947553,15.0002622 C12.7568309,15.0002622 14.9992133,12.7578798 14.9992133,10.0005245 C14.9992133,7.2431691 12.7568309,5.00078671 9.99947553,5.00078671 Z M12.4993444,10.8338141 L10.8327652,10.8338141 L10.8327652,12.5003934 C10.8327652,12.9603692 10.4594514,13.333683 9.99947553,13.333683 C9.53949965,13.333683 9.1661859,12.9603692 9.1661859,12.5003934 L9.1661859,10.8338141 L7.49960665,10.8338141 C7.03963077,10.8338141 6.66631702,10.4605003 6.66631702,10.0005245 C6.66631702,9.5405486 7.03963077,9.16723484 7.49960665,9.16723484 L9.1661859,9.16723484 L9.1661859,7.50065559 C9.1661859,7.04067972 9.53949965,6.66736596 9.99947553,6.66736596 C10.4594514,6.66736596 10.8327652,7.04067972 10.8327652,7.50065559 L10.8327652,9.16723484 L12.4993444,9.16723484 C12.9593203,9.16723484 13.332634,9.5405486 13.332634,10.0005245 C13.332634,10.4605003 12.9593203,10.8338141 12.4993444,10.8338141 Z" id="Shape"></path>
                        <path d="M1.24993444,10.0005245 C1.24993444,5.17661082 5.17472859,1.25098338 9.99947553,1.25098338 C11.7318847,1.25098338 13.378465,1.75679019 14.7758917,2.67340878 L13.5159578,3.93417598 C13.1226451,4.32582211 13.4001305,5.00078671 13.9576013,5.00078671 L17.7074046,5.00078671 C18.0523865,5.00078671 18.3323718,4.72080139 18.3323718,4.37581949 L18.3323718,0.626016164 C18.3323718,0.0718785623 17.6607404,-0.210606621 17.2657611,0.184372662 L15.6708447,1.77928901 C14.0284309,0.638515509 12.069367,0.00104894398 9.99947553,0.00104894398 C4.48559806,0.00104894398 0,4.48664701 0,10.0005245 C0,10.9779732 0.144159106,11.9495889 0.427477579,12.8887063 C0.526639044,13.2178557 0.87245424,13.4061792 1.20660338,13.3070177 C1.53658607,13.207023 1.72407624,12.8587079 1.62408148,12.5278919 C1.37576117,11.705435 1.24993444,10.8554796 1.24993444,10.0005245 Z" id="Path"></path>
                        <path d="M19.5714735,7.11234262 C19.4714787,6.78152664 19.1256635,6.59320319 18.7923477,6.69403123 C18.462365,6.79402599 18.2748748,7.14234105 18.3748696,7.47315703 C18.6231899,8.29561389 18.7490166,9.14556931 18.7490166,10.0005245 C18.7490166,14.8244381 14.8242225,18.7500656 9.99947553,18.7500656 C8.27039955,18.7500656 6.62631912,18.2467586 5.23139228,17.3334732 L6.48549317,16.0652064 C6.87547272,15.6702271 6.5954874,15.0002622 6.0413498,15.0002622 L2.29154648,15.0002622 C2.12488855,15.0002622 1.96406365,15.0669254 1.8474031,15.1860858 C1.73074256,15.3052462 1.66491268,15.4652378 1.66654836,15.6318958 L1.70824374,19.3816991 C1.71324347,19.93667 2.39070794,20.2066558 2.77735433,19.8141764 L4.34227225,18.2317594 C5.98135295,19.3666999 7.93541712,20 9.99947553,20 C15.513353,20 19.9989511,15.5144019 19.9989511,10.0005245 C19.9989511,9.02307574 19.854792,8.05146003 19.5714735,7.11234262 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
