import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
import { toggleSideBar } from '../../redux/actions/sidebarAction';
import { toggleLoader } from '../../redux/actions/loader.action';
import { bindActionCreators } from 'redux';

// React Chart
import { Chart } from "react-google-charts";

// Api
import { ApiEndPoints } from '../../models/api-endpoint';

// Services
import RestApiService from '../../services/http-services';
import Toaster from '../../services/toaster.services';

// MUI
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid } from '@material-ui/core';

// Date Picker
import DatePicker from "react-datepicker";
import _ from 'underscore';

// Devextreme
import { DropDownButton } from 'devextreme-react';
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';

// SVG Image
import { AddNewRecords, SchemaDetails, SchemaRecords, UpdateRecords } from '../../../assets/svg';

// Component
import HealthCard from './HealthCard';

// Common Component
import DynamicTableList from '../../shared/components/commonui/DynamicTableList/DynamicTableList';
import SmallLoader from '../../shared/components/loader/small-loader';
import Button from '../../shared/components/commonui/Button';

// Scss
import './dashboard.scss';
import "react-datepicker/dist/react-datepicker.css";




class DashboardComponent extends Component<any, any> {

  timer;
  intervalRate: any = 60;
  public state: any = {
    schemaBarChartData: [],
    schemaTableData: [],
    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(),
    envelopeBarChartData: [],
    envelopeData: [],
    envelopePieChartData: [],
    assistedAuditData: [],
    envelopeTableData: [],
    schemaPieChartData: [],
    schemaCreatedTableData: [],
    schemaUpdatedTableData: [],
    schemaDetailsTableData: [],
    buttonArray: ['All', 'Good', 'Pending', 'Bad'],
    statusArray: ['Past Hour', 'Past Day', 'Past Week', 'Past Month', 'Past Year'],
    ratesArray: [0, 60, 120, 600],
    isEnvelopeFullscreenEnabled: false,
    //envelopeStartDate: new Date(new Date().setUTCFullYear(new Date().getUTCFullYear() - 1)),
    envelopeStartDate: new Date(new Date().setUTCDate(new Date().getUTCDate() - 7)),
    envelopeEndDate: new Date(),
    schemaDetailsStartDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    schemaDetailsEndDate: new Date(),
    value: 0,
    pageSize: 10,
    currentPageNo: 1,
    totalPage: 1,
    totalRecord: 0,
    totalCreatedSchemas: 0,
    totalUpdatedSchemas: 0,
    healthQueryParam: '',
    envelopeDetail: {},
    statusText: 'Past Week',
    healthStatusText: 'All',
    envelopeIndex: null,
    refreshLoading: false,
    defaultRate: 60,
    orderIndexedFields: [{ 'orderBy': 'Family', 'order': 'desc', 'isActive': false }, { 'orderBy': 'TableName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'History', 'order': 'desc', 'isActive': false }, { 'orderBy': 'Summary', 'order': 'desc', 'isActive': false }, { 'orderBy': 'Inserted', 'order': 'desc', 'isActive': false }, { 'orderBy': 'Updated', 'order': 'desc', 'isActive': false }],
    tabsWidth: 'auto'
  }

  constructor(props: any) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSchemaDetailsStartDateChange = this.handleSchemaDetailsStartDateChange.bind(this);
    this.handleSchemaDetailsEndDateChange = this.handleSchemaDetailsEndDateChange.bind(this);
    this.handleEnvelopeStartDateChange = this.handleEnvelopeStartDateChange.bind(this);
    this.handleEnvelopeEndDateChange = this.handleEnvelopeEndDateChange.bind(this);
    this.handleEnvelopeChartChange = this.handleEnvelopeChartChange.bind(this);
    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.isLessthanTotalElement = this.isLessthanTotalElement.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
    this.filterHealthCards = this.filterHealthCards.bind(this)
    this.filterStatus = this.filterStatus.bind(this)
    this.getEnvelopeData = this.getEnvelopeData.bind(this)
    this.filterRefresh = this.filterRefresh.bind(this)
    this.onSortingData = this.onSortingData.bind(this)
    this.getAssistedAudit = this.getAssistedAudit.bind(this)
  }



  getDateFormat(dateData: string) {
    if (dateData) {
      let currentDt = new Date(dateData);
      let mm = currentDt.getMonth() + 1;
      let dd = currentDt.getDate();
      let yyyy = currentDt.getFullYear();
      let date = yyyy + "/" + mm + "/" + dd;
      return date;
    } else return "";
  }

  getBarChartData() {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_SCHEMAS_DETAILS_FOR_TABLE, null, null, { IsBarChart: true, startDate: this.state.schemaDetailsStartDate, endDate: this.state.schemaDetailsEndDate }, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      let dataFilter = [["Schema", "Summary Count", "History Count", "Inserted Records", "Updated Records"]];
      for (let row in res.data.data) {
        dataFilter.push([`${res.data.data[row].schema}`, res.data.data[row].summary, res.data.data[row].history, res.data.data[row].inserted, res.data.data[row].updated]);
      }
      this.setState({ schemaBarChartData: dataFilter });
    }).catch(error => {
      this.props.toggleLoader(false);
      // Toaster.errorToaster(error);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    })


  }

  getSchemaPieChartData() {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_SCHEMAS_LIST_FOR_PIE_CHART, null, null, { startDate: this.state.startDate, endDate: this.state.endDate }, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      this.setState({ totalCreatedSchemas: res.data[0].totalCreatedAt });
      this.setState({ totalUpdatedSchemas: res.data[0].totalUpdated });
      let dataFilterPie = [["DateType", "Value"]];
      dataFilterPie.push(["New Added Records", res.data[0].totalCreatedAt]);
      dataFilterPie.push(["Updated Records", res.data[0].totalUpdated]);
      this.setState({ schemaPieChartData: dataFilterPie });
      this.setState({ schemaCreatedTableData: res.data[0].createdData });
      this.setState({ schemaUpdatedTableData: res.data[0].updatedData });
    }).catch(error => {
      this.props.toggleLoader(false);
      // Toaster.errorToaster(error);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    })
  }

  getSchemaDetailsData(orderBy?: any, order?: any) {
    this.setState({ schemaDetailsTableData: [] });
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_SCHEMAS_DETAILS_FOR_TABLE, null, null, { orderBy: orderBy, order: order, pageNo: this.state.currentPageNo, pageSize: this.state.pageSize, IsBarChart: false, startDate: this.state.schemaDetailsStartDate, endDate: this.state.schemaDetailsEndDate }, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      this.setState({ schemaDetailsTableData: res.data.data });
      this.setState({ totalRecord: res.data.total })
      if (parseInt(res.data.total) % this.state.pageSize === 0) {
        // this.setState({ totalPage: (parseInt(res.data.total) / this.state.pageSize) });
        this.setState((prevState) => ({
          totalPage: (parseInt(res.data.total) / prevState.pageSize)
        }))
      }
      else {
        // this.setState({ totalPage: ((Math.floor(parseInt(res.data.total) / this.state.pageSize)) + 1) });
        this.setState((prevState) => ({
          totalPage: ((Math.floor(parseInt(res.data.total) / prevState.pageSize)) + 1)
        }))
      }
    }).catch(error => {
      this.props.toggleLoader(false);
      // Toaster.errorToaster(error);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    })
  }

  getEnvelopeData(showLoader: boolean) {
    if (showLoader) {
      this.props.toggleLoader(true);
    }
    else {
      this.props.toggleLoader(false);
      this.setState({ refreshLoading: true });
    }
    let body;
    if (this.state.healthQueryParam !== '') {
      body = { startDate: this.state.envelopeStartDate, endDate: this.state.envelopeEndDate, health: this.state.healthQueryParam }
    }
    else {
      body = { startDate: this.state.envelopeStartDate, endDate: this.state.envelopeEndDate };
    }


    RestApiService.invoke(ApiEndPoints.GET_DATA_FOR_ENVELOPE_CHART, null, body, null, null).then(res => {
      if (showLoader) {
        this.props.toggleLoader(false);
        this.autoRefreshFunction();
      } else {
        this.setState({ refreshLoading: false })
      }
      // let dataFilter = [["ETId", "Total", "Submitted", "BC Stored", "Datastore", "External BlockChain"]];
      // let dataFilterPie = [["StatusType", "Value"]];
      // for (let row in res.data.data) {
      //   dataFilter.push([String(res.data.data[row].ETId), res.data.data[row].Total, res.data.data[row].Submitted, res.data.data[row]["BC Stored"], res.data.data[row].Datastore, res.data.data[row]["External BlockChain"]]);
      // }
      // const Submitted = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue.Submitted);
      // }, 0);
      // const BCStored = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue["BC Stored"]);
      // }, 0);
      // const Datastore = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue.Datastore);
      // }, 0);

      // const ExternalBlockChain = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue["External BlockChain"]);
      // }, 0);

      // dataFilterPie.push(["Submitted", Submitted]);
      // dataFilterPie.push(["BC Stored", BCStored]);
      // dataFilterPie.push(["Datastore", Datastore]);
      // dataFilterPie.push(["External BlockChain", ExternalBlockChain]);
      // this.setState({envelopeBarChartData: dataFilter, envelopePieChartData: dataFilterPie, envelopeTableData: _.sortBy(res.data.data, 'ETId')})
      const sortedEnvelopeData = _.sortBy(res.data.data, 'Health');
      const reversedEnvelopeData = sortedEnvelopeData.reverse();
      this.setState({ envelopeData: reversedEnvelopeData }, () => {
        if (this.state.envelopeIndex !== null) {
          this.viewEnvelopeHealthCardDetails(this.state.envelopeData[this.state.envelopeIndex], this.state.envelopeIndex)
        }
      });
    }).catch(error => {
      this.props.toggleLoader(false);
      console.log('Get error in dashboard component', error);
      Toaster.errorToaster(error);
    })
  }
  getAssistedAudit() {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_ASSISTED_AUDIT_PIE_CHART, null, null, null, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      let dataFilterPie = [["DateType", "Value"]];
      dataFilterPie.push(["Pass", res.data.data[0].chainHistoryStatusComplete]);
      dataFilterPie.push(["Fail", res.data.data[0].chainHistoryStatusNotComplete]);
      this.setState({ assistedAuditData: dataFilterPie });
    }).catch(error => {
      this.props.toggleLoader(false);
      Toaster.errorToaster(error);
    })

  }

  refreshEnvelopeHealthData() {
    this.getEnvelopeData(false);
  }

  closeCardDetails(item, index) {
    if (this.state.envelopeIndex === index) {
      this.setState({ envelopeIndex: null });
    }
  }

  componentDidMount() {
    this.viewEnvelopeGraph();
  }

  autoRefreshFunction() {
    if (this.intervalRate === 0) {
      clearInterval(this.timer);
    }
    else {
      clearInterval(this.timer);
      let intervalTime = (+this.intervalRate * 1000);
      this.timer = setInterval(
        this.refreshEnvelopeHealthData.bind(this)
        , intervalTime);
    }
  }

  goToPreviousPage(page: any) {
    this.setState({ currentPageNo: page }, () => {
      this.getSchemaDetailsData();
    });

  }

  goToNextPage(page: any) {
    this.setState({ currentPageNo: page }, () => {
      this.getSchemaDetailsData();
    })
  }

  isLessthanTotalElement() {
    if ((this.state.currentPageNo * this.state.pageSize) === (this.state.totalPage - 1))
      return true;
    else
      return false;
  }

  changePageSize = (value: any) => {
    this.setState({ pageSize: value, currentPageNo: 1 }, () => {
      this.getSchemaDetailsData();
    })
    //  this.setState({ currentPageNo: 1 })

  }


  handleStartDateChange = (date) => {
    if (new Date(date) < new Date(this.state.endDate)) {
      this.setState({
        startDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("startDateValidation", "Start Date should be less than end date.");
    }
  };

  handleEndDateChange = date => {
    if (new Date(date) > new Date(this.state.startDate)) {
      this.setState({
        endDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("endDateValidation", "End Date should be greater than start date.");
    }
  };



  handleSchemaDetailsStartDateChange = (date) => {
    if (new Date(date) < new Date(this.state.schemaDetailsEndDate)) {
      this.setState({
        schemaDetailsStartDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("startDateValidation", "Start Date should be less than end date.");
    }
  };

  handleSchemaDetailsEndDateChange = (date) => {
    if (new Date(date) > new Date(this.state.schemaDetailsStartDate)) {
      this.setState({
        schemaDetailsEndDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("endDateValidation", "End Date should be greater than start date.");
    }
  };

  viewEnvelopeHealthCardDetails(item, index) {
    RestApiService.invoke(ApiEndPoints.GET_ENVELOPE_HEALTH_DETAILS, null, null, {
      ETId: item.ETId,
      SV: 1
    }, null).then(res => {
      this.setState({ envelopeDetail: { ...item, ...res.data.data[0] }, envelopeIndex: index });

    }).catch(error => {
      console.log('Get error in dashboard component', error);
      Toaster.errorToaster(error);
    })

  }

  debouncedViewEnvelopeHealthCardDetails = _.debounce(this.viewEnvelopeHealthCardDetails, 100);

  viewSchemaDetails() {
    this.getSchemaDetailsData();
    this.getBarChartData();
  }

  viewSchemaGraph() {
    this.getSchemaPieChartData();
  }

  handleEnvelopeStartDateChange = date => {
    if (new Date(date) < new Date(this.state.envelopeEndDate)) {
      this.setState({
        envelopeStartDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("startEvelpDateValidation", "Start Date should be less than end date.");
    }
  };

  handleEnvelopeEndDateChange = date => {
    if (new Date(date) > new Date(this.state.envelopeStartDate)) {
      this.setState({
        envelopeEndDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("endEnvlpDateValidation", "End Date should be greater than start date.");
    }
  };

  viewEnvelopeGraph() {
    this.getEnvelopeData(true);
  }

  handleEnvelopeChartChange(itemIndex: any) {
    this.setState({ value: itemIndex });
    if (itemIndex == 0) {
      clearInterval(this.timer);
      this.getEnvelopeData(true);
    }
    if (itemIndex == 1) {
      clearInterval(this.timer);
      this.viewSchemaGraph();
    }
    if (itemIndex == 2) {
      clearInterval(this.timer);
      this.getBarChartData();
      this.getSchemaDetailsData('Family', 'desc');
    }
  };

  filterHealthCards(health: any) {
    this.setState({ healthStatusText: health });
    switch (health) {
      case 'Good':
        this.setState({ healthQueryParam: 'Good' }, () => {
          this.getEnvelopeData(true);
        });
        break;
      case 'Pending':
        this.setState({ healthQueryParam: 'Pending' }, () => {
          this.getEnvelopeData(true);
        });
        break;
      case 'Bad':
        this.setState({ healthQueryParam: 'Bad' }, () => {
          this.getEnvelopeData(true);
        });
        break;
      default:
        this.setState({ healthQueryParam: '' }, () => {
          this.getEnvelopeData(true);
        });
    }

  }


  filterStatus(text) {
    this.setState({ statusText: text });
    if (text == "Past Hour") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCHours(new Date().getUTCHours() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }
    if (text == "Past Day") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }
    if (text == "Past Week") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCDate(new Date().getUTCDate() - 7)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })

    }
    if (text == "Past Month") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCMonth(new Date().getUTCMonth() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }
    if (text == "Past Year") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCFullYear(new Date().getUTCFullYear() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }

  }
  componentWillUnmount() {

    clearInterval(this.timer);
  }

  filterRefresh(refreshRate) {
    this.intervalRate = parseInt(refreshRate);
    this.setState({ defaultRate: parseInt(refreshRate) })
    if (this.intervalRate === 0) {
      clearInterval(this.timer);
    }
    else {
      clearInterval(this.timer);
      let intervalTime = (+this.intervalRate * 1000);
      this.timer = setInterval(
        this.refreshEnvelopeHealthData.bind(this)
        , intervalTime);
    }
  }
  onSortingData(orderBy: any, order: any) {
    let data = this.state.orderIndexedFields.map(item => ({ ...item, order: 'desc' }));
    let index = data.findIndex(obj => obj.orderBy === orderBy);
    data[index].order = order === 'desc' ? 'desc' : 'asc';
    let index1 = data.findIndex(obj => obj.isActive);
    if (index1 > -1) {
      data[index1].isActive = false;
    }
    data[index].isActive = true;
    this.setState({ orderIndexedFields: data });
    this.getSchemaDetailsData(orderBy, order);
  }

  itemRenderTabDashboard(param: any) {
    return (
      <span>{param.name}</span>
    )
  }

  render() {
    return (
      <div className={`Innerheading`}>
        <div className='view-wrapper view-wrapper-dashboard-details dashbord-page'>
          <span className='dashboard-text'>Dashboard</span>
          <div className='dx-card details-card'>
            <TabPanel
              showNavButtons
              focusStateEnabled={false}
              deferRendering={false}
              onSelectedIndexChange={this.handleEnvelopeChartChange}
              hoverStateEnabled={false}
              activeStateEnabled={false}
            >
              <TabPanelItem title='Envelope Health Check'>
                <div className='card-envelope-health-check'>
                  <div className="health-filter">
                    <div className="filter-content">
                      <div className="datepicker">
                        <span className="statuslabel">Time Interval:</span>
                        <DropDownButton
                          dataSource={this.state.statusArray}
                          text={this.state.statusText}
                          onItemClick={(e) => this.filterStatus(e.itemData)}
                          stylingMode='outlined'
                          dropDownOptions={{ minWidth: '150px' }}
                        />
                      </div>
                      <div className="datepicker">
                        <span className="statuslabel refresh">Refresh Rate/Second: </span>
                        <DropDownButton
                          dataSource={this.state.ratesArray}
                          text={this.state.defaultRate.toString()}
                          onItemClick={(e) => this.filterRefresh(e.itemData.name || e.itemData)}
                          stylingMode='outlined'
                          dropDownOptions={{ minWidth: '100px' }}
                        />
                      </div>
                      <div className='filter-buttons'>
                        <Button text='Refresh' icon='refresh' type="default" onClick={() => this.getEnvelopeData(true)} className='refreshbtn' />
                      </div>
                    </div>
                    <div className='datepicker'>
                      <span className="statuslabel">Filter By Health: </span>
                      <DropDownButton
                        dataSource={this.state.buttonArray}
                        text={this.state.healthStatusText}
                        onItemClick={(e) => this.filterHealthCards(e.itemData)}
                        stylingMode='outlined'
                        dropDownOptions={{ minWidth: '100px' }}
                      />
                    </div>
                  </div>

                  <div>
                    {this.state.refreshLoading && <div className="loader-content">
                      <SmallLoader></SmallLoader>
                    </div>}

                    <Grid container spacing={3}>
                      {this.state.envelopeData && this.state.envelopeData.length > 0 &&
                        this.state.envelopeData.map((item: any, index: any) =>
                          <HealthCard
                            key={`envelope_${index}`}
                            item={item}
                            index={index}
                            envelopeDetail={this.state.envelopeDetail}
                            envelopeIndex={this.state.envelopeIndex}
                            viewEnvelopeHealthCardDetails={() => this.debouncedViewEnvelopeHealthCardDetails(item, index)}
                            closeCardDetails={() => this.closeCardDetails(item, index)}
                          />
                        )}
                    </Grid>
                  </div>
                  {this.state.envelopeData.length == 0 && <div className={'not-found-health'}>
                    Data not found
                  </div>
                  }
                </div>
              </TabPanelItem>


              <TabPanelItem title='Schema'>
                <div className='card-schema-envelope-tasks'>
                  <div className="datepickers">
                    <div>
                      <span className="start-date">Start Date :</span>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        dateFormat="MM/dd/yyyy"
                        className={"dx-theme-background-color datetime-width"}
                      />

                    </div>
                    <div>
                      <span className="end-date">End Date :</span>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        dateFormat="MM/dd/yyyy"
                        className={"dx-theme-background-color datetime-width"}
                      />

                    </div>
                    <div className="viewSchema">
                      <Button text='View' type="default" onClick={() => this.viewSchemaGraph()} className='viewSchemaBtn' />
                    </div>
                  </div>
                  <div className='schema-notes'>
                    <div>
                      <UpdateRecords />
                    </div>
                    <div className='schema-text'>UPDATED RECORDS</div>
                  </div>

                  <DynamicTableList
                    dataSource={this.state.schemaUpdatedTableData}
                    columnList={[
                      { name: "Table Name", db: "tableName" },
                      { name: "ETId", db: "ETId" },
                      { name: "SV", db: "latestDVVersions" },
                      { name: "Total Fields", db: "totalUpdatedSchemaFields" },
                      { name: "Total Indexes", db: "totalUpdatedSchemaIndexes" }
                    ]}
                    columnAutoWidth={true}
                    columnMinWidth={130}
                  />

                  <div className='schema-notes'>
                    <div>
                      <AddNewRecords />
                    </div>
                    <div className='schema-text'>NEW ADDED RECORDS</div>
                  </div>

                  <DynamicTableList
                    dataSource={this.state.schemaCreatedTableData || []}
                    columnList={[
                      { name: "Table Name", db: "tableName" },
                      { name: "ETId", db: "ETId" },
                      { name: "SV", db: "latestDVVersions" },
                      { name: "Total Fields", db: "totalSchemaFields" },
                      { name: "Total Indexes", db: "totalSchemaIndexes" }
                    ]}
                    columnAutoWidth={true}
                    columnMinWidth={150}
                  />
                </div>
              </TabPanelItem>


              <TabPanelItem title='Schema Details'>
                <div className='card-details-envelope-tasks'>
                  <div className="datepickers">
                    <div className='start-date-div'>
                      <span className="start-date">Start Date :</span><DatePicker
                        selected={this.state.schemaDetailsStartDate}
                        onChange={this.handleSchemaDetailsStartDateChange}
                        dateFormat="MM/dd/yyyy"
                        className={"dx-theme-background-color datetime-width"}
                      />
                    </div>
                    <div className='end-date-div'>
                      <span className="end-date">End Date :</span><DatePicker
                        selected={this.state.schemaDetailsEndDate}
                        onChange={this.handleSchemaDetailsEndDateChange}
                        dateFormat="MM/dd/yyyy"
                        className={"dx-theme-background-color datetime-width"}
                      />
                    </div>
                    <div className="viewSchemagraph">
                      <Button text='View' type="default" onClick={() => this.viewSchemaDetails()} className='viewSchemagraphBtn' />
                    </div>
                  </div>
                  <div className='schema-notes'>
                    <div>
                      <SchemaDetails />
                    </div>
                    <div className='schema-text'>SCHEMA DETAILS</div>
                  </div>

                  {this.state.schemaBarChartData && this.state.schemaBarChartData.length > 1 && <div className='chart-container'>
                    <Chart
                      width={'99%'}
                      height={'100%'}
                      chartType="Bar"
                      loader={<div><LinearProgress /> Loading...</div>}
                      data={this.state.schemaBarChartData}
                      options={{
                        is3D: true,
                        hAxis: {
                          direction: -1,
                          slantedText: true,

                        },
                        colors: ["#4285F4", "#DB4437", "#0F9D58", "#F4B400", "#AB47BC"],
                        backgroundColor: "#4285F4",
                      }}
                      legendToggle
                      rootProps={{ 'data-testid': '8' }}
                      graphID={"schema-graph4"}
                      className='chart-color'
                    />
                  </div>}


                  <div className='schema-notes'>
                    <div>
                      <SchemaRecords />
                    </div>
                    <div className='schema-text'>SCHEMA RECORDS</div>
                  </div>

                  <DynamicTableList
                    dataSource={this.state.schemaDetailsTableData}
                    columnList={[
                      { name: "Family", db: "family", sort: false },
                      { name: "Schema", db: "schema", sort: false },
                      { name: "History", db: "history", sort: false },
                      { name: "Summary", db: "summary", sort: false },
                      { name: "Inserted", db: "inserted", sort: false },
                      { name: "Updated", db: "updated", sort: false },
                    ]}
                    fromComponent={'FileListComponent'}
                    columnAutoWidth={true}
                    orderIndexedFields={this.state.orderIndexedFields}
                    onSortingData={(order, orderBy) => this.onSortingData(orderBy, order)}
                    showPagination
                    goToPreviousPage={(page: any) => this.goToPreviousPage(page)}
                    goToNextPage={(page: any) => this.goToNextPage(page)}
                    changePageSize={(page: any) => this.changePageSize(page)}
                    pageSize={this.state.pageSize}
                    currentPageNo={this.state.currentPageNo}
                    totalPage={this.state.totalPage}
                    totalRecord={this.state.totalRecord}
                    isLessthanTotalElement={this.isLessthanTotalElement()}
                    columnMinWidth={130}
                  />
                </div>
              </TabPanelItem>
            </TabPanel>

          </div>

        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  isShowSideBar: state.appSidebarReducer
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSideBar: toggleSideBar,
      toggleLoader: toggleLoader
    },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
