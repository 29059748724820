import React, { useState } from "react";
import './footer.scss';
import Toolbar, { Item } from "devextreme-react/toolbar";

const FooterComponent = () => {
    let dates = new Date().getFullYear();
    const [currentYearDate, setCurrentYearrDate] = useState(dates);
    return (
        // <div className="footer-container">
        //     <div className="footer">
        //         <div className="privacy">
        //             <span><p>Privacy Policy</p></span>
        //         </div>
        //         <div className="privacy">
        //             <span><p>Terms of Use</p></span>
        //         </div>
        //         <div className="privacy">
        //             <span><p>Contact Us</p></span>
        //         </div>
        //         <div className="copyright">
        //             <span><p className='copyRightText'>Copyright {currentYearDate} Walacor Corporation </p></span>
        //         </div>
        //     </div>
        // </div>
        <div className="appFooter">
            <div className="footer">
                <div className="footerLeft">
                    <span className="policy">Policy</span>
                    <div className='separator' />
                    <span className="term">Terms of Use</span>
                    <div className='separator' />
                    <span className="contacts">Contact Us</span>
                </div>
                <div><span className="copyright">Copyright © {new Date().getFullYear()}  Walacor Corporation</span></div>
            </div>
        </div>
    );
}


export default FooterComponent