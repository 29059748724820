
import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './loader.scss';
class SmallLoader extends Component {
    render() {
        return (
            <div className="small-loader">
                <CircularProgress size={20} thickness={8} className="loader-icon" color="primary" />
            </div>
        );
    }
}

export default SmallLoader;