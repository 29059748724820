import { GET_ALL_ENVELOPES } from "../types/envelope";

// import {}

const initialState: any = {
  dataList: [],
  data: null,
};

export function envelopeReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_ENVELOPES:
      return {
        ...state,
        dataList: action.payload.data,
        total: action.payload.total
      };
    default:
      return state;
  }
}