import React, { useEffect, useState, useCallback, useMemo } from 'react';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleLoader } from '../../../redux/actions/loader.action';

// MUI
import { List, ListItem, Paper, Checkbox, Button, Tooltip, Grid, ListItemIcon, ListItemText } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';


import "./RoleList.scss";

// Api
import { ApiEndPoints } from '../../../models/api-endpoint';

// Services
import Toaster from '../../../services/toaster.services';
import RestApiService from '../../../services/http-services';

// SVG Images
import { Creates, DeleteIcons, Eyeopen, GroupAddIcons } from '../../../../assets/svg';

// Utils
import { changeEpochToDate } from '../../../utils/utilityFunctions';

// Packages
import moment from 'moment';

// Common Component
import ModalBox from '../../../shared/components/commonui/Modal/ModalBox';
import Buttons from '../../../shared/components/commonui/Button';
import InputWithLabel from '../../../shared/components/commonui/HigherOrderComponents/InputWithLabel';
import MultiSelectTagWithLabel from '../../../shared/components/commonui/HigherOrderComponents/MultiSelectTagWithLabel';
import NewSubHeaderComponent from '../../../shared/components/new-sub-header/new-sub-header';
import DynamicTableList from '../../../shared/components/commonui/DynamicTableList/DynamicTableList';
import { FormPopup } from '../../../shared/components/commonui/form-popup/FormPopup';

// Devextreme
import {  ScrollView } from 'devextreme-react';



const RoleListComponent: React.FC<RoleProps> = (props: RoleProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = React.useState<any>({});
    const [mode, setMode] = React.useState<any>('add');
    const [scope, setScope] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [roleId, setRoleId] = useState('');
    const pageSizeList: any[] = [{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }];
    const [open, setOpen] = React.useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState(20);
    const [totalRecord, setTotalRecord] = useState(200);
    const scopeList = ['AdminAccess', 'ReadWrite', 'ReadOnly'];
    const [users, setUsers] = React.useState([]);
    const [userRoles, setUserRoles] = React.useState([]);
    const [order, setOrder] = React.useState<any>('desc');
    const [orderBy, setOrderBy] = React.useState<any>('CreatedAt');
    const [checked, setChecked] = React.useState<any[]>([]);
    const [existsUser, setExistsUser] = React.useState<number[]>();
    const [notExistsUser, setNotExistsUser] = React.useState<number[]>();
    const existsUserChecked = intersection(checked, existsUser);
    const notExistsUserChecked = intersection(checked, notExistsUser);
    const [onCancel, setOnCancel] = React.useState(false);
    const [roleUserArray, setRoleUserArray] = React.useState<any[]>([]);
    const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'createdAt', 'order': 'desc', 'isActive': false }, { 'orderBy': 'updatedAt', 'order': 'desc', 'isActive': false }]);
    const [editedScopes, setEditedScopes] = React.useState<any[]>([]);
    const [addedRoleName, setAddedRoleName] = useState('')

    function not(a: number[], b: number[]) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a: number[], b: number[]) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }
    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const handleCheckedRight = () => {
        setNotExistsUser(notExistsUser.concat(existsUserChecked));
        setExistsUser(not(existsUser, existsUserChecked));
        setChecked(not(checked, existsUserChecked));
        checked.map((item: any) => {
            if (item.roleType === 'ExistingRole') {
                const userData = {
                    UID: item.UID,
                    IsDeleted: true
                }
                roleUserArray.push(userData);
            }
            else {
                const userData = {
                    UserUID: item.id,
                    RoleID: roleId
                }
                const currentIndex = roleUserArray.indexOf(userData);
                roleUserArray.splice(currentIndex, 1);
            }
        })

    };

    const handleCheckedLeft = () => {
        setExistsUser(existsUser.concat(notExistsUserChecked));
        setNotExistsUser(not(notExistsUser, notExistsUserChecked));
        setChecked(not(checked, notExistsUserChecked));
        checked.map((itme: any) => {
            const userData = {
                UserUID: itme.id,
                RoleID: roleId
            }
            roleUserArray.push(userData);
        })
    };
    const handleChangeSearch = (text: any) => {
        if (text !== undefined) {
            searchUsers(text, roleId);
        }
    };
    const OnCancelSearch = () => {
        searchUsers(null, roleId);
    }
    const searchUsers = (user?: string, roleID?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.SEARCH_USERS, null, null, { searchKey: user, roleId: roleID }, {
            ETId: 10
        }).then(res => {
            props.toggleLoader(false);
            setExistsUser(res.data.data.ExistingRole);
            setNotExistsUser(res.data.data.NewRole);
        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }
    const getRoles = (from?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_ROLES, null, null, { orderBy: orderBy, order: order }, {
            ETId: 15
        }).then(res => {
            props.toggleLoader(false);
            setRoles(res.data.data);
        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }

    const handleClose = () => {
        setOpen(false);
        // getRoles();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const deleteRole = () => {
        const roleData = {
            ETId: 15,
            Data: [{
                UID: roleId,
                IsDeleted: true
            }]
        }
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.DELETE_ROLE, null, roleData, null, {
            ETId: 15
        }).then(res => {
            Toaster.successToaster('Delete Role', 'Role deleted successfully');
            handleClose();
            getRoles();
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response.data.errors);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    const openModal = (action: string, value?: any) => {
        setOpen(true)
        handleMenuClose();
        if (action === 'delete') {
            setRoleName(value.roleName);
            setAddedRoleName(value.addedRoleName)
            setRoleId(value.id);
            setMode('delete')
        }
        if (action === 'add' && value === undefined) {
            setMode('add')
        }
        if (action === 'add' && value != undefined) {
            setRole(value);
            setRoleName(value.roleName);
            setAddedRoleName(value.addedRoleName)
            setRoleId(value.id);
            setMode('addUser');
            searchUsers(null, value.id);
            //getUsers();
        }
        if (action === 'view') {
            setMode('view')

        }
        if (action === 'edit') {
            setRole(value);
            setEditedScopes([...(value?.scopes ?? [])]);
            setRoleName(value.roleName);
            setMode('edit')
        }
    };

    const addRole = (roleData) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.CREATE_ROLE, null, roleData, null, {
            ETId: 15
        }).then(res => {
            Toaster.successToaster('Create Role', 'Role added successfully');
            handleClose();
            setTimeout(() => {
                getRoles()
            }, 2000);
            setAddedRoleName('');
            setScope([]);
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response.data.errors);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    const editRole = (roleData) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.UPDATE_ROLE, null, roleData, null, {
            ETId: 15
        }).then(res => {
            Toaster.successToaster('Update Role', 'Role updated successfully ');
            handleClose();
            setTimeout(() => {
                getRoles();
            }, 2000);
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    const saveRole = () => {
        if (mode === 'add') {
            const roleData = {
                ETId: 15,
                // SV: 1,
                Data: [{
                    RoleName: addedRoleName,
                    Scopes: scope
                }]
            }
            addRole(roleData);

        }
        else {
            const roleData = {
                ETId: 15,
                // SV: 1,
                Data: [{
                    UID: role.id,
                    RoleName: roleName,
                    Scopes: editedScopes
                }]
            }
            editRole(roleData);
        }
    }
    const getUsers = () => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_USER_WITHOUT_ROLE, null, null, null, {
            ETId: 10
        }).then(res => {
            props.toggleLoader(false);
            setUsers(res.data.data);

        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }
    const onPageChange = (page: any) => {
        setCurrentPageNo(page);

    }

    const goToPreviousPage = useCallback((page: any) => {
        setCurrentPageNo(page);

    }, [])

    const goToNextPage = useCallback((page: any) => {
        if (currentPageNo < totalPage) {
            setCurrentPageNo(page);

        }
    }, [])

    const isLessthanTotalElement = useCallback(() => {
        if ((currentPageNo * pageSize) === (totalPage - 1))
            return true;
        else
            return false;
    }, [currentPageNo, pageSize, totalPage])

    const changePageSize = useCallback((value: any) => {
        setPageSize(value);
        setCurrentPageNo(1);

    }, [])


    useEffect(() => {
        if (order && orderBy) {
            getRoles();
        }

    }, [order, orderBy]);

    const addUserRole = () => {
        props.toggleLoader(true);
        const Data = [];
        const userRole = {
            ETId: 16,
            // SV: 1,
            Data: roleUserArray
        }
        RestApiService.invoke(ApiEndPoints.CREATE_USER_ROLE, null, userRole, null, {
            ETId: 16
        }).then(res => {
            props.toggleLoader(false);
            Toaster.successToaster('Add UserRole', 'User role updated successfully');
            handleClose();
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });
    }
    const onSortingData = useCallback((order, orderBy, data: any) => {
        if (orderBy && order) {
            setOrderBy(orderBy.charAt(0).toUpperCase() + orderBy.slice(1));
            setOrder(order);
            setOrderIndexedFields(data);
        }
    }, []);


    const customList = (items: number[]) => (
        <Paper className="MuiPaper">
            <List dense component="div" role="list">
                {items.map((value: any) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.userName}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const getConvertFunctionByColumn = useCallback((value: string) => {
        const date = changeEpochToDate(parseInt(value, 10));
        const stillUtc = moment.utc(date).toDate();
        return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss');
    }, []);


    const ViewIconCellRenderer = useCallback((value: any): React.ReactNode => {
        return (
            <div className='role-icon'>
                <Tooltip title="Edit" placement="right">
                    <span><Creates className="edit" onClick={(e: any) => openModal('edit', value?.data)} /></span>
                </Tooltip>

                <Tooltip title="Users in Role" placement="right">
                    <span><GroupAddIcons className="user-role" onClick={() => openModal('add', value?.data)} /></span>
                </Tooltip>

                <Tooltip title="Delete" placement="right">
                    <span><DeleteIcons className="delete" onClick={(e: any) => openModal('delete', value?.data)} /></span>
                </Tooltip>
            </div>
        )
    }, [openModal]);

    const customActionColumnFormatters = [
        ViewIconCellRenderer
    ]

    const handleRoleAdd = useCallback((e) => {
        setAddedRoleName(e.value)
    }, []);


    const handleRoleEdit = useCallback((e) => {
        setRoleName(e.value)
    }, []);

    const handleRoleEditUser = useCallback((e: any) => {
        setRoleName(e.value)
    }, []);


    const handleSetEditScope = useCallback((e: any) => {
        setEditedScopes(e.value)
    }, []);

    const handleScope = useCallback((e: any) => {
        setScope(e.value)
    }, []);

    const tableColumnList = useMemo(() => [
        { name: "Role Name", db: "roleName" },
        { name: "Scopes", db: "scopes" },
        { name: "Created At", db: "createdAt", sort: true, format: getConvertFunctionByColumn },
        { name: "Updated At", db: "updatedAt", sort: true, format: getConvertFunctionByColumn }
    ], [getConvertFunctionByColumn]);



    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='role-page'>
                        <div className="Innerheading">
                            <div className='view-wrapper view-wrapper-role-details dx-theme-background-color'>
                                <NewSubHeaderComponent
                                    headingText={'Role Management'}
                                    btnText={'Add Role'}
                                    showBtn={true}
                                    onButtonClick={(value: any) => openModal('add')}
                                />
                                <div className="dx-theme-background-color Innerdetails dx-card">
                                    <DynamicTableList
                                        dataSource={roles}
                                        columnList={tableColumnList}
                                        columnAutoWidth={true}
                                        orderIndexedFields={orderIndexedFields}
                                        onSortingData={onSortingData}
                                        showPagination={false}
                                        fromComponent={'RoleComponent'}
                                        isLessthanTotalElement={isLessthanTotalElement()}
                                        showCustomActions
                                        customActionColumnFormatters={customActionColumnFormatters}
                                    />


                                    {
                                        mode === 'add' ? <FormPopup
                                            title='Add Role'
                                            width={480}
                                            height={'auto'}
                                            visible={(open && mode === 'add')}
                                            setVisible={() => handleClose()}
                                            wrapperAttr={{ class: 'add-wrapper' }}
                                            isSaveDisabled={scope.length === 0 || addedRoleName === ''}
                                            onSave={saveRole}>
                                            <InputWithLabel
                                                inputLabel='Role'
                                                stylingMode='outlined'
                                                value={addedRoleName}
                                                onValueChanged={handleRoleAdd}
                                            />
                                            <MultiSelectTagWithLabel
                                                inputLabel='Scopes'
                                                stylingMode='outlined'
                                                showClearButton
                                                searchEnabled
                                                dataSource={scopeList}
                                                value={scope}
                                                onValueChanged={handleScope}
                                            />
                                        </FormPopup> : null
                                    }

                                    {
                                        mode === 'addUser' ? <FormPopup
                                            title='Maintain Users in Role'
                                            width={480}
                                            height={'auto'}
                                            visible={(open && mode === "addUser")}
                                            setVisible={() => handleClose()}
                                            wrapperAttr={{ class: 'maintain-users-in-role' }}
                                            isSaveDisabled={roleUserArray.length === 0}
                                            onSave={addUserRole}
                                        //  isSaveDisabled={isAddDisabled}
                                        >
                                            <ScrollView
                                                direction="vertical">
                                                <InputWithLabel
                                                    inputLabel='Role'
                                                    stylingMode='outlined'
                                                    defaultValue={role.roleName}
                                                    value={roleName}
                                                    onValueChanged={handleRoleEditUser}
                                                    disabled={mode === 'addUser'}
                                                />
                                                <div className='dx-field'>
                                                    <div className="dx-field-label">
                                                        <span>Search</span>
                                                    </div>
                                                    <div className='dx-field-value'>
                                                        <SearchBar
                                                            className={'search-bar'}
                                                            placeholder={`Search`}
                                                            value=""
                                                            onChange={handleChangeSearch}
                                                            onCancelSearch={OnCancelSearch}
                                                        />
                                                    </div>
                                                </div>
                                                <Grid style={{ overflow: 'auto' }} container spacing={6} className="add-lable">
                                                    <Grid item xs={12} md={5} sm={4}>
                                                        <strong>Existing Users</strong>
                                                    </Grid>
                                                    <Grid item xs={12} md={2} sm={4}>
                                                    </Grid>
                                                    <Grid item xs={12} md={5} sm={4} >
                                                        <strong>Add  Users</strong>
                                                    </Grid>
                                                    <Grid item xs={12} md={5} sm={4}>
                                                        {existsUser ? customList(existsUser) : ''}
                                                    </Grid>
                                                    <Grid item xs={12} md={2} sm={4}>
                                                        <Grid container direction="column" alignItems="center">
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                className="btnclass left"
                                                                onClick={handleCheckedLeft}
                                                                disabled={notExistsUserChecked.length === 0}
                                                                aria-label="move selected left"
                                                            >
                                                                &lt;&lt; Add User
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                className="btnclass right"
                                                                onClick={handleCheckedRight}
                                                                disabled={existsUserChecked.length === 0}
                                                                aria-label="move selected right"
                                                            >
                                                                Remove User  &gt;&gt;
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={5} sm={4}>{notExistsUser ? customList(notExistsUser) : ''}</Grid>
                                                </Grid>
                                            </ScrollView>
                                        </FormPopup>
                                            : null
                                    }

                                    {
                                        mode === 'edit' ? <FormPopup
                                            title='Role Info'
                                            width={480}
                                            height={'auto'}
                                            visible={(open && mode === "edit")}
                                            setVisible={() => handleClose()}
                                            wrapperAttr={{ class: 'edit-wrapper' }}
                                            onSave={saveRole}>
                                            <InputWithLabel
                                                inputLabel='Role'
                                                stylingMode='outlined'
                                                defaultValue={role.roleName}
                                                value={roleName}
                                                onValueChanged={handleRoleEdit}
                                            />
                                            <MultiSelectTagWithLabel
                                                inputLabel='Scopes'
                                                stylingMode='outlined'
                                                showClearButton
                                                searchEnabled
                                                dataSource={scopeList}
                                                value={editedScopes}
                                                onValueChanged={handleSetEditScope}
                                            />
                                        </FormPopup> : null
                                    }

                                    {mode === 'delete' ? <ModalBox
                                        visible={(open && mode === "delete")}
                                        onHiding={handleClose}
                                        showCloseButton={true}
                                        dragEnabled={true}
                                        showTitle={true}
                                        title={`Delete Role Name - ${roleName}`}
                                        width={480}
                                        height={'auto'}
                                        hideOnOutsideClick
                                        wrapperAttr={{ class: 'delete-wrapper' }}>
                                        <div>
                                            <div>
                                                <span>Are you sure that you want to delete this Role? </span>
                                            </div>
                                        </div>
                                        <div className='delete-btn'>
                                            <Buttons text='OK' className='btn-role' type='default' onClick={deleteRole} />
                                            <Buttons text='Cancel' type='normal' onClick={handleClose} />
                                        </div>
                                    </ModalBox> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </div >
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RoleListComponent);


interface RoleProps {
    toggleLoader: any;
}