import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleLoader } from '../../redux/actions/loader.action';
// MUI
import {  Dialog, DialogContent, DialogTitle, IconButton, Typography, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Services
import Toaster from '../../services/toaster.services';
import RestApiService from '../../services/http-services';

// Api
import { ApiEndPoints } from '../../models/api-endpoint';

// Packages
import moment from 'moment';

// Constant
import appConstants from "../../core/constants/appConstants";

// Axios
import axios, { AxiosError } from 'axios';

// Utils
import { changeEpochToDate } from '../../utils/utilityFunctions';

// Common Component
import DynamicTableList from '../../shared/components/commonui/DynamicTableList/DynamicTableList';

// Scss
import "../../../assets/themes/default/share.scss";
import "./fileList.scss";


const FileListComponent: React.FC<Props> = (props: Props) => {
  const [file, setFile] = React.useState<any>();
  const [fileDetails, setFileDetails] = React.useState<any>();
  const [itemDetails, setItemDetails] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [direction, setDirection] = useState(-1);
  const [orderBy, setOrderBy] = useState("CreatedAt");
  const [search, setSearch] = React.useState<any>();
  const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'uploadDate', 'order': 'desc', 'isActive': false }])

  useEffect(() => {
    if (pageSize && currentPageNo && direction) {
      getFileList({ pageNo: currentPageNo, pageSize: pageSize, order: direction, orderBy: orderBy });
    }
  }, [pageSize, currentPageNo, direction, orderBy]);


  const getConvertFunctionByColumn = useCallback((value: string) => {
    const date = changeEpochToDate(parseInt(value, 10));
    const stillUtc = moment.utc(date).toDate();
    return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss');
  }, [])

  const getFileList = (query: object = {}) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_FILES_LIST, null, null, query, {
      ETId: appConstants.SCHEMA_DATA.ETId,
      SV: appConstants.SCHEMA_DATA.SV,
    }).then(res => {
      props.toggleLoader(false);
      const updatedFile = res.data.data.map((item) => {
        const createdAt = moment(item && item.CreatedAt).format('YYYY-MM-DD HH:mm:ss').toLocaleString() || 'N/A';
        return { ...item, ...item.documentVersion, createdAt }
      })
      setFile(updatedFile);
      setTotalRecord(res.data.total);
      if (parseInt(res.data.total) % pageSize === 0) {
        setTotalPage((parseInt(res.data.total) / pageSize));
      }
      else {
        setTotalPage((Math.floor(parseInt(res.data.total) / pageSize)) + 1);
      }
    }).catch((error) => {
      if (error.message === 'Network Error') {
        Toaster.errorToaster(error.message)
      }
      if (error?.response?.data?.errors?.length > 0) {
        if (error?.response?.data?.errors[0]?.message === "Invalid ETId") {
          Toaster.errorToaster("Intialize Schema first");
        }
        else {
          Toaster.errorToaster(error?.response?.data?.errors[0]?.message);
        }
      }
      props.toggleLoader(false);
      console.log('Get error in file list', error);
    })
  };

  const onFileChange = (file: any) => {
    if (file.target && file.target.files && file.target.files.length > 0) {
      setFileDetails(file.target.files[0].name);
      props.toggleLoader(true);
      let dataResult = new FormData();
      dataResult.append('file', file.target.files[0]);

      RestApiService.invoke(ApiEndPoints.UPLOAD_FILES, null, dataResult, { from: 'UPLOAD' }, {  // UPLOAD FILE WITH V2 API
        // ETId: 17,
        SV: appConstants.SCHEMA_DATA.SV,
      }).then(res => {
        props.toggleLoader(false);
        setFileDetails("");
        Toaster.successToaster("onFileChange", "Upload File Successful");
        getFileList({ pageNo: 1, pageSize: pageSize, order: direction, orderBy: orderBy });
      }).catch(error => {
        props.toggleLoader(false);
        console.log('Get error in file list', error);
        Toaster.errorToaster(error);
      })
    }
  }

  useEffect(() => {
    if (search) {
      getFileList({ pageNo: 1, search: search });
    }
  }, [search]);

  const onSearchData = (searchData: any) => {
    setSearch(searchData);

  }

  const onSortingData = useCallback((order: any, orderBy: any, data: any) => {
    setCurrentPageNo(1);
    setDirection(order);
    setOrderIndexedFields(data);
  }, []);


  const handleAction = (item: any, action: any) => {
    if (action == 'view') {
      setItemDetails(item);
      handleOpen();
    }
  }

  const goToPreviousPage = useCallback((page: any) => {
    setCurrentPageNo(page);
  }, [])

  const goToNextPage = useCallback((page: any) => {
    setCurrentPageNo(page);
  }, [])

  const isLessthanTotalElement = useCallback(() => {
    if ((currentPageNo * pageSize) === (totalPage - 1))
      return true;
    else
      return false;
  }, [currentPageNo, pageSize, totalPage])

  const changePageSize = useCallback((value: any) => {
    setPageSize(value);
    setCurrentPageNo(1);
  }, [])


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const tableColumnList = useMemo(() => [
    { name: "Id", db: "_id" },
    { name: "File Name", db: "fileName" },
    { name: "Content Type", db: "mimetype" },
    { name: "Upload Date", db: "createdAt", format: getConvertFunctionByColumn, sort: false }
  ], [getConvertFunctionByColumn]);


  return (
    <div className="Innerheading">
      <div className='Innerdetails dx-theme-background-color'>
        <div className="grid fileList">
          <DynamicTableList
            dataSource={file}
            columnList={tableColumnList}
            columnAutoWidth={false}
            showPagination
            fromComponent={'FileListComponent'}
            goToNextPage={goToNextPage}
            changePageSize={changePageSize}
            pageSize={pageSize}
            currentPageNo={currentPageNo}
            totalPage={totalPage}
            totalRecord={totalRecord}
            goToPreviousPage={goToPreviousPage}
            isLessthanTotalElement={isLessthanTotalElement()}
          />
        </div>
      </div>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="modalpopoup">
        <DialogTitle disableTypography>
          <Typography variant="h6">View Detail</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow hover>
                  <TableCell >
                    <strong>Id</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails._id}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>File Name</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails.filename}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>Length</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails.length}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>Md5</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails.md5}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>Update date </strong>
                  </TableCell>
                  <TableCell >
                    <span>{moment(itemDetails && itemDetails.uploadDate).format('YYYY-MM-DD HH:mm:ss').toLocaleString() || 'N/A'}</span>
                  </TableCell>

                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>

        </DialogContent>

      </Dialog>

    </div>
  );
}

const mapStateToProps = (state: any) => ({
 
});

const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FileListComponent);


interface Props {
  toggleLoader: any;
}