import React, { useState, useEffect, useCallback } from "react";
import './sharing.scss';
import '../../../../../assets/themes/default/share.scss'
import DataGrid, { Column, ColumnChooser, ColumnFixing, Editing, Paging, Sorting } from 'devextreme-react/data-grid';
import Button from "../Button/Button";
import DynamicPagination from "../Pagination/Pagination";
import moment from 'moment';
import { changeEpochToDate } from "../../../../utils/utilityFunctions";
import Icon from "../Icon/Icon";
import { CreateShare } from "../../../../../assets/svg";
import { Tooltip } from '@material-ui/core';



interface Props {
  id?: string;

  /**
* Specifies whether the outer borders of the UI component are visible.
*/
  showBorders?: boolean;

  /**
* Specifies whether columns should adjust their widths to the content.
*/
  columnAutoWidth?: boolean;

  /**
* Specifies whether a user can reorder columns.
*/
  allowColumnReordering?: boolean;

  dataSource?: any;

  /**
  * Specifies the key property (or properties) that provide(s) key values to access data items. Each key value must be unique. This property applies only if data is a simple array.
  */
  keyExpr?: string | Array<string>;

  /**
* Specifies whether rows should be shaded differently.
*/
  rowAlternationEnabled?: boolean;

  /**
     * Specifies whether text that does not fit into a column should be wrapped.
     */
  wordWrapEnabled?: boolean;

  /**
    * Specifies the UI component&apos;s height.
    */
  height?: number | string | (() => number | string);

  enabled?: any;

  mode?: any;

  allowUpdating?: any;

  allowDeleting?: any;

  allowAdding?: any;

  showEdit?: boolean;

  useIcons?: any;

  columnList?: any;

  showActions?: boolean;


  headerCellRender?: (...params: any) => React.ReactNode;

  dataField?: any;

  order?: string | number;

  viewCredentialExport?: any;

  viewCredentialExportData?: any;

  viewCredentialApprove?: any;

  viewCredentialApproveData?: any;

  orderIndexedFields?: any;

  onSortingData?: any;

  currentPageNo?: number;

  totalPage?: number;

  isLessthanTotalElement?: boolean;

  totalRecord?: number;

  pageSize?: number;

  goToPreviousPage?: any;

  goToNextPage?: any;

  changePageSize?: any;

  primaryColumn?: any;

  showDelete?: boolean;

  showView?: boolean;

  keysToHide?: any;

  showUserEdit?: boolean;

  showAdd?: boolean;

  isOrderIconChange?: boolean;

  fromComponent?: any;

  SNo?: boolean;

  showPagination?: boolean;

  createContract?: boolean;

  shareData?: boolean;

  showColumnLines?: boolean;

  showRowLines?: boolean;

  contractData?: boolean;

  fromOrganization?: boolean;

  hoverStateEnabled?: boolean;

}




function Sharing({
  id = "gridContainer",
  goToPreviousPage,
  goToNextPage,
  isLessthanTotalElement,
  totalRecord,
  totalPage,
  currentPageNo,
  onSortingData,
  viewCredentialExport,
  viewCredentialExportData = true,
  orderIndexedFields,
  viewCredentialApprove,
  viewCredentialApproveData,
  allowAdding = false,
  useIcons,
  showEdit = true,
  showBorders = true,
  columnAutoWidth = true,
  allowColumnReordering = false,
  dataSource, keyExpr,
  rowAlternationEnabled = true,
  wordWrapEnabled = true,
  height,
  enabled = false,
  mode = 'row',
  allowUpdating = true,
  allowDeleting = true,
  columnList,
  showDelete,
  showView,
  showActions,
  showAdd,
  isOrderIconChange,
  showPagination = true,
  createContract = false,
  shareData = false,
  showColumnLines = false,
  showRowLines = false,
  contractData = false,
  fromOrganization = false,
  hoverStateEnabled = false,

  ...props }: Props) {
  const [pageSizeList, setPageSizeList] = useState<any>([{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }]);
  const [order, setOrder] = useState<any>('desc');
  const [orderBy, setOrderBy] = useState<any>();
  const [orderedData, setOrderedData] = useState<any>(orderIndexedFields);
  const [pageSize, setPageSize] = useState();
  const [rows, setRows] = useState<any>([]);
  const [headerRendered, setHeaderRendered] = useState(false);
  const defaultPageSize = 10;


  // Header Column Table
  const headerRenderColumnByName = useCallback((headerDbName: string, headerName: string, sort = false) => {
    if (sort === true) {
      const HeaderRenderComponent = () => {
        return (
          <div
            className="actionBtn"
          >
            {headerName}
            <Button
              className='sortingButton'
              onClick={() => handleSorting(headerDbName, (order === "asc" ? "desc" : "asc"))} icon={order === "asc" ? "arrowup" : "arrowdown"}
            />
          </div>)
      }
      return HeaderRenderComponent;
    }
    const DefaultHeaderRendererComponent = () => {
      return (
        <div className='grid-header'>{headerName}</div>
      )
    }
    return DefaultHeaderRendererComponent;
  }, [order, orderBy]);


  // Header Column Action



  const actionColumnFormatter = useCallback((
    value: any,
    viewCredentialExport: any,
    viewCredentialExportData: any,
    viewCredentialApprove: any,
    viewCredentialApproveData: any,
    fromOrganization?: boolean,

  ) => {

    const handleViewCredentialExportEdit = () => {
      viewCredentialExport(true)
      viewCredentialExportData(value?.data)
    }

    const handleViewCredentialApprove = () => {
      viewCredentialApprove(true)
      viewCredentialApproveData(value.data)
    }
    return (
      <div className="share-actionBtn">
        {
          createContract === false && (
            <div className="contract-org">
              {(value.data.ORGType == "Receiver") && <Button
                className="exportBtn btn"
                text="Export"
                type="default"
                onClick={handleViewCredentialExportEdit}
              />}
              {(value.data.ORGType == "Receiver") && <Button
                className="approveBtn btn"
                text="Share Data"
                type="default"
                onClick={handleViewCredentialApprove}
              />}
            </div>
          )
        }
        {
          shareData && ((value.data.ORGType == "Receiver") && ((value.data.ESS < 20) || (value.data.ESS >= 40 && value.data.ESS < 60))) && <div className="share-approve-btn">
            <Button className="approveBtn btn" text="Approve" type="default" onClick={handleViewCredentialApprove}
            />
          </div>
        }
        {
          shareData && ((value.data.ORGType == "Sender") && (value.data.ESS < 40)) && <div className="share-approve-btn">
            <Button className="approveBtn btn" text="Approve" type="default" onClick={handleViewCredentialApprove}
            />
          </div>
        }

        {
          contractData === false && (value.data.ORGType == "Contract") && <div className="org-create">
            <Button className="approveBtn btn" text="Contract" type="default" onClick={handleViewCredentialApprove}
            />
          </div>
        }
        {
          (fromOrganization && !value?.data?.IsDefaultOrg) ? <div className="org-btn-group">
            <Button className="cerate-org-btn" text="Create Contract" type="default" onClick={handleViewCredentialApprove}
            />
            <CreateShare
              className="edit-org"
              onClick={handleViewCredentialExportEdit}
            />
          </div>
            : null
        }


      </div>
    )
  }, [viewCredentialExport, viewCredentialExportData, viewCredentialApprove, viewCredentialApproveData, fromOrganization])

  // Handle Sorting


  const handleSorting = (orderBy: any, order: string): any => {
    setOrder(order);
    setOrderBy(orderBy);
    const data = orderIndexedFields.map((item: any) => ({ ...item, order: 'desc' }));

    const index = data.findIndex((obj: any) => obj.orderBy === orderBy);
    data[index].order = order === 'desc' ? 'desc' : 'asc';
    const index1 = data.findIndex((obj: any) => obj.isActive);
    if (index1 > -1) {
      data[index1].isActive = false;
    }
    data[index].isActive = true;
    setOrderedData(data);
  };

  useEffect(() => {
    if (order && orderBy && onSortingData && orderedData) {
      onSortingData(order, orderBy, orderedData)
    }
  }, [order, orderBy]);


  // Handle Dropdown
  let pageSizeLst = [{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }]

  const noOfPageDropdown = () => {
    if (totalRecord <= 10) setPageSizeList(pageSizeLst.slice(0, 1))
    else if (totalRecord > 10 && totalRecord <= 25) setPageSizeList(pageSizeLst.slice(0, 2))
    else if (totalRecord > 25 && totalRecord <= 50) setPageSizeList(pageSizeLst.slice(0, 3))
    else setPageSizeList(pageSizeLst.slice(0, 4))
  }

  useEffect(() => {
    noOfPageDropdown()
  }, [totalRecord])

  // Change Page Size
  const changePageSize = (event: any) => {
    setPageSize(event);
  }

  useEffect(() => {
    if (pageSize) {
      props.changePageSize(pageSize);
    }
  }, [pageSize]);


  const findRows = (data: any) => {
    if (data && data.length > 0) {
      for (const obj of data) {
        for (const key of Object.keys(obj)) {
          if (!rows.includes(key)) {
            rows.push(key)
            setRows([...rows]);
          }
        }
      }
    }
  }

  const getConvertFunctionByColumn = useCallback((columnname: string) => {
    if ((columnname === 'StartDate' || columnname === 'startDate' ||
      columnname === 'EndDate' || columnname === 'endDate' ||
      columnname === 'CreatedAt' || columnname === 'createdAt')) {
      const convertDate = (value: any) => {
        const date = changeEpochToDate(value);
        const stillUtc = moment.utc(date).toDate();
        return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss');
      }
      return convertDate;
    }
    else {
      return undefined
    }
  }, [])




  useEffect(() => {
    if (dataSource)
      findRows(dataSource);
  }, []);

  return (

    <div className="dynamic-lists">
      <div className="dynamic-tables">
        <DataGrid
          className="sharing-table"
          id={id}
          showBorders={showBorders}
          columnAutoWidth={columnAutoWidth}
          allowColumnReordering={allowColumnReordering}
          dataSource={dataSource}
          rowAlternationEnabled={rowAlternationEnabled}
          wordWrapEnabled={wordWrapEnabled}
          keyExpr={keyExpr}
          height={height}
          showRowLines={showRowLines}
          showColumnLines={showColumnLines}
          columnMinWidth={130}
          hoverStateEnabled
          scrolling={{
            mode: 'standard',
            rowRenderingMode: 'standard',
            showScrollbar: 'always',
            useNative: true
          }}
          {...props}
        >
          <Paging enabled={enabled} />
          {
            showEdit ? <Editing
              mode={mode}
              allowUpdating={allowUpdating}
              allowDeleting={allowDeleting}
              allowAdding={allowAdding}
              useIcons={useIcons}
            /> : null
          }

          <ColumnChooser enabled={enabled} />
          <ColumnFixing enabled={enabled} />
          <Sorting mode="none" />

          {columnList && columnList.map((columnObject: any) => {
            return <Column
              headerCellRender={headerRenderColumnByName(columnObject.db, columnObject.name, columnObject.sort)}
              key={columnObject.name}
              dataField={columnObject.db}
              alignment={'left'}
              {...columnObject}
            />
          })}

          {
            showActions ? <Column
              dataField={'Action'}
              cellRender={(value: any) => actionColumnFormatter(value, viewCredentialExport, viewCredentialExportData, viewCredentialApprove, viewCredentialApproveData, fromOrganization)} /> : null
          }
        </DataGrid>
      </div>
      {
        showPagination &&
        <DynamicPagination
          currentPageNo={currentPageNo}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          totalPage={totalPage}
          dataLength={dataSource?.length || 0}
          isLessthanTotalElement={isLessthanTotalElement}
          changePageSize={changePageSize}
          totalRecord={totalRecord}
        />
      }

    </div>
  )
}





export default Sharing;