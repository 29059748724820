import React from 'react'

interface IPropsLabelText {
    inputLabel?: string;
    title?: any;
}


function LabelText({ inputLabel, title, ...props }: IPropsLabelText) {
    return (
        <div className='dx-field'>
            <div className="dx-field-label"><strong>{inputLabel}</strong></div>
            <div className='dx-field-value'>{title}</div>
        </div>
    )
}

export default LabelText;