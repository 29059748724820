import * as React from 'react';

export function Admin({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M17.572 11.215c-.11.286-.24.566-.39.837l1.3 1.88-2.414 2.306H10V0h2.008l.578 2.163c.32.098.634.214.94.347l2.125-1.165 2.84 2.53-1.308 1.895c.15.271.28.55.39.836L20 7.122V10.7l-2.428.515z" fill="#FF9E22" />
                <path d="M10 0v16.238H4.177l-2.674-2.282 1.314-1.904a6.753 6.753 0 0 1-.39-.837L0 10.7V7.122l2.428-.516c.11-.285.24-.565.39-.836l-1.31-1.895 2.84-2.53L6.476 2.51c.305-.133.618-.249.939-.347L7.992 0H10z" fill="#FFD322" />
                <path d="M16.889 17.374V20H10v-8.119h.725c2.112 0 4.053.947 5.191 2.532.637.886.973 1.91.973 2.96z" fill="#002659" />
                <path d="M10 11.881V20H3.111v-2.626c0-1.052.336-2.075.973-2.96 1.138-1.586 3.08-2.533 5.191-2.533H10z" fill="#39326C" />
                <path d="M14.417 9.084c0 2.17-1.981 3.936-4.417 3.936V5.149c2.436 0 4.417 1.765 4.417 3.935z" fill="#5F55AE" />
                <path d="M10 5.149v7.87c-2.436 0-4.417-1.765-4.417-3.935S7.564 5.15 10 5.15z" fill="#918EDD" />
            </g>
        </svg>
    );
}
