import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarMenu from '../../../models/SidebarMenu.list';
import './sideBar.scss';
import { AuthService } from '../../../core/interceptors/auth.interceptor';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { logoutUser, changePassword } from '../../../redux/actions/authActions';
import { CookieStorage } from 'cookie-storage';
import { toggleSideBar } from '../../../redux/actions/sidebarAction';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import AdminSideBarComponent from '../adminsidebar/adminSideBar';
import { useCookies } from "react-cookie";
import appConstants from "../../../core/constants/appConstants";
import Drawer from "devextreme-react/drawer";
import List from "devextreme-react/list.js";
import { Tooltip } from 'devextreme-react';
import { useScreenSize } from '../../../utils/media-query';
const cookies = new CookieStorage();



const SideBarComponent = (props: any) => {
    const [cookie, setCookie, removeCookie] = useCookies(['theme']);
    const [user, setUser] = useState<any>();
    const [isThemeSet, setIsThemeSet] = useState(true);
    const [isShowAdmin, setIsShowAdmin] = useState(false);
    const { isXSmall, isLarge } = useScreenSize();

    useEffect(() => {
        setUser(props.auth.user)
    }, [props.auth]);



    const onLogout = () => {
        let theme = cookies.getItem('theme');
        props.logoutUser();
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        cookies.removeItem("firstname");
        cookies.removeItem("lastname");
        cookies.clear();
        cookies.setItem("theme", theme);
        props.history.push('/landing');
    }
    const location = useLocation();
    const authService = new AuthService();




    useEffect(() => {
        const cookiesData = cookies.getItem('CanChangePassword');
        cookiesData === 'true' ? setIsShowAdmin(true) : setIsShowAdmin(false);
    });



    useEffect(() => {
        if (isThemeSet) {
            dynamicThemeApply();
            setIsThemeSet(false);
        }
    }, [isThemeSet]);
    const dynamicThemeApply = () => {
        var theme = cookie.theme;
        if (theme === appConstants.THEME.DARK_BLUE) {
            document.documentElement.style.setProperty('--color', '#fff');
            document.documentElement.style.setProperty('--font-color', '#fff');
            document.documentElement.style.setProperty('--tab_background_color', '#23395d');
            document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
            document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
            document.documentElement.style.setProperty('--border_color', '#006eaf');
            document.documentElement.style.setProperty('--border_color1', 'transparent');
            document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
            document.documentElement.style.setProperty('--selected_background_color', '#7488aa');
            document.documentElement.style.setProperty('--background-color', '#23395d');
            document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
            document.documentElement.style.setProperty('--Bad_color', '#c9817d');
            document.documentElement.style.setProperty('--Good_color', '#9bc798');
            document.documentElement.style.setProperty('--hover_background_color', '#305695');
        }
        else if (theme === appConstants.THEME.ALICE_BLUE) {
            document.documentElement.style.setProperty('--color', '#fff');
            document.documentElement.style.setProperty('--font-color', '#fff');
            document.documentElement.style.setProperty('--tab_background_color', '#3E849E');
            document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
            document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
            document.documentElement.style.setProperty('--border_color', '#06b5f4');
            document.documentElement.style.setProperty('--border_color1', 'transparent');
            document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
            document.documentElement.style.setProperty('--selected_background_color', '#2e7691');
            document.documentElement.style.setProperty('--background-color', '#3E849E');
            document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
            document.documentElement.style.setProperty('--Bad_color', '#c9817d');
            document.documentElement.style.setProperty('--Good_color', '#9bc798');
            document.documentElement.style.setProperty('--hover_background_color', '#2e7691');
        }

        else {
            document.documentElement.style.setProperty('--color', '#0078d4');
            document.documentElement.style.setProperty('--font-color', '#000000');
            document.documentElement.style.setProperty('--tab_background_color', '#ffffff');
            document.documentElement.style.setProperty('--table_header_color', '#c2c0bd');
            document.documentElement.style.setProperty('--table_header_background', '#c2c0bd');
            document.documentElement.style.setProperty('--border_color', '#ccc');
            document.documentElement.style.setProperty('--border_color1', '#c2c0bd');
            document.documentElement.style.setProperty('--btn_background_color', '#0078d4');
            document.documentElement.style.setProperty('--selected_background_color', '#f1f6ff');
            document.documentElement.style.setProperty('--Pending_color', '#fdf0c8');
            document.documentElement.style.setProperty('--Bad_color', '#f8cecc');
            document.documentElement.style.setProperty('--Good_color', '#d5e8d4');
            document.documentElement.style.setProperty('--background-color', '#fff');
        }
    }

    const renderItem = (item: any, index: any) => (item.type && item.type === 'DROPDOWN') ? item.menu(props) :
        <li className={`menu-link ${(item.name === 'Logout') ? 'logoutitem' : ''} `} key={"s_nll" + index}>
            {item.name !== "Admin" && item.name !== "Logout" && <NavLink activeClassName="active" className={`link ${(item.name === 'Admin' && location.pathname === "/page/admin/roles") ? 'active' : ''} `} to={item.url} key={"s_nl" + index}>
                <div className='devListIcon' id={item.elementAttr.id}><span className='svg-root'>{item.icon}</span></div>
                <div className='dev-listRoot'><span className='text-title'>{item.name}</span></div>
            </NavLink>}

            {item.name === "Admin" && isShowAdmin && <NavLink activeClassName="active" className={`link ${(item.name === 'Admin' && location.pathname === "/page/admin/roles") ? 'active' : ''} `} to={item.url} key={"s_nl" + index}>
                <div className='devListIcon' id={item.elementAttr.id}><span className='svg-root'>{item.icon}</span></div>
                <div className='dev-listRoot'> <span className='text-title'>{item.name}</span></div>
            </NavLink>}
            {(item.name === "Logout") && <NavLink activeClassName="active" className={`link ${(item.name === 'Admin' && location.pathname === "/page/admin/roles") ? 'active' : ''} `} to={item.url} onClick={() => onLogout()} key={"s_nl" + index}>
                <div className='devListIcon' id={item.elementAttr.id}><span className='svg-root'>{item.icon}</span></div>
                <div className='dev-listRoot'> <span className='text-title'>{item.name}</span></div>
            </NavLink>}
        </li>

    const renderContentHomeIcon = useCallback(() => {
        return (
            <div>Home</div>
        )
    }, []);

    const renderContentDashboardIcon = useCallback(() => {
        return (
            <div>Dashboard</div>
        )
    }, []);

    const renderContentSchemaIcon = useCallback(() => {
        return (
            <div>Schema</div>
        )
    }, []);

    const renderContentBPMIcon = useCallback(() => {
        return (
            <div>BPM</div>
        )
    }, []);

    const renderContentListIcon = useCallback(() => {
        return (
            <div>List</div>
        )
    }, []);

    const renderContentAdminIcon = useCallback(() => {
        return (
            <div>Admin</div>
        )
    }, []);

    const renderContentShareIcon = useCallback(() => {
        return (
            <span>Sharing</span>
        )
    }, []);

    const renderContentLogoutIcon = useCallback(() => {
        return (
            <div>Logout</div>
        )
    }, []);

    const ListMenu = () => {
        return (
            <div className="list-menu">
                <List
                    items={SidebarMenu}
                    selectionMode="single"
                    itemRender={renderItem}
                    className="logoutbtn"
                    hoverStateEnabled={false}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                />
                <Tooltip
                    target="#sideBarHomeIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentHomeIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideDashboardIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentDashboardIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideSchemaIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentSchemaIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideBPMIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentBPMIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideListIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentListIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideShareIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentShareIcon}
                    position="right"
                />
                <Tooltip
                    target="#sideAdminIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentAdminIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideLogoutIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentLogoutIcon}
                    position="right"
                    onPositionChange={onLogout}
                />

            </div>
        )
    }

    return (
        <div className='adminsidebar'>
            <Drawer
                className={`drawer ${props.openMenu ? "closestrip" : ""}`}
                component={ListMenu}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={isXSmall ? 0 : 60}
                maxSize={250}
                shading={isLarge ? false : true}
                style={{ boxShadow: '0 0 10px 0 rgba(67, 89, 113, 0.15)' }}
            >
            </Drawer>
            {location.pathname === "/page/admin/users" || location.pathname === "/page/admin/roles" || location.pathname === "/page/admin/settings" || location.pathname === "/page/admin/clusters" || location.pathname === "/page/admin/clusters/node" || location.pathname === "/page/admin/clusters/compare" || location.pathname === "/page/admin/clusters/compare/comparison" ? <AdminSideBarComponent /> : null}
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    isShowSideBar: state.appSidebarReducer
});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleSideBar: toggleSideBar, logoutUser, changePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBarComponent));
