import { ERROR_GET_ERRORS, ERROR_CLEAR_ERRORS } from '../types/errorTypes';

const initialState = {};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ERROR_GET_ERRORS:
      return action.payload;
    case ERROR_CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
