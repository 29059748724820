import React, { useCallback, useEffect } from 'react';
import './header.scss';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { logoutUser, changePassword } from '../../../redux/actions/authActions';
import { ApiEndPoints } from '../../../models/api-endpoint';
import RestApiService from "../../../services/http-services";
import { toggleLoader } from '../../../redux/actions/loader.action';
import Toaster from '../../../services/toaster.services';
import { CookieStorage } from 'cookie-storage';
import { Toolbar, Item } from 'devextreme-react/toolbar';
import ThemeSwitcherComponent from '../commonui/ThemeSwitcher';
import UserMenu from '../commonui/UserMenu';
import { bindActionCreators } from 'redux';
import { ChangeProfilePasswordForm } from '../change-password-form/ChangePasswordForm';

const cookies = new CookieStorage();


const HeaderComponent = (props: any) => {
    const [errors, setErrors] = React.useState({});
    const [showResetPassword, setShowResetPassword] = React.useState<any>(false);
    const [user, setUser] = React.useState<any>();

    useEffect(() => {
        setUser(props.auth.user)
    }, [props.auth]);


    const onLogout = () => {
        let theme = cookies.getItem('theme');
        // authService.logout();
        props.logoutUser();
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        cookies.removeItem("firstname");
        cookies.removeItem("lastname");
        cookies.setItem("theme", theme);
        // props.history.push({
        //     pathname: `/login`
        // });
        // props.history.push('/login');
        props.history.push('/landing');
        // cookies.removeItem('access_token');
        cookies.clear();
    }
    useEffect(() => {
        onFontSize();
    })

    const onSubmit = (event) => {
        props.toggleLoader(true);
        props.changePassword(false);
        RestApiService.invoke(ApiEndPoints.RESET_PASSWORD, null, event, null, {
            ETId: 10,
            SV: 1,
        }).then(res => {
            props.changePassword(true);
            Toaster.successToaster("passwordChange", "Password Changed Successfully");
            setShowResetPassword(false);
            props.toggleLoader(false);
        }).catch(error => {
            if (error.message === 'Network Error') {
                Toaster.errorToaster(error.message)
            }
            if (error?.response?.data?.errors?.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
            props.changePassword(false);
            console.log('Get error', error);
            setErrors(error);
            props.toggleLoader(false);
        });
    }
    const onFontSize = () => {
        let fontSide = cookies.getItem('fontSize');

        switch (fontSide) {
            case 'Large':
                document.documentElement.style.setProperty('--h3-font-size', '19px');
                return document.documentElement.style.setProperty('--font-text-size', '18px');
            case 'Medium':
                document.documentElement.style.setProperty('--h3-font-size', '17px');
                return document.documentElement.style.setProperty('--font-text-size', '15px');
            default:
                document.documentElement.style.setProperty('--h3-font-size', '15px');
                return document.documentElement.style.setProperty('--font-text-size', '12px');
        }

    }

    const buttonOptions = {
        icon: "menu",
        onClick: () => {
            props.onClick(!props.openMenu)
        }
    };

    const handleShowResetPassword = useCallback(() => {
        setShowResetPassword(false)
    }, [])

    const onChangePasswordClick = useCallback(() => {setShowResetPassword(true)},[]);

    const goToLandingPage = useCallback(() => {
        props.history.push('/landing')
    },[props.history]);

    return (

        <div className="header-page">
            <Toolbar id="toolbar" className="toolbar">
                <Item
                    cssClass="Icon"
                    widget="dxButton"
                    options={buttonOptions}
                    location="before" />

                <Item cssClass="walacorLogo" location="before">
                    <span className="header-logo">
                        <img src={require('../../../../assets/images/Walacor_Logo2.png')} alt="The Platform" title={"The Platform"} />
                    </span>
                </Item>

                <Item cssClass="titleItemClass" location="before">
                    <h2 className="title">
                        The Platform
                    </h2>
                </Item>


                <Item
                    cssClass="themeSwithcer"
                    location="after"
                >
                    <ThemeSwitcherComponent />
                </Item>

                <Item location="after">
                    <UserMenu
                        title={cookies.getItem('usershortname')}
                        onHomeClick={goToLandingPage}
                        onLogoutClick={onLogout}
                        onChangePasswordClick={onChangePasswordClick}
                    />
                </Item>
            </Toolbar>
            {
                <ChangeProfilePasswordForm
                    visible={showResetPassword}
                    onSubmitForm={onSubmit}
                    setVisible={handleShowResetPassword} />
            }
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    isShowSideBar: state.appSidebarReducer
});
const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader, logoutUser: logoutUser, changePassword: changePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderComponent));