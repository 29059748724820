import React from "react";
import { Fragment } from "react";

// MUI
import { Card, CardContent, CardHeader, Grid, Tooltip } from "@material-ui/core";

// Common Component
import Button from "../../shared/components/commonui/Button/Button";

// Route
import { NavLink } from "react-router-dom";


interface IHealthCard {
    item: any,
    index: any,
    envelopeDetail: any,
    envelopeIndex: any,
    viewEnvelopeHealthCardDetails: () => void,
    closeCardDetails: () => void
}

const HealthCard = ({ item, index, envelopeDetail, envelopeIndex, viewEnvelopeHealthCardDetails, closeCardDetails }: IHealthCard) => {
    return (
        <Grid item xs={12} md={3} key={`envelope_${index}`}>
            <Card className={`health-card ${item.Health === 1 ? 'Good' : ''}
                          ${item.Health === 2 ? 'Pending' : ''}
                          ${item.Health === 3 ? 'Bad' : ''} `}>
                <CardHeader
                    className="health-header"
                    action={
                        <Fragment>
                            {envelopeIndex !== index &&
                                <Button icon='chevrondown' stylingMode='text' onClick={viewEnvelopeHealthCardDetails} />}

                            {envelopeIndex === index &&
                                <Button icon='chevronup' stylingMode='text' onClick={closeCardDetails} />}
                        </Fragment>

                    }
                    title={`${item.Name} (${item.ETId})`}
                />
                {envelopeDetail && (envelopeIndex === index) && <CardContent>
                    <div className="content-block">
                        <strong className="content-label">ETId</strong>
                        <span>{envelopeDetail.ETId}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">Total</strong>
                        <span>{envelopeDetail.Total}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">API Submitted</strong>
                        <span>{envelopeDetail['API Submitted']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">API Failed</strong>
                        <span>{envelopeDetail['API Failed']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">API Processed</strong>
                        <span>{envelopeDetail['API Processed']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">OCS Stored</strong>
                        <span>{envelopeDetail['OCS Stored']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">BC Submitted</strong>
                        <span>{envelopeDetail['BC Submitted']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">BC Included</strong>
                        <span>{envelopeDetail['BC Included']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">BC Confirmed</strong>
                        <span>{envelopeDetail['BC Confirmed']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">BPM Processed</strong>
                        <span>{envelopeDetail['BPM Processed']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">BPM Failed</strong>
                        <span>{envelopeDetail['BPM Failed']}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">Table Name</strong>
                        <Tooltip title={envelopeDetail.TableName} placement={'right'}>
                            <span className="span-content">{envelopeDetail.TableName}</span>
                        </Tooltip>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">Family</strong>
                        <span>{envelopeDetail.Family}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">Do Summary</strong>
                        <span>{envelopeDetail.DoSummary ? 'true' : 'false'}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">Organization</strong>
                        <span>{envelopeDetail.ORGName}</span>
                    </div>
                    <div className="content-block">
                        <strong className="content-label">Description</strong>
                        <Tooltip title={envelopeDetail.Description} placement={'right'}>
                            <span className="span-content">{envelopeDetail.Description}</span>
                        </Tooltip>

                    </div>
                    <div className="content-block">
                        <strong className="content-label"></strong>
                        <span> <nav>
                            <NavLink
                                to="/page/schema-list"
                                className='schemalink'
                            >
                                Schema
                            </NavLink>
                        </nav></span>
                    </div>
                </CardContent>}
            </Card>
        </Grid>
    )
}

export default React.memo(HealthCard);