import { ApiEndPoints } from "../../models/api-endpoint";
import RestApiService from "../../services/http-services";
import appConstants from "../../core/constants/appConstants";

const getContractInfo = async (query: any) => {
    return RestApiService.invoke(ApiEndPoints.GET_CONTRACT_LIST, null, null, query, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.CONTRACT.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.CONTRACT.SV
    })
}

const getContracts = async (query: any) => {
    return RestApiService.invoke(ApiEndPoints.GET_CONTRACT_LIST, null, null, query, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.SV
    })
}
const getUserGeneratedETIds = async (query: any) => {
    return RestApiService.invoke(ApiEndPoints.USER_GENERATED_ETIDs, null, null, query, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.SCHEMAS.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.SCHEMAS.SV
    })
}

const getDataSharingContracts = async (query: any) => {
    return RestApiService.invoke(ApiEndPoints.GET_DATA_SHARING_CONTRACT, null, null, query, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.SV
    })
}

const addConracts = async (contractData: any) => {
    const body = {
        "ETId": appConstants.SYSTEM_GENERATED_ETIDS.CONTRACT.ETId,
        "Data": contractData
    }
    return RestApiService.invoke(ApiEndPoints.CREATE_CONTRACT, null, body, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.CONTRACT.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.CONTRACT.SV
    })
}

const addDataSharing = async (contractData: any) => {
    const body = {
        "ETId": appConstants.SYSTEM_GENERATED_ETIDS.DATA_SHARING.ETId,
        "Data": contractData
    }
    return RestApiService.invoke(ApiEndPoints.CREATE_DATA_SHARING, null, body, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.DATA_SHARING.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.DATA_SHARING.SV
    })
}

const updateDataSharingStatus = async (updatedData: Array<any>) => {
    const body = {
        "ETId": appConstants.SYSTEM_GENERATED_ETIDS.DATA_SHARING.ETId,
        "Data": updatedData
    }
    return RestApiService.invoke(ApiEndPoints.UPDATE_DATA_SHARING_CONTRACT, null, body, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.DATA_SHARING.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.DATA_SHARING.SV
    })
}

const generateSeed = async (body: any) => {
    return RestApiService.invoke(ApiEndPoints.GENERATE_SEED, null, null, body, null);
}

export { getContracts, addConracts, generateSeed, getDataSharingContracts, getUserGeneratedETIds, addDataSharing, updateDataSharingStatus }; 
