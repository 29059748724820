import * as React from 'react';

export function UpdateRecords({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>changes</title>
            <defs>
                <polygon id="path-1" points="0 0 20 0 20 20 0 20"></polygon>
            </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="2-Walacor-Dashboard-Update_v2" transform="translate(-122.000000, -287.000000)">
                    <g id="changes" transform="translate(122.000000, 287.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <g id="Path"></g>
                        <path d="M14.125,8.525 L9.40833333,13.2416667 C9.08333333,13.5666667 8.55833333,13.5666667 8.23333333,13.2416667 L5.875,10.8833333 C5.55,10.5583333 5.55,10.0333333 5.875,9.70833333 C6.2,9.38333333 6.725,9.38333333 7.05,9.70833333 L8.81666667,11.475 L12.9416667,7.35000833 C13.2666667,7.02500833 13.7916667,7.02500833 14.1166667,7.35000833 C14.45,7.67500833 14.45,8.20000833 14.125,8.525 Z M3.33333333,10 C3.33333333,8.05834167 4.18333333,6.316675 5.51666667,5.10000833 L6.79166667,6.37500833 C7.05,6.63334167 7.5,6.45000833 7.5,6.07500833 L7.5,2.50000833 C7.5,2.266675 7.31666667,2.08334167 7.08333333,2.08334167 L3.50833333,2.08334167 C3.13333333,2.08334167 2.95,2.53334167 3.21666667,2.791675 L4.33333333,3.916675 C2.7,5.43334167 1.66666667,7.591675 1.66666667,10 C1.66666667,13.9583333 4.43333333,17.275 8.13333333,18.125 C8.65833333,18.2416667 9.16666667,17.85 9.16666667,17.3083333 C9.16666667,16.9166667 8.89166667,16.5833417 8.50833333,16.4916667 C5.55,15.8166667 3.33333333,13.1666667 3.33333333,10 Z M18.3333333,10 C18.3333333,6.041675 15.5666667,2.72500833 11.8666667,1.87500833 C11.3416667,1.75834167 10.8333333,2.15000833 10.8333333,2.691675 C10.8333333,3.08334167 11.1083333,3.416675 11.4916667,3.50834167 C14.45,4.18334167 16.6666667,6.83334167 16.6666667,10 C16.6666667,11.9416667 15.8166667,13.6833333 14.4833333,14.9 L13.2083333,13.625 C12.95,13.3666667 12.5,13.55 12.5,13.925 L12.5,17.5 C12.5,17.7333333 12.6833333,17.9166667 12.9166667,17.9166667 L16.4916667,17.9166667 C16.8666667,17.9166667 17.05,17.4666667 16.7833333,17.2083333 L15.6666667,16.0833333 C17.3,14.5666667 18.3333333,12.4083333 18.3333333,10 L18.3333333,10 Z" id="Shape" fill="#0078D4" fill-rule="nonzero" mask="url(#mask-2)"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
