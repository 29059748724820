import { SCHEMA_UPDATE_BY_KEY, SCHEMA_CREATE, SCHEMA_GET_LIST, SCHEMA_GET_LIST_BY_ID, SCHEMA_GET_DATATYPES, SCHEMA_GET_LIST_WITHVERSION, SCHEMA_TYPE_CHANGE } from '../types/schemaTypes';

const initialState = {
  dataList: [],
  dataListWithVersion: null,
  data: null,
  dataTypes: [],
  fetching: false,
  created: false,
  total: null
};

export function schemaReducer(state = initialState, action: any) {
  switch (action.type) {
    case SCHEMA_TYPE_CHANGE:
      return Object.assign({}, state, { [action.key]: action.value });
    case SCHEMA_CREATE:
      return {
        ...state,
        data: {}
      };
    case SCHEMA_GET_LIST_BY_ID:
      const data = action.payload;
      data.DoSummary = data.DoSummary !== null ? data.DoSummary.toString() : data.DoSummary = 'false';
      data.Fields = data.Fields.map((obj: any) => ({ ...obj, Required: obj.Required !== null ? obj.Required.toString() : obj.Required = false }));
      if (data.Indexes) {
        data.Indexes = data.Indexes.map((obj: any) => ({ ...obj, Delete: obj.Delete !== null ? obj.Delete.toString() : obj.ForceUpdate = false, ForceUpdate: obj.ForceUpdate !== null ? obj.ForceUpdate.toString() : obj.ForceUpdate = false }))
      } else {
        data.Indexes = [];
      }
      return {
        ...state,
        data
      };
    case SCHEMA_GET_LIST:
      return {
        ...state,
        dataList: action.payload.data,
        total: action.payload.total
      };
    case SCHEMA_GET_DATATYPES:
      return {
        ...state,
        dataTypes: action.payload
      }
    case SCHEMA_GET_LIST_WITHVERSION:
      return {
        ...state,
        dataListWithVersion: action.payload
      }
    default:
      return state;
  }
}