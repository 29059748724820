import './ChangeProfilePasswordForm.scss';
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import Form, { Item, Label } from 'devextreme-react/form';
import Validator from 'devextreme-react/validator';
import { PasswordTextBox } from '../commonui/password-text-box/PasswordTextBox';
import { FormPopup } from '../commonui/form-popup/FormPopup';

export const ChangeProfilePasswordForm = ({ visible, setVisible, onSubmitForm }) => {
  const confirmField = useRef<Validator>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [currentPasswordValid, setCurrentPasswordValid] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmedPasswordValid, setConfirmedPasswordValid] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);


  const confirmPasswordValidators = useMemo((): any[] => {
    return [{
      type: 'compare',
      message: 'Passwords do not match',
      comparisonTarget: () => newPassword,
    }];
  }, [newPassword]);

  useEffect(() => {
    const formValues = [currentPassword, newPassword, confirmedPassword];
    const validity = [currentPasswordValid, newPasswordValid, confirmedPasswordValid];

    setIsSaveDisabled(
      formValues.some((value) => !value) ||
      validity.some((value) => !value)
    );
  }, [
    currentPassword,
    newPassword,
    confirmedPassword,
    currentPasswordValid,
    newPasswordValid,
    confirmedPasswordValid
  ]);

  const checkConfirm = useCallback(() => {
    confirmField.current?.instance.validate();
  }, []);

  const onCurrentPasswordValidated = useCallback((e: any) => {
    setCurrentPasswordValid(!!e.isValid);
  }, []);

  const onConfirmedPasswordValidated = useCallback((e: any) => {
    setConfirmedPasswordValid(!!e.isValid);
  }, []);

  const onNewPasswordValidated = useCallback((e: any) => {
    setNewPasswordValid(!!e.isValid);
  }, []);

  const onNewPasswordChange = useCallback((value) => {
    setNewPassword(value);

    checkConfirm();
  }, [checkConfirm]);

  const onCurrentPasswordChange = useCallback((value) => {
    setCurrentPassword(value)
  }, [])

  const onConfirmPasswordChange = useCallback((value) => {
    setConfirmedPassword(value)
  }, [])

  const saveButtonHandler = useCallback(() => {
    onSubmitForm({
      oldPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmedPassword
    });

    confirmField.current?.instance.reset();
  }, [currentPassword, newPassword, confirmedPassword]);

  return <FormPopup
    title='Change Password'
    visible={visible}
    width={360}
    height={'auto'}
    wrapperAttr={{ class: 'change-profile-password-popup' }}
    isSaveDisabled={isSaveDisabled}
    onSave={saveButtonHandler}
    setVisible={setVisible}
  >
    <Form id='form'
      labelMode='outside'
      showColonAfterLabel
      labelLocation='top'>
      <Item>
        <Label text='Current Password' />
        <PasswordTextBox
          value={currentPassword}
          placeholder='Current Password'
          onValueChange={onCurrentPasswordChange}
          onValueValidated={onCurrentPasswordValidated}
        />
      </Item>

      <Item>
        <div className='h-separator' />
      </Item>

      <Item>
        <Label text='Password' />
        <PasswordTextBox
          value={newPassword}
          placeholder='Password'
          onValueChange={onNewPasswordChange}
          onValueValidated={onNewPasswordValidated}
        />
      </Item>

      <Item>
        <Label text='Confirm Password' />
        <PasswordTextBox
          ref={confirmField}
          value={confirmedPassword}
          placeholder='Confirm Password'
          validators={confirmPasswordValidators}
          onValueChange={onConfirmPasswordChange}
          onValueValidated={onConfirmedPasswordValidated}
        />
      </Item>
    </Form>
  </FormPopup>;
};
