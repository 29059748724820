import * as React from 'react';

export function Friend({ fill = '#fff', width = 15, height = 15, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M17.116 9.172h-2.03c-1.591 0-2.894 1.3-2.894 2.895v3.192c0 .957.785 1.741 1.74 1.741h4.338A1.74 1.74 0 0 0 20 15.26v-3.193a2.894 2.894 0 0 0-2.884-2.895z" fill="#FF504A" />
                <path d="M16.1 9.172h-1.014c-1.591 0-2.894 1.3-2.894 2.895v3.192c0 .957.785 1.741 1.74 1.741H16.1V9.172z" fill="#FF7959" />
                <path d="M16.1 3.242a2.6 2.6 0 0 0 0 5.202 2.6 2.6 0 0 0 0-5.202z" fill="#FF504A" />
                <path d="M16.1 3.242a2.6 2.6 0 0 0 0 5.202V3.242z" fill="#FF7959" />
                <path d="M4.923 9.172h-2.03c-1.59 0-2.893 1.3-2.893 2.895v3.192C0 16.216.785 17 1.74 17h4.337a1.74 1.74 0 0 0 1.73-1.74v-3.193a2.894 2.894 0 0 0-2.884-2.895z" fill="#2FD163" />
                <path d="M3.908 9.172H2.893c-1.59 0-2.893 1.3-2.893 2.895v3.192C0 16.216.785 17 1.74 17h2.168V9.172z" fill="#7EFF76" />
                <path d="M11.766 7.668H8.234a2.892 2.892 0 0 0-2.885 2.892v5.856c0 .32.258.579.577.579h8.148a.578.578 0 0 0 .577-.579V10.56a2.892 2.892 0 0 0-2.885-2.892z" fill="#3594C7" />
                <path d="M10.02 7.668H8.233a2.892 2.892 0 0 0-2.885 2.892v5.856c0 .32.258.579.577.579h4.093V7.668z" fill="#16DCFA" />
                <path d="M10 0a3.478 3.478 0 0 0-3.47 3.478A3.478 3.478 0 0 0 10 6.956c1.913 0 3.47-1.56 3.47-3.478A3.478 3.478 0 0 0 10 0z" fill="#3594C7" />
                <path d="M10 0a3.478 3.478 0 0 0-3.47 3.478A3.478 3.478 0 0 0 10 6.956V0z" fill="#16DCFA" />
                <path d="M3.908 3.242a2.6 2.6 0 0 0 0 5.202 2.6 2.6 0 0 0 0-5.202z" fill="#2FD163" />
                <path d="M3.908 3.242a2.6 2.6 0 0 0 0 5.202V3.242z" fill="#7EFF76" />
            </g>
        </svg>
    );
}
