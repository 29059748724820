import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './adminSideBar.scss';
import SidebarAdminMenu from '../../../models/SidebarAdminMenu';
import { AuthService } from '../../../core/interceptors/auth.interceptor';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { logoutUser, changePassword } from '../../../redux/actions/authActions';
import { CookieStorage } from 'cookie-storage';
import { toggleSideBar } from '../../../redux/actions/sidebarAction';
import { bindActionCreators } from 'redux';
import { useCookies } from "react-cookie";
import appConstants from "../../../core/constants/appConstants";
import Drawer from "devextreme-react/drawer";
import List from "devextreme-react/list";
import Tooltip from 'devextreme-react/tooltip';
import { useScreenSize } from '../../../utils/media-query';

const cookies = new CookieStorage();
const AdminSideBarComponent = (props: any) => {
    const [cookie, setCookie, removeCookie] = useCookies(['theme']);
    const [isThemeSet, setIsThemeSet] = useState(true);
    const authService = new AuthService();
    const [user, setUser] = useState<any>();
    const [openMenu, setOpenMenu] = useState(true);
    const { isXSmall, isLarge } = useScreenSize();


    useEffect(() => {
        setUser(props.auth.user)
    }, [props.auth]);

    const MenuButton = () => {
        return (
            <span className='menu-btns'>
                <i className="dx-icon-menu" aria-hidden='true' onClick={isOpenMenu} style={{ fontSize: 18 }} />
            </span>
        );
    };

    const isOpenMenu = () => {
        setOpenMenu((previousValue) => !previousValue);
    }

    useEffect(() => {
        if (isThemeSet) {
            dynamicThemeApply();
            setIsThemeSet(false);
        }
    }, [isThemeSet]);

    const dynamicThemeApply = () => {
        var theme = cookie.theme;
        if (theme === appConstants.THEME.DARK_BLUE) {
            document.documentElement.style.setProperty('--color', '#fff');
            document.documentElement.style.setProperty('--font-color', '#fff');
            document.documentElement.style.setProperty('--tab_background_color', '#23395d');
            document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
            document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
            document.documentElement.style.setProperty('--border_color', '#006eaf');
            document.documentElement.style.setProperty('--border_color1', 'transparent');
            document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
            document.documentElement.style.setProperty('--selected_background_color', '#7488aa');
            document.documentElement.style.setProperty('--background-color', '#23395d');
            document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
            document.documentElement.style.setProperty('--Bad_color', '#c9817d');
            document.documentElement.style.setProperty('--Good_color', '#9bc798');
            document.documentElement.style.setProperty('--hover_background_color', '#305695');
        }
        else if (theme === appConstants.THEME.ALICE_BLUE) {
            document.documentElement.style.setProperty('--color', '#fff');
            document.documentElement.style.setProperty('--font-color', '#fff');
            document.documentElement.style.setProperty('--tab_background_color', '#3E849E');
            document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
            document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
            document.documentElement.style.setProperty('--border_color', '#06b5f4');
            document.documentElement.style.setProperty('--border_color1', 'transparent');
            document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
            document.documentElement.style.setProperty('--selected_background_color', '#2e7691');
            document.documentElement.style.setProperty('--background-color', '#3E849E');
            document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
            document.documentElement.style.setProperty('--Bad_color', '#c9817d');
            document.documentElement.style.setProperty('--Good_color', '#9bc798');
            document.documentElement.style.setProperty('--hover_background_color', '#2e7691');
        }

        else {
            document.documentElement.style.setProperty('--color', '#0078d4');
            document.documentElement.style.setProperty('--font-color', '#000000');
            document.documentElement.style.setProperty('--tab_background_color', '#ffffff');
            document.documentElement.style.setProperty('--table_header_color', '#c2c0bd');
            document.documentElement.style.setProperty('--table_header_background', '#c2c0bd');
            document.documentElement.style.setProperty('--border_color', '#ccc');
            document.documentElement.style.setProperty('--border_color1', '#c2c0bd');
            document.documentElement.style.setProperty('--btn_background_color', '#0078d4');
            document.documentElement.style.setProperty('--selected_background_color', '#f1f6ff');
            document.documentElement.style.setProperty('--Pending_color', '#fdf0c8');
            document.documentElement.style.setProperty('--Bad_color', '#f8cecc');
            document.documentElement.style.setProperty('--Good_color', '#d5e8d4');
            document.documentElement.style.setProperty('--background-color', '#fff');
        }
    }

    const adminRender = (item: any, index: any) => (item.type && item.type === 'DROPDOWN') ? item.menu(props) :
        <li>
            <div className="right" key={"s_r_nl" + index}>
                <li className={`menu-link `} key={"s_li_nl" + index}>
                    <NavLink activeClassName="active" className="link" to={item.url} key={"s_nl" + index}>
                        <div className='devListIcon' id={item.elementAttr.id}><span className='svg-root'>{item.icon}</span></div>
                        <div className='dev-listRoot'><span className='text-title'>{item.name}</span></div>
                    </NavLink>
                </li>
            </div>
        </li>

    const adminMenu = () => {
        return (
            <div className="rightmenu">
                <List
                    items={SidebarAdminMenu}
                    selectionMode="single"
                    itemRender={adminRender}
                    hoverStateEnabled={false}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                />
                <Tooltip
                    target="#sideUserIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentUserIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideRoleIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderContentRoleIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideConfigureIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderConfigureIcon}
                    position="right"
                />

                <Tooltip
                    target="#sideClusterIcon"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentRender={renderClusterIcon}
                    position="right"
                />
            </div>
        )
    }

    const renderContentUserIcon = useCallback(() => {
        return (
            <>User</>
        )
    }, []);

    const renderContentRoleIcon = useCallback(() => {
        return (
            <>Role</>
        )
    }, []);

    const renderConfigureIcon = useCallback(() => {
        return (
            <>Configuration Settings</>
        )
    }, []);

    const renderClusterIcon = useCallback(() => {
        return (
            <>Cluster Management</>
        )
    }, []);
    return (
        <div className="mainside">
            <MenuButton />
            <Drawer
                className={`drawer ${openMenu ? "closestrip" : ""}`}
                component={adminMenu}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={isXSmall ? 0 : 60}
                maxSize={250}
                shading={isLarge ? false : true}
            >
            </Drawer>
        </div >
    );
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    isShowSideBar: state.appSidebarReducer
});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleSideBar: toggleSideBar, logoutUser, changePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminSideBarComponent));