import React from "react";
import "./ProductText.scss";
import SearchBox from "../../../SearchBox/SearchBox";
import { TextBox } from "devextreme-react";

function ProductText() {
  return (
    <div className="sub-landingpage">
      <div className="sub-header-landing">
        <span className="title">Walacor Workspace</span>
      </div>
      <div className="text-search">
        <TextBox
          stylingMode="outlined"
          className={"search-header dx-theme-background-color"}
          placeholder={"Search"}
          value={""}
          onInput={() => {}}
          buttons={[
            {
              name: "cancel",
              location: "before",
              options: {
                onClick: () => {},
                icon: "search",
                stylingMode: "text",
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ProductText;
