import * as React from 'react';

export function BackArrow({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="15" height="13" viewBox="0 0 15 13" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
            <path d="M14.098 5.436a1.061 1.061 0 0 0-.185-.014H3.33l.23-.108c.226-.108.431-.255.607-.434L7.135 1.88c.391-.377.457-.984.156-1.437A1.066 1.066 0 0 0 5.68.303L.315 5.726a1.093 1.093 0 0 0 0 1.535l5.366 5.422a1.065 1.065 0 0 0 1.61-.108c.3-.454.235-1.06-.156-1.437L4.173 8.134a2.148 2.148 0 0 0-.537-.396l-.322-.147h10.54c.548.02 1.029-.366 1.132-.91a1.083 1.083 0 0 0-.888-1.245z" fill="#878B9F" fill-rule="nonzero" />
        </svg>

    );
}
