import { POC_UPDATE_BY_KEY, POC_CREATE, POC_GET_LIST, POC_UPDATE, POC_GET_HISTORIES, POC_VALIDATE_ITEM, POC_GET_BLOCKCHAIN_LIST } from '../types/pocTypes';

const initialState = {
  dataList: [],
  data: null,
  targetBlocakChains: [],
  fetching: false,
  completed: false,
  updated: false,
  histories: null,
};

export function pocReducer(state = initialState, action: any) {
  switch (action.type) {

    case POC_UPDATE_BY_KEY:
      return Object.assign({}, state, { [action.key]: action.value });
    case POC_CREATE:
      return {
        ...state,
        data: action.payload
      };
    case POC_UPDATE:
      return {
        ...state,
        data: action.payload
      };
    case POC_GET_LIST:
      return {
        ...state,
        dataList: action.payload.data,
        total: action.payload.total
      };
    case POC_GET_HISTORIES:
      return {
        ...state,
        histories: action.payload,
      };
    case POC_GET_BLOCKCHAIN_LIST:
      return {
        ...state,
        targetBlocakChains: action.payload
      }
    default:
      return state;
  }
}

export function validatePocItemReference(state = false, action: any) {
  switch (action.type) {
    case POC_VALIDATE_ITEM:
      return action.payload;
    default:
      return state;
  }
}
