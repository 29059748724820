import * as React from 'react';

export function Facebook({ fill = '#fff', width = 15, height = 15, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="65" height="65" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 0C15.67 0 0 15.67 0 35s15.67 35 35 35 35-15.67 35-35S54.33 0 35 0zm8.87 31.684-.58 4.877a.921.921 0 0 1-.922.82h-5.065v14.46a.654.654 0 0 1-.645.659H31.5a.654.654 0 0 1-.645-.663l.028-14.456h-3.836a.921.921 0 0 1-.921-.921v-4.873c0-.508.412-.92.92-.92h3.81V25.94c0-5.466 3.255-8.441 8.012-8.441h3.901c.509 0 .921.412.921.921v4.103a.921.921 0 0 1-.92.921h-2.391c-2.588.042-3.076 1.28-3.076 3.132v4.076h5.673a.921.921 0 0 1 .894 1.031z" fill="#0078D4" fill-rule="nonzero" />
        </svg>


    );
}
