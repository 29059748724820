import React from 'react';
import DateBox, { IDateBoxOptions } from 'devextreme-react/date-box';

interface IPropsFormDateBox extends IDateBoxOptions {
    inputLabel?: string;
    error?: any;
    touched?: any;
    children?: React.ReactNode;
    value?: any,
    readOnly?: any,
    name?: any,
    label?: any,
    onValueChange?: any;
    placeholder?: any
}
export const FormDateBox = ({ value, readOnly, name, placeholder, onValueChange, inputLabel, error, touched, children, ...props }: IPropsFormDateBox) => {
    const dropDownOptions:any = {
        icon: 'calendar',
        stylingMode: 'outlined'
    }
    return (
        <div className='dx-field'>
            <div className="dx-field-label">
                <strong>{inputLabel}</strong>
            </div>
            <div className='dx-field-value'>
                <DateBox
                    value={value}
                    readOnly={readOnly}
                    name={name}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    placeholder={placeholder}
                    displayFormat="yyyy-MM-dd HH:mm:ss"
                    onValueChange={onValueChange}
                    focusStateEnabled={false}
                    hoverStateEnabled={false}
                    dropDownOptions={dropDownOptions}
                    {...props}
                >
                    {children}
                </DateBox>
                {error && touched ? <div className="alert alert-danger my-3">{error}</div> : null}
            </div>
        </div>
    );
};
