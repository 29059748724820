import React from "react";
import { Button as DevextremeButton, IButtonOptions } from 'devextreme-react/button';
import './button.scss';
import { ClickEvent } from "devextreme/ui/button";

export type ButtonStyle = 'text' | 'outlined' | 'contained';

export type ButtonType = 'back' | 'danger' | 'default' | 'normal' | 'success';
export interface IButtonProps extends IButtonOptions {
    color?: string;
    backgroundColor?: string;
    style?: any;
    className?: string;
    render?: (...params: any) => React.ReactNode;
    component?: React.ComponentType<any>;
    keyFn?: (data: any) => string;
    /**
     * A Boolean value specifying whether or not the UI component changes its state when interacting with a user.
     */
    activeStateEnabled?: boolean;
    /**
     * Specifies whether the UI component can be focused using keyboard navigation.
     */
    focusStateEnabled?: boolean;
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
     */
    hoverStateEnabled?: boolean;
    /**
     * Specifies the icon to be displayed on the button.
     */
    icon?: string;
    /**
     * A function that is executed when the Button is clicked or tapped.
     */
    onClick?: ((e: ClickEvent) => void);
    /**
     * Specifies how the button is styled.
     */
    stylingMode?: ButtonStyle;
    /**
     * The text displayed on the button.
     */
    text?: string;
    /**
     * Specifies the button type.
     */
    type?: ButtonType;
    /**
     * Specifies whether the button submits an HTML form.
     */
    useSubmitBehavior?: boolean;
    /**
     * Specifies the name of the validation group to be accessed in the click event handler.
     */
    validationGroup?: string;

    children?:React.ReactNode; 
}

function Button({ color, backgroundColor, style, text, className, render, component, keyFn, icon,children, ...props }: IButtonProps) {
    const styleSheet = { color: color, backgroundColor: backgroundColor };
    return (
        <DevextremeButton
            render={render}
            text={text}
            style={{ ...styleSheet, ...style }}
            className={className}
            component={component}
            keyFn={keyFn}
            icon={icon}
            {...props}
        >
            {children}
        </DevextremeButton>
    )
}

export default Button; 