import { combineReducers } from 'redux';
import { pocReducer, validatePocItemReference } from './pocReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import messageReducer from './messageReducer';
import profileReducer from './profileReducer';
import { loadingReducer } from './appLoadingReducer';
import { envelopeReducer } from './envelopeReducer';
import { schemaReducer } from './schemaReducer';
import { fileReducer } from './fileReducers';
import appLoaderReducer from './loader.reducer';
import appSidebarReducer from './sidebarReducer';

export default combineReducers({
  validatePocItemReference,
  poc: pocReducer,
  auth: authReducer,
  errors: errorReducer,
  message: messageReducer,
  profile: profileReducer,
  loading: loadingReducer,
  envelope: envelopeReducer,
  schema: schemaReducer,
  file: fileReducer,
  appLoaderReducer:appLoaderReducer,
  appSidebarReducer:appSidebarReducer
});
