import React from 'react'
import { Form } from 'devextreme-react';
import { getSizeQualifier } from '../../../../utils/media-query';
import { ColCountByScreen, GroupItem, SimpleItem } from 'devextreme-react/form';
import { FormTextbox } from '../../../../shared/components/commonui/form-textbox/FormTextbox';
import { PasswordTextBox } from '../../../../shared/components/commonui/password-text-box/PasswordTextBox';


function AddUserForm({
    UserName,
    handleAddUserName,
    handleAddUserValidate,
    FirstName,
    handleAddFirstName,
    handleAddFirstNameValid,
    LastName,
    handleAddLastName,
    Password,
    handlePassword,
    handleAddPasswordValid
}) {
    return (
        <Form
            id='form'
            labelMode='outside'
            showColonAfterLabel
            labelLocation='top'
            screenByWidth={getSizeQualifier}
        >
            <SimpleItem colSpan={2}>
                <FormTextbox
                    label='User Name'
                    value={UserName}
                    isEditing={false}
                    onValueChange={handleAddUserName}
                    validationRules={[{ type: 'required', pattern: /^[a-zA-Z_0-9 ]+$/, message: 'Username should begin with an alphabet and spaces are not allowed' }]}
                    onValueValidated={handleAddUserValidate}
                />
            </SimpleItem>

            <GroupItem itemType='group'>
                <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                <SimpleItem cssClass='accent'>
                    <FormTextbox
                        label='First Name'
                        value={FirstName}
                        isEditing={false}
                        onValueChange={handleAddFirstName}
                        validationRules={[{ type: 'required', pattern: /^[a-zA-Z_0-9 ]+$/, message: 'FirstName should begin with an alphabet and spaces are not allowed' }]}
                        onValueValidated={handleAddFirstNameValid}
                    />
                </SimpleItem>
                <SimpleItem cssClass='accent'>
                    <FormTextbox
                        label='Last Name'
                        value={LastName}
                        isEditing={false}
                        onValueChange={handleAddLastName}
                    />
                </SimpleItem>
            </GroupItem>
            <SimpleItem colSpan={2}>
                <div className='addPassword'>
                    <span className='password-title'>Password</span>
                </div>
                <PasswordTextBox
                    stylingMode='outlined'
                    value={Password}
                    onValueChange={handlePassword}
                    onValueValidated={handleAddPasswordValid}
                />
            </SimpleItem>
        </Form>
    )
}

export default AddUserForm;