import React, { Children } from "react";
import './ModalBox.scss'
import { Popup as DevextremePopup, IPopupOptions } from 'devextreme-react/popup';
import { Cancelable, DxEvent, EventInfo } from "devextreme/events";
import { PositionAlignment } from "devextreme/ui/popup";
import { PositionConfig } from "devextreme/animation/position";
import { useScreenSize } from '../utils/media-query';

export interface IPopupProps extends IPopupOptions {
  /**
    * Specifies the UI component&apos;s width in pixels.
    */
  width?: number | string | (() => number | string);

  /**
    * Specifies the UI component&apos;s height in pixels.
    */
  height?: number | string | (() => number | string);

  title?: string;
  /**
   * The title in the overlay window.
   */


  position?: PositionAlignment | PositionConfig | Function;
  /**
   * Positions the UI component.
   */


  accessKey?: string;
  /**
  * Specifies the shortcut key that sets focus on the UI component.
  */




  contentRender?: (...params: any) => React.ReactNode;


  disabled?: boolean;
  /**
   * Specifies whether the UI component responds to user interaction.
   */


  dragEnabled?: boolean;
  /**
     * Specifies whether or not to allow a user to drag the popup window.
     */


  dragOutsideBoundary?: boolean;
  /**
      * 
      */



  fullScreen?: boolean;
  /**
   * Specifies whether to display the Popup in full-screen mode.
   */



  /**
     * 
     */
  hideOnOutsideClick?: boolean | ((event: DxEvent<MouseEvent | PointerEvent | TouchEvent>) => boolean);

  hoverStateEnabled?: boolean;
  /**
   * Specifies whether the UI component changes its state when a user pauses on it.
   */


  shading?: boolean;
  /**
   * Specifies whether to shade the background when the UI component is active.
   */

  shadingColor?: string;
  /**
  * Specifies the shading color. Applies only if shading is enabled.
  */


  showCloseButton?: boolean;
  /**
   * Specifies whether or not the UI component displays the Close button.
   */


  children?: React.ReactNode | undefined

  visible?: boolean;
  /**
    * A Boolean value specifying whether or not the UI component is visible.
    */


  showTitle?: boolean;
  /**
   * The title in the overlay window.
   */
}

function ModalBox({ title, width = 480, height = 'auto', wrapperAttr = { class: '' }, contentRender, children, visible, dragEnabled, onHiding, className, showTitle, showCloseButton, position, ...props }: IPopupProps) {
  const { isXSmall } = useScreenSize();
  return (
    <DevextremePopup
      visible={visible}
      showCloseButton={showCloseButton}
      dragEnabled={dragEnabled}
      title={title}
      showTitle={showTitle}
      fullScreen={isXSmall}
      contentRender={contentRender}
      onHiding={onHiding}
      className={className}
      width={width}
      height={height}
      wrapperAttr={{ ...wrapperAttr, class: `${wrapperAttr?.class} form-popup` }}
      {...props}
    >
      {children}
    </DevextremePopup>
  )
}

export default ModalBox;