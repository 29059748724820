import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Button, TextField, MenuItem, Select
} from '@material-ui/core';
import { ApiEndPoints } from '../../../models/api-endpoint';
import Toaster from '../../../services/toaster.services';
import { toggleLoader } from '../../../redux/actions/loader.action';
import RestApiService from '../../../services/http-services';


import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import NestedTable from './NestedTable/NestedTable';

import "../Setting/Setting.scss";
import "./index.scss"
import AppConstants from '../../../core/constants/appConstants';
import { stripTrailingSlash, trimSpaces } from '../../../utils/validation/common.validation';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import ModalBox from '../../../shared/components/commonui/Modal/ModalBox';
import Buttons from '../../../shared/components/commonui/Button';
import InputBox from '../../../shared/components/commonui/Input/InputBox';
import SelectBox from '../../../shared/components/commonui/SelectBox/SelectBox';
import NewSubHeaderComponent from '../../../shared/components/new-sub-header/new-sub-header';
import { FormPopup } from '../../../shared/components/commonui/form-popup/FormPopup';
import { Form } from 'devextreme-react';

import Toolbar, { Item } from 'devextreme-react/toolbar';
import { getSizeQualifier } from '../../../utils/media-query';
import { ColCountByScreen, GroupItem, SimpleItem } from 'devextreme-react/form';
const ClusterNodeComponent = (props: ISettingProps) => {
    const [pageSize] = useState(10);
    const [currentPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [order] = useState<any>('desc');
    const [orderBy] = useState<any>('CreatedAt');
    const [searchKey, setSearchKey] = useState<any>();
    const [listData, setListData] = useState([]);
    const [nodeListData, setNodeListData] = useState([]);
    const [status, setStatus] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopup1, setOpenPopup1] = useState(false);
    const [initialValue] = useState<any>({
        ClusterFamily: '',
        ClusterName: '',
    });
    const [nodeInitialValue, setNodeInitialValue] = useState<any>({
        SelectCluster: '',
        ClusterUID: '',
        NodeName: '',
        NodeUrl: '',
        NodeType: ""
    })
    const [viewCredential, setViewCredntial] = useState(false)
    const [viewCredentialData, setViewCredntialData] = useState<any>([])
    const [viewCredntialPopupData, setViewCredntialPopupData] = useState([])
    const [selectedData, setSelectedData] = useState<any>([])
    const NodeTypeList = useMemo(() => [
        'Master', "Replica"
    ], []);
    const [clusterETId, clusterSV, nodeETId, nodeSV] = [AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.SV];

    const debouncedValue = useDebounce<string>(searchKey, 500);


    function handleCreateCluster(value: any, formikHelpers: FormikHelpers<any>) {
        formikHelpers.resetForm();
        props.toggleLoader(true);
        const bodyCluster = {
            "ETId": clusterETId,
            "SV": clusterSV,
            "Data": [{ 'ClusterFamily': trimSpaces(value.ClusterFamily), 'ClusterName': trimSpaces(value.ClusterName) }]
        }

        RestApiService.invoke(ApiEndPoints.CREATE_CLUSTER, null, bodyCluster, null, {
            ETId: AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
            SV: AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV
        }).then(res => {
            props.toggleLoader(false);
            setOpenPopup1(false)
            getClusterList({})
            Toaster.successToaster("Create Node", "Cluster Created Succesfully")

        }).catch((error) => {
            props.toggleLoader(false);
            console.log(error, "error")
            setOpenPopup1(false)
            Toaster.errorToaster(error);
        })
    }

    function handleCreateClusterNode(value: any, formikHelpers: FormikHelpers<any>) {

        const bodyNode = {
            "ETId": nodeETId, "SV": nodeSV,
            "Data": [{ 'ClusterUID': value.ClusterUID, 'NodeName': trimSpaces(value.NodeName), 'NodeUrl': stripTrailingSlash(value.NodeUrl), "NodeType": value.NodeType }]
        }
        formikHelpers.resetForm();
        props.toggleLoader(true);

        RestApiService.invoke(ApiEndPoints.CREATE_NODE, null, bodyNode, null, {
            ETId: nodeETId,
            SV: nodeSV
        }).then(res => {
            setOpenPopup(false)
            props.toggleLoader(false);
            getNodeList()
            Toaster.successToaster("createdClusterNode", "Node created successfully")
        }).catch((errors) => {
            console.log(errors.message, "errorClust")
            props.toggleLoader(false);
            setOpenPopup(false)
            Toaster.errorToaster(errors.message);
        })

    }

    useEffect(() => {
        setViewCredntialPopupData(nodeListData.filter((data) => data.UID === viewCredentialData[0]))
    }, [nodeListData, viewCredentialData])

    const getClusterList = (query, type?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_CLUSTER_LIST, null, null, query, {
            ETId: clusterETId,
            SV: clusterSV
        }).then(res => {
            props.toggleLoader(false);
            setListData(res.data.data);
            if (parseInt(res.data.totalRecord) % pageSize === 0) {
                setTotalPage((parseInt(res.data.totalRecord) / pageSize));
            }
            else {
                setTotalPage((Math.floor(parseInt(res.data.totalRecord) / pageSize)) + 1);
            }

        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }


    const getNodeList = (_type?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_NODE_LIST, null, null, null, {
            ETId: nodeETId,
            SV: nodeSV
        }).then(res => {
            props.toggleLoader(false);
            setNodeListData(res.data.data);
        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }

    useEffect(() => {
        getNodeList()
        if (pageSize && currentPageNo && orderBy && order) {
            getClusterList({ searchKey: debouncedValue });
        }
    }, [pageSize, currentPageNo, order, orderBy, debouncedValue]);


    const onSearchData = (searchData: any) => {
        setSearchKey(searchData);
    }

    const onCancelSearch = (value: any) => {
        setSearchKey('');
        if (value) {
            getClusterList({});
        }
    }

    function compareHandler(compareList, selectedData) {
        props.history.push({ pathname: selectedData.length === 0 ? "/page/admin/clusters/compare" : `/page/admin/clusters/compare/comparison`, state: { clusterCompareList: selectedData } });
    }

    const handleSelectChange = (data: any) => {
        setNodeInitialValue({
            SelectCluster: data.name,
            ClusterUID: data.value,
            NodeName: nodeInitialValue.NodeName,
            NodeUrl: nodeInitialValue.NodeUrl,
            NodeType: nodeInitialValue.NodeType
        })
    }
    const handleSelectChangeNode = (data: any) => {
        setNodeInitialValue({
            SelectCluster: nodeInitialValue.SelectCluster,
            ClusterUID: nodeInitialValue.ClusterUID,
            NodeName: nodeInitialValue.NodeName,
            NodeUrl: nodeInitialValue.NodeUrl,
            NodeType: data.value
        })
    }

    const memoizedFunction = useCallback((values: any) => setViewCredntialData(values), []);
    const viewCredentialMemoized = useCallback((value) => setViewCredntial(value), []);
    const gridSelectData = useCallback((values) => {
        listData.forEach((clusterObject) => {
            values.forEach((nodeObject) => {
                if (clusterObject.UID === nodeObject.ClusterUID) {
                    nodeObject["NodeName"] = nodeObject.NodeName + " (" + clusterObject.ClusterName + ")"
                }
            }
            )
        });
        setSelectedData(values)
    }, [listData]);

    function statusColumnFormatter(value: any) {
        if (value.data.ES > 70) {
            return (
                <div className='status-column'>
                    <div className="status-column-circle green">
                    </div>
                    <div className='status-column-btn'>
                        <span className='status-column-text'>Done</span>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='status-column'>
                    <div className="status-column-circle yellow">
                    </div>
                    <span className='status-column-text'>In Progress</span>
                </div>
            )
        }
    }


    const handleSetViewCredential = useCallback(() => {
        setViewCredntial(false)
    }, []);

    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='setting-page'>
                        <div className="Innerheading clusterCompare">
                            <div className='view-wrapper view-wrapper-cluster-details dx-theme-background-color'>
                                <NewSubHeaderComponent
                                    headingText={'Cluster Management'}
                                    searchLabel={'Cluster or Node'}
                                    showSearch
                                    searchKey={searchKey}
                                    onSearchData={(searchData: any) => onSearchData(searchData)}
                                    onCancelSearch={(value: any) => onCancelSearch(value)}
                                    btnText3={"Compare"}
                                    btnText={'Create Cluster'}
                                    btnText2={"Create Cluster Node"}
                                    showBtn
                                    onButtonClick={() => setOpenPopup1(true)}
                                    showBtn2={true}
                                    onButtonClick2={(popUp) => { setOpenPopup(popUp) }}
                                    showBtn3={true}
                                    blurBtn={(selectedData.length !== 0)}
                                    onButtonClick3={(e) => compareHandler(e, selectedData)}

                                />
                                <div className="dx-theme-background-color Innerdetails dx-card">
                                    <div className="grid">
                                        <NestedTable
                                            showActions={true}
                                            actionBtnIcon={"share"}
                                            viewCredential={viewCredentialMemoized}
                                            viewCredentialData={memoizedFunction}
                                            gridSelectData={gridSelectData}
                                            dataSource={listData}
                                            nestedDataSource={nodeListData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalBox
                showCloseButton
                showTitle
                title='Create Cluster'
                hideOnOutsideClick
                dragEnabled={true}
                visible={openPopup1}
                onHiding={() => setOpenPopup1(false)}
                width={480}
                height={'auto'}
                wrapperAttr={{ class: 'create-cluster-form-popup' }}
            >
                <Formik
                    initialValues={initialValue}
                    enableReinitialize
                    validationSchema={
                        yup.object().shape({
                            ClusterFamily: yup.string().trim().required('Cluster Family Field is Required'),
                            ClusterName: yup.string().trim().required('Cluster Name Field is Required'),
                        })}
                    onSubmit={(value, formikHelpers) => { handleCreateCluster(value, formikHelpers); formikHelpers.resetForm() }}
                >
                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }) => {
                        if (initialValue) return (
                            <form onSubmit={handleSubmit} className='dx-form'>
                                <Form
                                    id='form'
                                    labelMode='outside'
                                    showColonAfterLabel
                                    labelLocation='top'
                                    screenByWidth={getSizeQualifier}
                                >
                                    <SimpleItem colSpan={2}>
                                        <div className='main-title'>
                                            <span className='title'><strong>Cluster Family</strong></span>
                                        </div>
                                        <InputBox
                                            stylingMode='outlined'
                                            placeholder='slave cluster 101'
                                            value={values.ClusterFamily}
                                            name="ClusterFamily"
                                            onFocusOut={(e: any) => { handleBlur(e.event) }}
                                            onInput={(e: any) => handleChange(e.event)}
                                        />
                                        {errors.ClusterFamily && touched.ClusterFamily && <div className="alert alert-danger my-3">{errors.ClusterFamily}</div>}
                                    </SimpleItem>


                                    <SimpleItem colSpan={2}>
                                        <div className='main-title'>
                                            <span className='title'><strong>Cluster Name</strong></span>
                                        </div>
                                        <InputBox
                                            stylingMode='outlined'
                                            placeholder='Culter 101'
                                            value={values.ClusterName}
                                            name="ClusterName"
                                            onFocusOut={(e: any) => { handleBlur(e.event) }}
                                            onInput={(e: any) => handleChange(e.event)}
                                        />
                                        {errors.ClusterName && touched.ClusterName && <div className="alert alert-danger my-3">{errors.ClusterName}</div>}
                                    </SimpleItem>

                                    <SimpleItem>
                                        <div className='main-title'>
                                            <strong><span className='title'>*Cluster name cannot be edited after save / submission</span></strong>
                                        </div>
                                    </SimpleItem>
                                </Form>
                                <Toolbar>
                                    <Item location={'bottom'}>
                                        <Buttons text='Submit' type='default' className='btn-save' onClick={() => handleSubmit} useSubmitBehavior={true} />
                                        <Buttons text='Close' type='normal' className='btn-close' onClick={() => { setOpenPopup1(false) }} />
                                    </Item>
                                </Toolbar>
                            </form>
                        )
                    }
                    }

                </Formik>
            </ModalBox>




            <ModalBox
                visible={openPopup}
                onHiding={() => setOpenPopup(false)}
                showCloseButton={true}
                dragEnabled={true}
                showTitle={true}
                title='Create Cluster Node'
                height={'auto'}
                width={'570'}
                hideOnOutsideClick
                wrapperAttr={{ class: 'add-cluster-form-popup' }}
            >

                <Formik
                    initialValues={nodeInitialValue}
                    enableReinitialize
                    validationSchema={
                        yup.object().shape({
                            ClusterUID: yup.string().required('Cluster UID Field is Required'),
                            NodeName: yup.string().required('NodeName Field is Required'),
                            NodeUrl: yup.string().required('Node URL Field is Required'),
                        })}
                    onSubmit={(value, formikHelpers) => { handleCreateClusterNode(value, formikHelpers); formikHelpers.resetForm(); }}
                >
                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }) => {
                        if (initialValue) return (
                            <form onSubmit={handleSubmit} className='dx-form'>
                                <Form
                                    id='form'
                                    labelMode='outside'
                                    showColonAfterLabel
                                    labelLocation='top'
                                    screenByWidth={getSizeQualifier}
                                >
                                    <GroupItem itemType='group'>
                                        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                                        <SimpleItem cssClass='accent'>
                                            <div className='main-title'>
                                                <span className='title'><strong>Select Cluster</strong></span>
                                            </div>
                                            <SelectBox
                                                stylingMode='outlined'
                                                dataSource={listData}
                                                displayExpr="ClusterName"
                                                valueExpr="UID"
                                                name="SelectCluster"
                                                value={values.ClusterUID}
                                                onValueChanged={(e) => handleSelectChange(e)}
                                            />
                                        </SimpleItem>
                                        <SimpleItem cssClass='accent'>
                                            <div className='main-title'>
                                                <span className='title'><strong>Cluster UID</strong></span>
                                            </div>
                                            <InputBox
                                                stylingMode='outlined'
                                                placeholder='6enajfna-9Adbka1dssd-addsds76'
                                                value={values.ClusterUID}
                                                name="ClusterUID"
                                                onFocusOut={(e: any) => { handleBlur(e.event) }}
                                                onInput={(e: any) => handleChange(e.event)}
                                                disabled={true}
                                            />
                                            {errors.ClusterUID && touched.ClusterUID && <div className="alert alert-danger my-3">{errors.ClusterUID}</div>}
                                        </SimpleItem>
                                    </GroupItem>

                                    <GroupItem itemType='group'>
                                        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                                        <SimpleItem cssClass='accent'>
                                            <div className='main-title'>
                                                <span className='title'><strong>Node Type</strong></span>
                                            </div>

                                            <SelectBox
                                                stylingMode='outlined'
                                                dataSource={NodeTypeList}
                                                value={values.NodeType}
                                                name="SelectCluster"
                                                onValueChanged={(e) => handleSelectChangeNode(e)}
                                            />
                                        </SimpleItem>
                                        <SimpleItem cssClass='accent'>
                                            <div className='main-title'>
                                                <span className='title'><strong>Node Name</strong></span>
                                            </div>
                                            <InputBox
                                                stylingMode='outlined'
                                                placeholder='Node Name'
                                                value={values.NodeName}
                                                name="NodeName"
                                                onFocusOut={(e: any) => { handleBlur(e.event) }}
                                                onInput={(e: any) => handleChange(e.event)}
                                            />
                                            {errors.NodeName && touched.NodeName && <div className="alert alert-danger my-3">{errors.NodeName}</div>}
                                        </SimpleItem>
                                    </GroupItem>

                                    <SimpleItem colSpan={2}>
                                        <div className='main-title'>
                                            <span className='title'><strong>Node Url</strong></span>
                                        </div>
                                        <InputBox
                                            stylingMode='outlined'
                                            placeholder='https://example.epcone.com'
                                            value={values.NodeUrl}
                                            name="NodeUrl"
                                            onFocusOut={(e: any) => { handleBlur(e.event) }}
                                            onInput={(e: any) => handleChange(e.event)}
                                        />
                                        {errors.NodeUrl && touched.NodeUrl && <div className="alert alert-danger my-3">{errors.NodeUrl}</div>}
                                    </SimpleItem>
                                </Form>

                                <Toolbar>
                                    <Item location='bottom'>
                                        <Buttons text='Submit' type='default' className='btn-save' useSubmitBehavior onClick={() => handleSubmit} />
                                        <Buttons text='Close' type='normal' className='btn-close' onClick={() => { setOpenPopup(false) }} />
                                    </Item>
                                </Toolbar>
                            </form>
                        )
                    }
                    }
                </Formik>

            </ModalBox>


            {/* <ModalBox */}
            <FormPopup
                visible={viewCredential}
                wrapperAttr={{ class: 'view-node-credentials' }}
                title='Node Details'
                height={'auto'}
                width={400}
                setVisible={handleSetViewCredential}
            >
                <div className='modal-view-node'>
                    <div className="dx-field">
                        <div className="dx-field-label">Node Type</div>
                        <div className="dx-field-value">{viewCredentialData?.NodeType}</div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">Cluster</div>
                        <div className="dx-field-value">{viewCredentialData?.NodeName}</div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">Status</div>
                        <div className="dx-field-value">Done</div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">Node Id</div>
                        <div className="dx-field-value">{viewCredentialData?._id}</div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label"> Node UID</div>
                        <div className="dx-field-value">{viewCredentialData?.UID}</div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">Node URL</div>
                        <div className="dx-field-value">{viewCredentialData?.NodeUrl}</div>
                    </div>

                    <div className="buttonDivs" >
                        <div className="closeBtns" >
                            <Buttons text='Close' className='credential-btn' type='default' onClick={handleSetViewCredential} />
                        </div>
                    </div >
                </div>
            </FormPopup>
            {/* </ModalBox> */}
        </div>
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClusterNodeComponent);


interface ISettingProps {
    toggleLoader: any;
    history: any;
}