import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { CookieStorage } from 'cookie-storage';
import moment from 'moment';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleLoader } from '../../../redux/actions/loader.action';


import RestApiService from '../../../services/http-services';
import Toaster from '../../../services/toaster.services';


import { ApiEndPoints } from '../../../models/api-endpoint';

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography,Button as Buttons } from '@material-ui/core';


import { useDebounce } from "../../../shared/hooks/useDebounce";


import { Button, Validator } from 'devextreme-react';


import { changeEpochToDate } from '../../../utils/utilityFunctions';


import "./User.scss";


import { FormPopup } from '../../../shared/components/commonui/form-popup/FormPopup';
import NewSubHeaderComponent from '../../../shared/components/new-sub-header/new-sub-header';
import AddUserForm from './AddUserForm/AddUserForm';
import EditChangePassword from './EditChangePassword/EditChangePassword';
import EditUserForm from './EditUserForm/EditUserForm';
import EditRole from './EditRole/EditRole';
import AddRole from './AddRole/AddRole';
import DynamicTableList from '../../../shared/components/commonui/DynamicTableList/DynamicTableList';
import { AccountTreeIcons, Creates, LockOpenIcons } from '../../../../assets/svg';
import ModalBox from '../../../shared/components/commonui/Modal/ModalBox';
import { ToolbarItem } from 'devextreme-react/popup';
import { Close } from '@material-ui/icons';
import MultipleSelectComponent from '../../../shared/components/elements/MultipleDropDown/MultipleSelectDropDown';
import { JwtServices } from '../../../services/jwt.services';

const UserComponent = (props: UserProps) => {
  const confirmField = useRef<Validator>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState<any>({});
  const [userObj, setUserObj] = React.useState<any>({});
  const [mode, setMode] = React.useState<any>('add');
  const [roleMode, setRoleMode] = React.useState<any>('add');
  const [hasBlockChainAddress, setHasBlockChainAddress] = React.useState(false);
  const [roleList, setRoleList] = React.useState([]);
  const [userRoleData, setUserRoleData] = React.useState<any>({});
  const [userRoleArray, setUserRoleArray] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);
  const [userId, setUserId] = React.useState('');
  const [UserName, setUserName] = React.useState('');
  const [FirstName, setFirstName] = React.useState('');
  const [LastName, setLastName] = React.useState('');
  const [Password, setPassword] = React.useState('');
  const [userNameValid, setUserNameValid] = React.useState(false);
  const [firstNameValid, setFirstNameValid] = React.useState(false);
  const [passwordValid, setPasswordValid] = React.useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [firstNameEditValid, setFirstNameEditValid] = React.useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [retypePassword, setRetypePassword] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openRole, setOpenRole] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [order, setOrder] = React.useState<any>('desc');
  const [orderBy, setOrderBy] = React.useState<any>('CreatedAt');
  const [searchKey, setSearchKey] = React.useState<any>();
  const [userStatus, setUserStatus] = React.useState('All');
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const filterUserArray: any[] = ['All', 'Enable', 'Disable'];
  const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'userName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'firstName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'lastName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'createdAt', 'order': 'desc', 'isActive': false }]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmedPasswordValid, setConfirmedPasswordValid] = useState(false);

  const cookies = new CookieStorage();
  const jwtServices = new JwtServices();
  const debouncedValue = useDebounce<string>(searchKey, 500);




  const getUsers = (query, type?: string) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_USERS, null, null, query, {
      ETId: 10
    }).then(res => {
      props.toggleLoader(false);
      setUsers(res.data.data);
      setTotalRecord(res.data.totalRecord);
      if (parseInt(res.data.totalRecord) % pageSize === 0) {
        setTotalPage((parseInt(res.data.totalRecord) / pageSize));
      }
      else {
        setTotalPage((Math.floor(parseInt(res.data.totalRecord) / pageSize)) + 1);
      }
    }).catch(error => {
      props.toggleLoader(false);
      Toaster.errorToaster(error);
    })
  }

  const getRoles = () => {
    RestApiService.invoke(ApiEndPoints.GET_ROLES, null, null, null, {
      ETId: 15
    }).then(res => {
      setRoleList(res.data.data);
    }).catch(error => {
      Toaster.errorToaster(error);
    })
  }



  const handleClose = (text: string) => {
    if (text === 'user' || text === 'delete') {
      setOpen(false);
      setOpenChangePassword(false);
    } else {
      setOpenRole(false);
    }
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const onSortingData = useCallback((order, orderBy, data: any) => {
    if (orderBy && order) {
      setOrderBy(orderBy.charAt(0).toUpperCase() + orderBy.slice(1));
      setOrder(order);
      setOrderIndexedFields(data);
    }
  }, []);

  const onUserAccount = (isDeleted: boolean, item: any) => {
    const currentUserDetails = jwtServices.getDecodedJWTToken()
    if (currentUserDetails.id === item.id) {
      Toaster.errorToaster("You can't lock yourself.")
      return
    }
    const userData = {
      ETId: 10,
      Data: [{
        UID: item.id,
        IsDeleted: isDeleted
      }]
    }
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.DELETE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      if (!isDeleted) {
        Toaster.successToaster('Update User', item.userName + ' user is enabled successfully');
      }
      else {
        Toaster.successToaster('Update User', item.userName + ' user is disabled successfully');
      }

      setOpen(false);
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response.data.errors);
      error.response.data.errors.forEach((response) => {
        Toaster.errorToaster(response.message);
      })
    })
  }

  const openModal = (action: string, item?: any) => {
    if (action === 'changePassword') {
      setOpenChangePassword(true)
      setMode('changePassword')
      handleMenuClose();
      setUser(item);
      setUserName(item.userName);
      setUserObj({ UID: item.id })
    }
    if (action === 'delete') {
      setOpen(true)
      setMode('delete')
      setUserId(item.id);
      setUserName(item.userName);
    }
    if (action === 'addUser') {
      setOpen(true)
      handleMenuClose();
      setMode('add');
      setUserName("");
      setFirstName("");
      setLastName("")
    }
    if (action === 'add') {
      if (item.roles.length > 0) {
        openUserRoleModal('edit', item.roles)
        setUser(item);
        setUserObj({ UID: item.id })
        getRoles();
      }
      else {
        openUserRoleModal('add')
        setUser(item);
        setUserObj({ UID: item.id })
        getRoles();
      }

    }
    if (action === 'view') {
      setMode('view')

    }
    if (action === 'edit') {
      setOpen(true)
      handleMenuClose();
      setMode('edit')
      setUser(item);
      item.userAddress !== null ? setIsDisabled(true) : setIsDisabled(false);
      setUserName(item.userName);
      setFirstName(item.firstName);
      setLastName(item.lastName);
      setUserObj({ UID: item.id })
      getRoles();
    }
  };


  const openUserRoleModal = (action: string, userRole?: any) => {
    setOpenRole(true);
    handleMenuClose();
    if (action === 'add') {
      setRoleMode('add');
      setUserRoleArray([]);

    }
    if (action === 'edit') {
      handleClose('user');
      setRoleMode('edit')
      setUserRoleData(userRole);

      setUserRoleArray(userRole);
    }
  };



  const onView = (item) => {
    setOpen(true);
    setUser(item);
    setMode('view');
  }

  const addUser = (userData: any) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.CREATE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      if (hasBlockChainAddress) {
        const address = {
          ETId: 11,
          SV: 1,
          Data: [{
            UserUID: res.data.data.UID[0]
          }
          ]
        }
        assignAddress(address);
      }
    }).catch((error) => {
      props.toggleLoader(false);
      console.log('Get error', error);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const editUser = (userData) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.UPDATE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      Toaster.successToaster('Update Role', 'User updated successfully.Changes will reflect in sometime');
      setOpen(false);
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const saveUser = () => {
    if (mode === 'add') {
      const userData = {
        ETId: 10,
        SV: 1,
        Data: [{
          UserName,
          FirstName,
          LastName,
          Password
        }
        ]
      }
      addUser(userData);
    }
    else {
      const userData = {
        ETId: 10,
        SV: 1,
        Data: [userObj]
      }
      if (Object.keys(userObj).length > 1) {
        editUser(userData);
      }
      else if ((isDisabled || !hasBlockChainAddress) && (Object.keys(userObj).length === 1)) {
        Toaster.errorToaster('Please make some changes to be saved ');
      }
    }

  }

  const addUserRole = (userRole) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.CREATE_USER_ROLE, null, userRole, null, {
      ETId: 16
    }).then(res => {
      Toaster.successToaster('Add UserRole', 'User role created successfully');
      handleClose('role');
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }


  const editUserRole = (userRole) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.UPDATE_USER_ROLE, null, userRole, null, {
      ETId: 16
    }).then(res => {
      Toaster.successToaster('Update UserRole', 'User role updated successfully');
      handleClose('role');
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const assignRoles = () => {
    if (roleMode === 'add') {
      const Data = [];
      userRoles.map((role: any) => {
        const userData = {
          RoleID: role.id,
          UserUID: user.id
        }
        Data.push(userData);
      })
      const userRole = {
        ETId: 16,
        SV: 1,
        Data: Data
      }
      addUserRole(userRole);
    }
    else {
      const Data = [];
      userRoles.map((role: any) => {
        const userData = {
          RoleID: role.id,
          UserUID: user.id
        }
        Data.push(userData);
      })
      user.roles.map((existUsers: any) => {
        let eData = userRoles.filter(element => element.id === existUsers.id);
        if (eData.length < 1) {
          const existUser = {
            UID: existUsers.userRoleId,
            IsDeleted: true
          }
          Data.push(existUser);
        }
      })

      const userRole = {
        ETId: 16,
        //  SV: 2,
        Data: Data
      }
      editUserRole(userRole);
    }
  }


  const assignAddress = (address) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.ADD_USER_BLOCKCHAIN_ADDRESS, null, address, null, {
      ETId: 11,
      SV: 1
    }).then(res => {
      Toaster.successToaster('Create User', 'User added successfully');
      setOpen(false);
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });

  }


  const onPageChange = (page: any) => {
    setCurrentPageNo(page);

  }

  const goToPreviousPage = useCallback((page: any) => {
    setCurrentPageNo(page);
  }, [])

  const goToNextPage = useCallback((page: any) => {
    if (currentPageNo < totalPage) {
      setCurrentPageNo(page);
    }
  }, [currentPageNo, totalPage]);

  const onSearchData = useCallback((searchData: string | undefined | null) => {
    if (searchData.trim() !== undefined) {
      setSearchKey(searchData?.trim());
    }
  }, [currentPageNo, pageSize, orderBy, order, userStatus, debouncedValue, getUsers]);



  const onCancelSearch = useCallback((value: any) => {
    if (value) {
      setSearchKey('');
      setCurrentPageNo(1);
      setPageSize(10);
      getUsers({
        pageNo: currentPageNo, pageSize: pageSize,
        orderBy: orderBy, order: order, totalReq: true, userStatus: userStatus === 'Enable' ? false : userStatus === 'Disabled' ? true : ''
      })
    }
  }, [currentPageNo, pageSize, orderBy, userStatus])

  const isLessthanTotalElement = useCallback(() => {
    if ((currentPageNo * pageSize) === (totalPage - 1))
      return true;
    else
      return false;
  }, [currentPageNo, pageSize, totalPage])

  const changePageSize = useCallback((value: any) => {
    setPageSize(value);
    setCurrentPageNo(1);

  }, []);

  useEffect(() => {
    if (mode === 'edit' && FirstName !== user.firstName) {
      setUserObj({ ...userObj, FirstName })
    }
    else {
      delete userObj["FirstName"]
    }
  }, [FirstName]);

  useEffect(() => {
    if (mode === 'edit' && LastName !== user.lastName) {
      setUserObj({ ...userObj, LastName })
    }
    else {
      delete userObj["LastName"]
    }
  }, [LastName]);
  useEffect(() => {
    if (mode === 'changePassword' && Password !== user.password) {
      setUserObj({ ...userObj, Password })
    }
    else {
      delete userObj["Password"]
    }
  }, [Password]);

  useEffect(() => {
    user && user.userAddress === null ? setHasBlockChainAddress(false) : setHasBlockChainAddress(true);

  }, [user]);

  useEffect(() => {
    if (pageSize && currentPageNo && orderBy && order) {
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true });
    }
  }, [pageSize, currentPageNo, order, orderBy]);

  useEffect(() => {
    currentPageNo === 1 ? getUsers({
      pageNo: currentPageNo, pageSize: pageSize,
      orderBy: orderBy, order: order, searchKey: debouncedValue, totalReq: true, userStatus: userStatus === 'Enable' ? false : userStatus === 'Disabled' ? true : ''
    }) : setCurrentPageNo(1);
  }, [debouncedValue]);

  const handleAction = (item: any, action: any) => {
    if (action == 'view') {
      setOpen(true);
    }
  }
  const deleteUser = () => {
    const userData = {
      ETId: 10,
      Data: [{
        UID: userId,
        IsDeleted: true
      }]
    }
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.DELETE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      Toaster.successToaster('Delete User', 'User deleted successfully');
      setOpen(false);
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response.data.errors);
      error.response.data.errors.forEach((response) => {
        Toaster.errorToaster(response.message);
      })
    })
  }
  const onFilterUserStatus = (status: any) => {
    setUserStatus(status);
    getUsers({
      pageNo: currentPageNo, pageSize: pageSize,
      orderBy: orderBy, order: order, totalReq: true, searchKey: debouncedValue, userStatus: status === 'Enable' ? false : status === 'Disable' ? true : ''
    })

  }
  const changePassword = () => {
    if (Password === retypePassword) {
      const userData = {
        ETId: 10,
        Data: [userObj]
      }
      props.toggleLoader(true);
      RestApiService.invoke(ApiEndPoints.CHANGE_PASSWORD, null, userData, null, {
        ETId: 10
      }).then(res => {
        Toaster.successToaster('Change Password', 'Password changed successfully');
        setOpenChangePassword(false);
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }).catch(error => {
        props.toggleLoader(false);
        console.log('Get error', error.response.data.errors);
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        })
      })
    }
    else {
      Toaster.errorToaster('Password does not match.');
    }
  }

  const getConvertFunctionByColumn = useCallback((value: string) => {
    const date = changeEpochToDate(parseInt(value, 10));
    const stillUtc = moment.utc(date).toDate();
    return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss')
  }, [changeEpochToDate]);

  const getRoleNames = useCallback((value: any) => {
    if (value?.data?.roles?.length > 0) {
      return value.data.roles.map(role => role.roleName).join(', ');
    }
    return 'null';
  }, []);

  const userLastNameColumn = useCallback((value: any) => {
    return value.data.lastName ? value.data.lastName : 'null'
  }, []);


  const userAddressAssigned = useCallback((value: any) => {
    return value?.data?.userAddress === null ? 'No' : 'Yes';
  }, []);

  const getLastLoginColumn = useCallback((value: any) => {
    const dateAndTime = value?.data?.lastLogin;
    if (dateAndTime) {
      const date = changeEpochToDate(parseInt(dateAndTime, 10));
      const stillUtc = moment.utc(date).toDate();
      return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss:SSS');
    }
    return 'null';
  }, [changeEpochToDate]);

  const ViewIconCellRenderer = useCallback((value: any): React.ReactNode => {
    return (
      <div className='user-icons'>
        <Tooltip title="Edit" placement="right">
          <span><Creates className="edit" onClick={(e: any) => openModal('edit', value?.data)} /></span>
        </Tooltip>

        <Tooltip title="Role" placement="right">
          <span><AccountTreeIcons className="add" onClick={() => openModal('add', value?.data)} /></span>
        </Tooltip>

        <Tooltip title="Change Password" placement="right">
          <span><LockOpenIcons className="change" onClick={() => openModal('changePassword', value?.data)} /></span>
        </Tooltip>
      </div>
    )
  }, []);

  const customActionColumnFormatters = [
    ViewIconCellRenderer
  ]

  const tableColumnList = useMemo(() => [
    { name: "User Name", db: "userName", sort: true },
    { name: "First Name", db: "firstName", sort: true },
    { name: "Last Name", db: "lastName", sort: true, cellRender: userLastNameColumn },
    { name: "Created At", db: "createdAt", sort: true, format: getConvertFunctionByColumn },
    { name: "Address Assigned", db: "userAddress", cellRender: userAddressAssigned },
    { name: "Role", db: "roles", cellRender: getRoleNames },
    { name: "Last Login", db: "lastLogin", cellRender: getLastLoginColumn },
  ], [getConvertFunctionByColumn]);


  const checkConfirm = useCallback(() => {
    confirmField.current?.instance.validate();
  }, []);


  const handleSetPassword = useCallback((value) => {
    setPassword(value)
    checkConfirm();

  }, [checkConfirm]);


  const handleReTypePassword = useCallback((value) => {
    setRetypePassword(value)
  }, []);


  const passwordValidated = useCallback((e: any) => {
    setNewPasswordValid(!!e.isValid)
  }, []);

  const retypePasswordValidated = useCallback((e: any) => {
    setConfirmedPasswordValid(!!e.isValid)
  }, []);



  const confirmPasswordValidators = useMemo((): any[] => {
    return [{
      type: 'compare',
      message: 'Passwords do not match',
      comparisonTarget: () => Password,
    }];
  }, [Password]);


  useEffect(() => {
    const formValues = [Password, retypePassword];
    const validity = [newPasswordValid, confirmedPasswordValid];

    setIsSaveDisabled(
      formValues.some((value) => !value) ||
      validity.some((value) => !value)
    );
  }, [
    Password,
    retypePassword,
    newPasswordValid,
    confirmedPasswordValid
  ]);


  const handleAddUserName = useCallback((newValue: string) => {
    const formattedValue = newValue.replace(' ', '_');
    if ((/^[a-zA-Z_0-9 ]+$/).test(formattedValue)) {
      setUserName(formattedValue);
    } else {
      setUserName('');
    }
  }, []);

  const handleAddUserValidate = useCallback((e: any) => {
    setUserNameValid(!!e.isValid);
  }, []);

  const handleAddFirstName = useCallback((newValue: string) => {
    const formattedValue = newValue.replace(' ', '_');
    if ((/^[a-zA-Z_0-9 ]+$/).test(formattedValue)) {
      setFirstName(formattedValue);
    }
    else {
      setFirstName('');
    }
  }, []);

  const handleAddFirstNameValid = useCallback((e: any) => {
    setFirstNameValid(!!e.isValid);
  }, []);

  const handleAddLastName = useCallback((newValue: string) => {
    setLastName(newValue)
  }, [])

  const handleuserName = useCallback((newValue: string) => {
    const formattedValue = newValue.replace(' ', '_');
    if ((/^[a-zA-Z_0-9 ]+$/).test(formattedValue)) {
      setUserName(formattedValue);
    } else {
      setUserName('');
    }
  }, []);

  const handleFirstName = useCallback((newValue: string) => {
    const formattedValue = newValue.replace(' ', '_');
    if ((/^[a-zA-Z_0-9 ]+$/).test(formattedValue)) {
      setFirstName(formattedValue);
    } else {
      setFirstName('');
    }
  }, []);

  const handleEditFirstNameValid = useCallback((e: any) => {
    setFirstNameEditValid(!!e.isValid);
  }, []);

  const handleLastName = useCallback((newValue: string) => {
    setLastName(newValue)
  }, []);


  const handlePassword = useCallback((e: any) => {
    setPassword(e)
  }, []);

  const handleAddPasswordValid = useCallback((e: any) => {
    setPasswordValid(!!e.isValid);
  }, []);

  const accountLockHandler = useCallback((checkBoxEvent: any, item: any) => {
    onUserAccount(checkBoxEvent, item?.data);
  }, [onUserAccount]);

  useEffect(() => {
    const formValues = [UserName, FirstName, Password];
    const validity = [userNameValid, firstNameValid, passwordValid];

    setIsAddDisabled(
      formValues.some((value) => !value) ||
      validity.some((value) => !value)
    );
  }, [
    UserName,
    FirstName,
    Password,
    userNameValid,
    firstNameValid,
    passwordValid
  ]);


  useEffect(() => {
    const formValues = [FirstName];
    const validity = [firstNameEditValid];

    setIsEditDisabled(
      formValues.some((value) => !value) ||
      validity.some((value) => !value)
    );
  }, [
    FirstName,
    firstNameEditValid
  ]);
  return (
    <div className="mainview">
      <div className="maincontent">
        <div className="dashboardcover">
          <div className='user-page'>
            <div className="Innerheading">
              <div className='view-wrapper view-wrapper-user-details dx-theme-background-color'>
                <NewSubHeaderComponent
                  filterHeading={'Filter By Account Locked:'}
                  headingText={'User Management'}
                  btnText={'Add User'}
                  searchLabel={'User'}
                  showSearch
                  showFilter
                  showBtn
                  searchKey={debouncedValue}
                  filterArray={filterUserArray}
                  filterUserStatus={onFilterUserStatus}
                  userStatus={userStatus}
                  onSearchData={onSearchData}
                  onCancelSearch={onCancelSearch}
                  onButtonClick={(value: any) => openModal('addUser')}
                />
                <div className="Innerdetails dx-card dx-theme-background-color">
                  <DynamicTableList
                    dataSource={users}
                    columnList={tableColumnList}
                    columnAutoWidth={true}
                    orderIndexedFields={orderIndexedFields}
                    onSortingData={onSortingData}
                    fromComponent={'UserComponent'}
                    showPagination
                    goToPreviousPage={goToPreviousPage}
                    goToNextPage={goToNextPage}
                    changePageSize={changePageSize}
                    pageSize={pageSize}
                    currentPageNo={currentPageNo}
                    totalPage={totalPage}
                    totalRecord={totalRecord}
                    isLessthanTotalElement={isLessthanTotalElement()}
                    showCustomActions
                    customActionColumnFormatters={customActionColumnFormatters}
                    showCheckBoxAction
                    checkBoxColumnName="Account Locked"
                    checkBoxKeyExpr='isDeleted'
                    checkBoxOnChange={accountLockHandler}
                  />

                  {
                    mode === 'add' ? <ModalBox
                      title='Add User Details'
                      width={480}
                      height={'auto'}
                      visible={open}
                      onHiding={() => handleClose('user')}
                      wrapperAttr={{ class: 'add-user-form-popup' }}
                      showCloseButton
                      showTitle
                      dragEnabled
                    >
                      <AddUserForm
                        UserName={UserName}
                        handleAddUserName={handleAddUserName}
                        handleAddUserValidate={handleAddUserValidate}
                        FirstName={FirstName}
                        handleAddFirstName={handleAddFirstName}
                        handleAddFirstNameValid={handleAddFirstNameValid}
                        LastName={LastName}
                        handleAddLastName={handleAddLastName}
                        Password={Password}
                        handlePassword={handlePassword}
                        handleAddPasswordValid={handleAddPasswordValid}
                      />
                      <ToolbarItem
                        toolbar='bottom'
                        location='after'
                      >
                        <div className={`form-popup-buttons-container ${props.width <= 360 ? 'flex-buttons' : ''}`}>
                          <Button text='Cancel' stylingMode='outlined' type='normal' onClick={() => handleClose('user')}
                          />
                          <Button text='Save' stylingMode='contained' type='default' disabled={isAddDisabled} onClick={saveUser}
                          />
                        </div>
                      </ToolbarItem>
                    </ModalBox> : null
                  }

                  {
                    mode === 'edit' ? <ModalBox
                      title='Edit User Details'
                      visible={open}
                      width={480}
                      height={'auto'}
                      wrapperAttr={{ class: 'edit-user-form-popup' }}
                      onHiding={() => handleClose('user')}
                      showCloseButton
                      showTitle
                      hideOnOutsideClick
                      dragEnabled
                    >
                      <EditUserForm
                        UserName={UserName}
                        handleuserName={handleuserName}
                        FirstName={FirstName}
                        handleFirstName={handleFirstName}
                        handleEditFirstNameValid={handleEditFirstNameValid}
                        LastName={LastName}
                        handleLastName={handleLastName}
                        user={user}
                        mode={mode}
                        openUserRoleModal={openUserRoleModal}
                        hasBlockChainAddress={hasBlockChainAddress}
                        setHasBlockChainAddress={setHasBlockChainAddress}
                      />

                      <ToolbarItem
                        toolbar='bottom'
                        location='after'
                      >
                        <div className={`form-popup-buttons-container ${props.width <= 360 ? 'flex-buttons' : ''}`}>
                          <Button text='Cancel' stylingMode='outlined' type='normal' onClick={() => handleClose('user')}
                          />
                          <Button text='Save' stylingMode='contained' type='default' disabled={isEditDisabled} onClick={saveUser}
                          />
                        </div>
                      </ToolbarItem>
                    </ModalBox> : null
                  }

                  <Dialog open={openRole} className="useraddpopup" >
                    <DialogTitle disableTypography>
                      <div className="usertile">
                        {roleMode === 'add' && <Typography component={'span'} variant="h6">User Role</Typography>}
                        {roleMode === 'edit' && <Typography component={'span'} variant="h6">Edit User Role</Typography>}
                      </div>
                      <IconButton aria-label="close" onClick={() => handleClose('role')}>
                        <Close />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Typography component={'span'} gutterBottom>
                        <Grid container spacing={6} className="add-lable">
                          <Grid item xs={12} md={2}>
                            <span>Select</span>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <div className="multi">
                              <MultipleSelectComponent
                                labelname={"Roles"}
                                data={roleList}
                                mode={roleMode}
                                from={"USER"}
                                selectedValues={roleMode === 'edit' ? userRoleArray : []}
                                handleRoles={(data) => setUserRoles(data)}
                              />

                            </div>

                          </Grid>
                        </Grid>

                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Buttons className={"btn-primary"} variant="contained" disabled={userRoles.length === 0} color="primary" onClick={assignRoles}>
                        Save
                      </Buttons>
                    </DialogActions>
                  </Dialog>
                  <div className="change-password-modal">
                    <FormPopup
                      title={'Change Password'}
                      visible={openChangePassword}
                      setVisible={() => handleClose('user')}
                      wrapperAttr={{ class: 'change-profile-password-popup' }}
                      width={360}
                      height={300}
                      onSave={changePassword}
                      isSaveDisabled={isSaveDisabled}
                    >
                      <EditChangePassword
                        Password={Password}
                        handleSetPassword={handleSetPassword}
                        passwordValidated={passwordValidated}
                        confirmField={confirmField}
                        confirmPasswordValidators={confirmPasswordValidators}
                        retypePassword={retypePassword}
                        handleReTypePassword={handleReTypePassword}
                        retypePasswordValidated={retypePasswordValidated}
                      />
                    </FormPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);


interface UserProps {
  toggleLoader: any;
  width?: number;
}