import { ToastContainer, toast } from 'react-toastify';


class Toaster {

    public successToaster(toasterId: any, txt: string) {
        if (!toast.isActive(toasterId)) {
            toast.success(txt, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                toastId: toasterId
            });
        }
    }
    public infoToaster(txt: string) {
        toast.info(txt, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000
        });
    }

    public warnToaster(txt: string) {
        toast.warn(txt, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000
        });
    }
    public errorToaster(txt: string) {
        toast.error(txt, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
        });
    }

    public errorToasterWithId(toasterId: any, txt: string) {
        if (!toast.isActive(toasterId)) {
            toast.error(txt, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });
        }
    }

    public errorToasterWith30S(txt: string) {
        toast.error(txt, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
        });
    }
}

export default new Toaster();