import React, { useEffect, useCallback } from 'react';
import {
    MenuItem, FormControl, TextField, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip, Checkbox
} from '@material-ui/core';
import "../../../../assets/themes/default/share.scss";
import "./dynamic-list.scss";
import { changeUTCMillisecondToDate, changeEpochToDate } from '../../../utils/utilityFunctions';
import { AddCircle, Visibility, Delete, Create, VpnKey, Search } from '@material-ui/icons';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Pagination } from 'react-bootstrap';
import moment from 'moment';

const DynamicList = (props: DynamicListProps) => {
    const [pageSizeList, setPageSizeList] = React.useState<Array<{ "name": number }>>([{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }]);
    const [prePage, setPrePage] = React.useState();
    const [nextPage, setNextPage] = React.useState();
    const [pageSize, setPageSize] = React.useState();
    const [rows, setRows] = React.useState<any>([]);
    const [action, setAction] = React.useState<any>('');
    const [item, setItem] = React.useState<any>();
    const [order, setOrder] = React.useState<any>('desc');
    const [orderIcon, setOrderIcon] = React.useState<any>('asc');
    const [orderBy, setOrderBy] = React.useState<any>();
    const [isDeleted, setIsDeleted] = React.useState(false);
    const [item1, setItem1] = React.useState<any>();
    const [orderByItem, setOrderByItem] = React.useState<any>();
    const [orderedData, setOrderedData] = React.useState<any>(props.orderIndexedFields);
    const [rowSelected, setRowSelected] = React.useState<Array<any>>([]);

    React.useEffect(() => {
        if (props.dataList)
            findRows(props.dataList);
    }, []);

    const findRows = (data: any) => {
        if (data && data.length > 0) {
            for (const obj of data) {
                for (const key of Object.keys(obj)) {
                    if (!rows.includes(key)) {
                        rows.push(key)
                        setRows([...rows]);
                    }
                }
            }
        }
    }

    let pageSizeLst = [{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }]
    const noOfPageDropdown = useCallback(() => {
        if (props.totalRecord <= 10) setPageSizeList(pageSizeLst.slice(0, 1))
        else if (props.totalRecord > 10 && props.totalRecord <= 25) setPageSizeList(pageSizeLst.slice(0, 2))
        else if (props.totalRecord > 25 && props.totalRecord <= 50) setPageSizeList(pageSizeLst.slice(0, 3))
        else setPageSizeList(pageSizeLst.slice(0, 4))
    }, [props?.totalRecord, pageSizeList]);
    useEffect(() => {
        noOfPageDropdown()
    }, [props?.totalRecord])


    const convertValue = (value, columnname) => {

        if (typeof value === 'boolean' && columnname !== 'isDeleted') {
            return value.toString();
        }
        else if (value && (columnname === 'StartTime' || columnname === 'QueueTime' || columnname === 'EndTime' ||
            columnname === 'LastCheck' || columnname === 'PostDate' || columnname === 'CreatedAt' || columnname === 'createdAt' ||
            columnname === 'UpdatedAt' || columnname === 'updatedAt' || columnname === 'LastLogin' || columnname === 'TS' || columnname === 'CreateDate' || columnname === 'lastLogin')) {
            const date = changeEpochToDate(value);
            const stillUtc = moment.utc(date).toDate();
            // return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
            return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss.SSS');
        }
        else if ((typeof (value) === 'object' && columnname === 'ESStatusInfo')) {
            return value.label;
        }
        else if ((typeof (value) === 'object' && columnname === 'userAddress')) {
            return value === null ? 'No' : 'Yes';
        }
        else if (columnname === 'isDeleted') {
            return value;
        }
        else if (columnname === 'roles') {
            return value.map((item, index) => index != 0 ? ', ' + item.roleName : '' + item.roleName);
        }
        else if (columnname === 'scopes') {
            return value.map((item, index) => index != 0 ? ', ' + item : '' + item);
        }
        else {
            return value ? value : 'null';
        }
    }

    useEffect(() => {
        if (order && orderBy && props.onSortingData && orderedData) {
            props.onSortingData(order, orderBy, orderedData)
        }
    }, [order, orderBy]);

    const handleSorting = (orderBy: any, order: string): any => {
        setOrder(order);
        setOrderBy(orderBy);
        var data = props.orderIndexedFields.map(item => ({ ...item, order: 'desc' }));
        // var data = [...this.state.orderIndexedFields];
        var index = data.findIndex(obj => obj.orderBy === orderBy);
        data[index].order = order === 'desc' ? 'desc' : 'asc';
        var index1 = data.findIndex(obj => obj.isActive);
        if (index1 > -1) {
            data[index1].isActive = false;
        }
        data[index].isActive = true;
        setOrderedData(data);
        // setOrderByItem(item.charAt(0).toLowerCase() + item.slice(1));
        // { text === "asc" ? setOrderIcon("desc") : setOrderIcon("asc") }
        // // item.preventDefault();       
    };

    useEffect(() => {
        if (action && item) {
            props.handleAction(item, action);
        }
    }, [action, item]);
    useEffect(() => {
        if (isDeleted || item1) {
            props.userAccount(isDeleted, item1);
        }
    }, [isDeleted, item1]);

    const actionChange = (item: any, action: string): any => {
        setAction(action);
        setItem(item);
    };
    const onChecked = (isDeleted: boolean, item: any): any => {
        setIsDeleted(isDeleted)
        setItem1(item);
    };
    useEffect(() => {
        if (prePage) {
            props.goToPreviousPage(prePage);
        }
    }, [prePage]);

    const goToPreviousPage = (page: any) => {
        setPrePage(page);
        props.goToPreviousPage(page);
    }

    useEffect(() => {
        if (nextPage) {
            props.goToNextPage(nextPage);
        }
    }, [nextPage]);

    const goToNextPage = (page: any) => {
        setNextPage(page);
        props.goToNextPage(page);
    }

    useEffect(() => {
        if (pageSize) {
            props.changePageSize(pageSize);
        }
    }, [pageSize]);

    const changePageSize = (value: any) => {
        setPageSize(value);

    }

    const checkboxHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, uniqueKey: string | number) => {
        props.onCheckBoxChange(event, checked, uniqueKey);
        // rowSelected
    }

    return (
        <div className="dynamic-list" >
            {/* <Paper>
                <div className="filter-block">
                    <div className="dropdown-filter">
                    </div>
                    <div className="search-cover">
                        <FormControl className="formControl-input">
                            <TextField
                                id="standard-uncontrolled"
                                label="Search Value"
                                margin="normal"
                                variant="outlined"
                                onChange={handleChangeSearch}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <Search color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                </div>
            </Paper> */}
            <Paper >
                <div className={`dynamic-table ${props.fromComponent === 'BPMComponent' ? 'bpm-table' : props.fromComponent === 'EnvelopeListComponent' ? 'envelopelist-table' : ''} `}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {props.checkBoxEnabled && <TableCell><div className="table-sort"> </div></TableCell>}
                                {props.SNo && <TableCell><div className="table-sort">S.No. </div></TableCell>}
                                {props.columnList && props.columnList.length > 0 ? props.columnList.map((item: any, index: any) => <TableCell key={index}>
                                    {!props.keysToHide.includes(item) && <div className="table-sort">
                                        {item.name}
                                        {item.sort && props.orderIndexedFields.length > 0 && <div className="sort-icons">
                                            {/* {orderIcon === "asc" && <Tooltip title="ascending">
                                                <ArrowDownwardIcon onClick={() => handleSorting(item.db, 'asc')} className={orderBy === item.db ? 'arrowiconactive' : 'arrowicon'} />
                                            </Tooltip>}
                                            {orderIcon === "desc" && <Tooltip title="descending">
                                                <ArrowUpwardIcon onClick={() => handleSorting(item.db, 'desc')} className={orderBy === item.db ? 'arrowiconactive' : 'arrowicon'} />
                                            </Tooltip>} */}
                                            {props.orderIndexedFields.find(x => x.orderBy === item.db && x.order === 'desc' && !x.isActive) &&
                                                <Tooltip title="ascending">
                                                    <ArrowDownwardIcon onClick={() => handleSorting(item.db, 'asc')} className="arrowicon" />
                                                </Tooltip>}
                                            {props.orderIndexedFields.find(x => x.orderBy === item.db && x.order === 'asc' && x.isActive) &&
                                                <Tooltip title="descending">
                                                    <ArrowUpwardIcon onClick={() => handleSorting(item.db, 'desc')} className="arrowiconactive" />
                                                </Tooltip>}
                                            {props.orderIndexedFields.find(x => x.orderBy === item.db && x.order === 'desc' && x.isActive) &&
                                                <Tooltip title="ascending">
                                                    <ArrowDownwardIcon onClick={() => handleSorting(item.db, 'asc')} className="arrowiconactive" />
                                                </Tooltip>}
                                        </div>
                                        }
                                        {/* {item.sort && props.isOrderIconChange && <div className="sort-icons">
                                            {orderByItem !== item.db &&
                                                <Tooltip title="ascending">
                                                    <ArrowDownwardIcon onClick={() => handleSorting(item.db, 'asc')} className="arrowicon" />
                                                </Tooltip>}
                                            {orderIcon === "asc" && orderByItem === item.db &&
                                                <Tooltip title="ascending">
                                                    <ArrowDownwardIcon onClick={() => handleSorting(item.db, 'asc')} className="arrowiconactive" />
                                                </Tooltip>}
                                            {orderIcon === "desc" && orderByItem === item.db &&
                                                <Tooltip title="descending">
                                                    <ArrowUpwardIcon onClick={() => handleSorting(item.db, 'desc')} className="arrowiconactive" />
                                                </Tooltip>
                                            }


                                            //<img className="icon-asc" onClick={() => handleSorting(item.db, 'asc')} src={require('../../../../assets/images/down.png')} alt="Acs"></img>
                                            //<img className="icon-desc" onClick={() => handleSorting(item.db, 'desc')} src={require('../../../../assets/images/up.png')} alt="Desc"></img>
                                        
                                        </div>
                                        } */}
                                    </div>}
                                </TableCell>) : <TableCell>Undefined</TableCell>}
                                {props.showActions && <TableCell><div className="table-sort">Actions</div></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.dataList && props.dataList.length > 0 ? props.dataList
                                .map((item: any, index: any) =>
                                    <TableRow key={index} style={{ background: "white" }}>
                                        {props.SNo && <TableCell>{index + 1}</TableCell>}
                                        {props.checkBoxEnabled && <TableCell><Checkbox
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            key={item[props.primaryColumn]}
                                            onChange={(event, checked) => checkboxHandler(event, checked, item[props.primaryColumn])}
                                            checked={item.checked}
                                        /></TableCell>}
                                        {
                                            props.columnList && props.columnList.length > 0 ? props.columnList.map((key: any, index: any) =>
                                                (!props.keysToHide.includes(key.db)) && key.db !== 'isDeleted' && <TableCell className={`${(convertValue(item[key.db], key.db) === 'null') ? 'null-text' : ''}`} key={index}>{convertValue(item[key.db], key.db)}</TableCell>)
                                                : <TableCell>N.A</TableCell>
                                        }
                                        {props.columnList.length > 0 && props.columnList.map((key: any, index: any) =>
                                            (!props.keysToHide.includes(key.db)) && key.db === 'isDeleted' && <TableCell>
                                                {key.renderer ? key.renderer(item) : <div className="checked-user"> <Checkbox
                                                    checked={convertValue(item[key.db], key.db)}
                                                    value=''
                                                    onChange={(event) => onChecked(event.target.checked, item)}
                                                    color="primary"
                                                /></div>}
                                            </TableCell>)
                                        }

                                        {props.showActions && <TableCell>
                                            <div className="action-icon">
                                                {props.showUserEdit && <Tooltip title="Edit" placement="right">
                                                    <Create color="primary" onClick={() => actionChange(item, 'edit')} />
                                                </Tooltip>}
                                                {props.showAdd && <Tooltip title="Role" placement="right">
                                                    <AccountTreeIcon color="primary" onClick={() => actionChange(item, 'add')} />
                                                </Tooltip>}
                                                {props.showAddMultiUser && <Tooltip title="Users in Role" placement="right">
                                                    <GroupAddIcon color="primary" onClick={() => actionChange(item, 'add')} />
                                                </Tooltip>}
                                                {props.showEdit && <Tooltip title="Edit" placement="right">
                                                    <Create color="primary" onClick={() => actionChange(item, 'edit')} />
                                                </Tooltip>}
                                                {props.showDelete && <Tooltip title="Delete" placement="right">
                                                    < Delete color="primary" onClick={() => actionChange(item, 'delete')} />
                                                </Tooltip>}
                                                {props.showView && <Tooltip title="View" placement="right">
                                                    <Visibility color="primary" onClick={() => actionChange(item, 'view')} />
                                                </Tooltip>}
                                                {props.showChangePassword && <Tooltip title="Change Password" placement="right">
                                                    <LockOpenIcon color="primary" onClick={() => actionChange(item, 'changePassword')} />
                                                </Tooltip>}

                                            </div></TableCell>}
                                    </TableRow>) : <TableRow><TableCell>No Data Found</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </div>


                {props.showPagination && <div className={`pagination-cover`} >

                    <div className="total-result">
                        <div className="total-size">
                            {/* <p>Showing {props.currentPageNo} of {props.totalPage} pages ({props.totalRecord} records)</p> */}
                            <p>Showing {props.currentPageNo} of {props.totalPage} Pages | ({props.totalRecord} Records)</p>
                            <div className="filter-box pagination-pageSize">
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    label="Page Size"
                                    className="select-field page-size-select-field"
                                    margin="normal"
                                    variant="outlined"
                                    value={props.pageSize}
                                    onChange={(event: any) => { changePageSize(event.target.value) }}
                                >
                                    {pageSizeList.map((item, index) => (
                                        <MenuItem key={`pageSize_${index}`} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                        </div>
                        <Pagination>
                            <Pagination.Item
                                onClick={() => {
                                    if (props.currentPageNo > 1)
                                        goToPreviousPage(1)
                                }}
                                disabled={props.currentPageNo === 1}

                            >
                                First Page
                            </Pagination.Item>

                            <Pagination.Prev
                                onClick={() => {
                                    if (props.currentPageNo != 1) {
                                        let page = props.currentPageNo - 1;
                                        goToPreviousPage(page)
                                    } else {
                                        goToPreviousPage(props.currentPageNo)
                                    }
                                }}
                                disabled={props.currentPageNo === 1}
                            />

                            <Pagination.Item disabled>{props.currentPageNo}</Pagination.Item>

                            <Pagination.Next
                                onClick={() => {
                                    if (!props.isLessthanTotalElement && (props.currentPageNo < props.totalPage)) {
                                        let page = props.currentPageNo + 1;
                                        goToNextPage(page)
                                    }
                                    else {
                                        goToNextPage(props.currentPageNo);
                                    }
                                }}
                                disabled={(props.currentPageNo === props.totalPage) || (props.dataList.length === 0)}
                            />
                            <Pagination.Item
                                onClick={() => {
                                    if (props.currentPageNo < props.totalPage)
                                        goToNextPage(props.totalPage)
                                }}
                                disabled={(props.currentPageNo === props.totalPage) || (props.dataList.length === 0)}
                            >
                                Last Page
                            </Pagination.Item>
                        </Pagination>

                    </div>

                </div>}

            </Paper>
        </div >
    );
}

export default DynamicList;
interface DynamicListProps {
    dataList?: any;
    columnList: any;
    orderIndexedFields?: any;
    primaryColumn: any;
    // totalCount?: any;
    showEdit: boolean;
    showDelete: boolean;
    showView: boolean;
    showActions: boolean;
    onSortingData?: any;
    handleAction?: any;
    keysToHide?: any;
    fromComponent?: any;
    goToPreviousPage: any;
    goToNextPage: any;
    changePageSize: any;
    isLessthanTotalElement: boolean;
    pageSize: number;
    currentPageNo: number;
    totalPage: number;
    totalRecord: number;
    SNo: boolean;
    showUserEdit: boolean;
    showAdd: boolean;
    showPagination: boolean;
    showAddMultiUser: boolean;
    userAccount?: any;
    showChangePassword: boolean;
    isOrderIconChange: boolean;
    checkBoxEnabled?: boolean;
    onCheckBoxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, uniqueKey: string | number) => void;
}

DynamicList.defaultProps = {
    showEdit: true,
    showDelete: true,
    showView: true,
    showUserEdit: false,
    showAdd: false,
    showPagination: true,
    showAddMultiUser: false,
    showChangePassword: false,
    isOrderIconChange: false,
    checkBoxEnabled: false,
    onCheckBoxChange: () => { }
}