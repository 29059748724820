import { TOGGLE_LOADER} from '../types/loader.types';
const initialState = {
    isLoaderShow : false
}
const appLoaderReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case TOGGLE_LOADER:
        state =  {...state, isLoaderShow: action.payload};
        break;
    }
    return state;
}

export default appLoaderReducer;
