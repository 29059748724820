import * as React from 'react';

export function SchemaDetails({ fill = '#fff', width = 15, height = 15, className,color, id, onClick, ...props}: any) {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>analytics</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="3-Walacor-Dashboard-Update_v2" transform="translate(-122.000000, -287.000000)" fill="#0078D4" fill-rule="nonzero">
            <g id="analytics" transform="translate(122.000000, 287.000000)">
                <path d="M14.2307692,10.4700855 L14.2307692,20 L11.025641,20 L11.025641,10.4700855 L14.2307692,10.4700855 Z M19.7435897,5.94017094 L19.7435897,20 L16.5384615,20 L16.5384615,5.94017094 L19.7435897,5.94017094 Z M3.20512821,12.4786325 L3.20512821,20 L0,20 L0,12.4786325 L3.20512821,12.4786325 Z M8.71794872,7.90598291 L8.71794872,20 L5.51282051,20 L5.51282051,7.90598291 L8.71794872,7.90598291 Z M18.0306838,0 C18.9269231,0 19.6535043,0.726025641 19.6535043,1.62162393 C19.6535043,2.51722222 18.9269658,3.24324786 18.0307265,3.24324786 C17.7738889,3.24324786 17.5311966,3.18337607 17.3153419,3.07726496 L14.1533333,5.77094017 C14.2120085,5.93858974 14.2442735,6.11863248 14.2442735,6.30628205 C14.2442735,7.20188034 13.5177778,7.92790598 12.6215385,7.92790598 C11.7252991,7.92790598 10.9987607,7.20188034 10.9987607,6.30628205 C10.9987607,6.29576923 10.999359,6.28538462 10.9995726,6.27491453 L8.29974359,5.16918803 C8.00401709,5.48094017 7.58598291,5.67568376 7.12217949,5.67568376 C6.74311966,5.67568376 6.39474359,5.54547009 6.11854701,5.32786325 L3.31363248,6.98628205 C3.32794872,7.07286325 3.3357265,7.16162393 3.3357265,7.25226496 C3.3357265,8.14786325 2.60923077,8.87388889 1.71299145,8.87388889 C0.816752137,8.87388889 0.0902136752,8.14786325 0.0902136752,7.25226496 C0.0902136752,6.35666667 0.816752137,5.63064103 1.71299145,5.63064103 C2.09200855,5.63064103 2.44038462,5.7608547 2.7165812,5.97846154 L5.52149573,4.32004274 C5.50717949,4.23346154 5.49940171,4.14470085 5.49940171,4.05405983 C5.49940171,3.15846154 6.22589744,2.4324359 7.12213675,2.4324359 C8.01837607,2.4324359 8.74491453,3.15846154 8.74491453,4.05405983 L8.74410256,4.08547009 L11.4439316,5.19119658 C11.7396581,4.87944444 12.1576923,4.68470085 12.6214957,4.68470085 C12.900812,4.68470085 13.1636752,4.75525641 13.3931624,4.87948718 L16.520641,2.21525641 C16.4481624,2.03136752 16.4079487,1.83123932 16.4079487,1.62162393 C16.4079487,0.726025641 17.1344444,0 18.0306838,0 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </g>
</svg>
    );
}
