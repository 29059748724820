
export class ApiEndPoints {
    public static readonly SIGN_IN: APIDef = { 'method': 'POST', api: () => `/ui/api/auth/login` };
    public static readonly SIGN_UP: APIDef = { 'method': 'POST', api: () => `/ui/api/auth/register` };
    public static readonly RESET_PASSWORD: APIDef = { 'method': 'POST', api: () => `/ui/api/users/resetpassword` };
    public static readonly GET_ENVELOPES: APIDef = { 'method': 'GET', api: () => `/ui/api/envelopes` };
    public static readonly GET_ETIDs: APIDef = { 'method': 'POST', api: () => `/ui/api/getETIds` };
    public static readonly GET_ENVELOPE_TYPE_VERSIONS: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/envelopeTypes/${apiInput.ETId}/versions` };
    public static readonly GET_VERSIONS: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/versions${apiInput.queryString}` };

    public static readonly GET_HASHES: APIDef = { 'method': 'GET', api: () => `/ui/api/envelopes/hashes` };
    public static readonly VIEW_DATAHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/hashes/Model?DH=${apiInput.DH}&EId=${apiInput.EId}` };
    public static readonly DECRYPT_DATAHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/hashes/decrypt?DH=${apiInput.DH}&EId=${apiInput.EId}` };
    public static readonly VIEW_FILEHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/filehashes/Model?FH=${apiInput.FH}` };
    public static readonly DECRYPT_FILEHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/filehashes/decrypt?FH=${apiInput.FH}` };
    public static readonly GET_FILES: APIDef = { 'method': 'GET', api: () => `/ui/api/uploads` };
    public static readonly POST_FILES: APIDef = { 'method': 'POST', api: () => `/ui/api/uploads` };
    public static readonly GET_FILE_BY_NAME: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/uploads/${apiInput.type}/${apiInput.fileName}` };
    public static readonly DELETE_FILE_BY_NAME: APIDef = { 'method': 'DELETE', api: (apiInput: APIInput) => `/ui/api/uploads/${apiInput.fileName}` };

    public static readonly GET_PROFILE: APIDef = { 'method': 'GET', api: () => '/ui/api/profile' };
    public static readonly GET_PROFILES: APIDef = { 'method': 'GET', api: () => '/ui/api/profile/all' };
    public static readonly POST_PROFILE: APIDef = { 'method': 'POST', api: () => '/ui/api/profile' };
    public static readonly DELETE_PROFILE: APIDef = { 'method': 'DELETE', api: () => '/ui/api/profile' };
    public static readonly GET_PROFILE_BY_HANDLE: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/profile/handle/${apiInput.handle}` };

    public static readonly GET_SCHEMAS_TREE: APIDef = { 'method': 'GET', api: () => '/ui/api/schema/treeview' };
    public static readonly GET_SCHEMAS: APIDef = { 'method': 'GET', api: () => '/ui/api/schemas' };
    public static readonly GET_SYSTEM_FIELDS: APIDef = { 'method': 'GET', api: () => '/ui/api/systemFields' };
    public static readonly POST_SCHEMAS: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas' };
    public static readonly GET_SCHEMAS_ID: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/${apiInput.id}` }; //
    public static readonly GET_SCHEMA_DATATYPE: APIDef = { 'method': 'GET', api: () => '/ui/api/schemas/dataTypes' };
    public static readonly GET_SCHEMA_WITH_VERSION: APIDef = { 'method': 'GET', api: () => '/ui/api/schemas/withVersion' };
    public static readonly GET_ETID_DATA: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/getETIdData' };
    public static readonly GET_RECORD_TIMELINE: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/recordTimeline' };
    public static readonly GET_RECORD_COMPARISON: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/recordComparison' };
    public static readonly VALIDATE_RECORDS: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/validateRecords' };
    public static readonly GET_SCHEMAS_LIST_FOR_CHART: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/schemaList?page=1&pageSize=25&startDate=${apiInput.startDate}&endDate=${apiInput.endDate}` };
    public static readonly GET_SCHEMAS_LIST_FOR_PIE_CHART: APIDef = { 'method': 'POST', api: (apiInput: APIInput) => `/ui/api/schemas/getSchemaPieData` };
    public static readonly GET_SCHEMAS_LIST_FOR_ENVELOPE_CHART: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelope/graphData?startDate=${apiInput.startDate}&endDate=${apiInput.endDate}` };
    public static readonly GET_SCHEMAS_DETAILS_FOR_TABLE: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/getSchemaDetails` };
    public static readonly USER_GENERATED_ETIDs: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/userGeneratedETIds' };

    public static readonly GET_ORGANIZATIONS: APIDef = { 'method': 'GET', api: () => '/ui/api/organizations' };
    public static readonly GET_ENVELOPE_HEALTH_DETAILS: APIDef = { 'method': 'GET', api: () => `/ui/api/envelope/healthDetails` };
    public static readonly GET_DATA_FOR_ENVELOPE_CHART: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/graphData` };


    public static readonly GET_BPM_RULES: APIDef = { 'method': 'GET', api: () => `/ui/api/bpm/bpmRules` };
    public static readonly SAVE_BPM_RULE: APIDef = { 'method': 'POST', api: () => `/ui/api/bpm/create` };
    public static readonly GET_FIELDS: APIDef = { 'method': 'GET', api: () => `/ui/api/bpm/fields` };
    public static readonly STORE_FILE: APIDef = { 'method': 'POST', api: () => `/ui/api/bpm/uploadFile` };
    public static readonly GET_FILE_HASH: APIDef = { 'method': 'POST', api: () => `/ui/api/bpm/getFileHash` };


    public static readonly GET_ROLES: APIDef = { 'method': 'GET', api: () => `/ui/api/roles` };
    public static readonly CREATE_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly UPDATE_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };

    public static readonly GET_USERS: APIDef = { 'method': 'POST', api: () => `/ui/api/users` };
    public static readonly CREATE_USER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly UPDATE_USER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly CREATE_USER_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly UPDATE_USER_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly ADD_USER_BLOCKCHAIN_ADDRESS: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly GET_USER_WITHOUT_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/role/getUserWithoutRole` };
    public static readonly DELETE_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly DELETE_USER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly CHANGE_PASSWORD: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static readonly GET_USER_ROLES: APIDef = { 'method': 'POST', api: () => `/ui/api/user/getUserAssignRoles` };
    public static readonly SEARCH_USERS: APIDef = { 'method': 'GET', api: () => `/ui/api/users/searchUsers` };

    public static readonly SETTINGS_USERS: APIDef = { 'method': 'POST', api: () => `/ui/api/setting/settingDetails` };
    public static readonly GET_ASSISTED_AUDIT_PIE_CHART: APIDef = { 'method': 'GET', api: () => `/ui/api/schemas/getAssistedAuditGraph` };
    public static readonly GET_FILES_LIST: APIDef = { 'method': 'GET', api: () => `/ui/api/getFilesList` };
    public static readonly UPLOAD_FILES: APIDef = { 'method': 'POST', api: () => `/ui/api/uploadFile` };
    public static readonly BLOCKCHAIN_INFO: APIDef = { 'method': 'POST', api: () => `/ui/api/cluster/blockChainInfo` };
    public static readonly CREATE_CLUSTER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` }
    public static readonly CREATE_NODE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` }
    public static readonly GET_CLUSTER_LIST: APIDef = { 'method': 'POST', api: () => `/ui/api/cluster/getClusterList` };
    public static readonly GET_NODE_LIST: APIDef = { 'method': 'POST', api: () => `/ui/api/cluster/getNodeList` };
    public static readonly GET_ENVELOPE_COUNT: APIDef = { 'method': 'POST', api: () => `/ui/api/totalenvelopes` };

    public static readonly GET_DATA_SHARING_CONTRACT: APIDef = { 'method': 'GET', api: () => `/ui/api/sharing/getDataSharingContracts` };
    public static readonly UPDATE_DATA_SHARING_CONTRACT: APIDef = { 'method': 'PUT', api: () => `/ui/api/sharing/updateDataSharingStatus` };
    public static readonly GET_CONTRACT_LIST: APIDef = { 'method': 'GET', api: () => `/ui/api/sharing/getContractList` };
    public static readonly CREATE_CONTRACT: APIDef = { 'method': 'POST', api: () => `/ui/api/sharing/createContract` };
    public static readonly CREATE_DATA_SHARING: APIDef = { 'method': 'POST', api: () => `/ui/api/sharing/createDataSharingContract` };
    public static readonly GENERATE_SEED: APIDef = { 'method': 'POST', api: () => `/ui/api/sharing/generateSeed` };

    public static readonly GET_ORGANIZATION_INFO: APIDef = { 'method': 'POST', api: () => '/ui/api/organizations/getOrganizationInfo' }
    public static readonly GET_CURRENT_ORG: APIDef = { 'method': 'POST', api: () => `/ui/api/organizations/getDefaultOrganization` };
    public static readonly CREATE_ORGANIZATION: APIDef = { 'method': 'POST', api: () => `/ui/api/organizations/createOrganization` };
    public static readonly UPDATE_ORGANIZATION: APIDef = { 'method': 'POST', api: () => `/ui/api/organizations/updateOrganization` };
    public static readonly GET_ORGANIZATION_LIST: APIDef = { 'method': 'POST', api: () => `/ui/api/organizations/getOrganizationList` };

}

export interface APIDef {
    method: string;

    api: any;
}

export interface APIInput {
    id?: string;
    ETId?: any;
    pageSize?: number;
    pageNo?: number;
    type?: string;
    fileName?: any;
    handle?: any;
    startDate?: any;
    endDate?: any;
    DH?: any;
    FH?: any;
    EId?: any;
    queryString?: any;
}




