import * as React from 'react';

export function ReadingBook({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="2.3vw" height="2.7vw" viewBox="0 0 34 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.595 25.782a3.913 3.913 0 0 0-3.126-3.828v-4.22a1.172 1.172 0 0 0-1.583-1.097l-2.237.84a13.663 13.663 0 0 0-5.21-3.384 7.806 7.806 0 0 0 3.17-6.28C24.61 3.505 21.106 0 16.798 0S8.985 3.505 8.985 7.813a7.806 7.806 0 0 0 3.17 6.28 13.664 13.664 0 0 0-5.21 3.383l-2.237-.839a1.171 1.171 0 0 0-1.583 1.098v4.22A3.913 3.913 0 0 0 0 25.781a3.913 3.913 0 0 0 3.125 3.828v4.531c0 .489.303.926.76 1.098l12.5 4.687c.264.099.56.099.824 0l12.5-4.687c.457-.172.76-.61.76-1.098V29.61a3.913 3.913 0 0 0 3.126-3.828zM11.328 7.812a5.475 5.475 0 0 1 5.47-5.468 5.475 5.475 0 0 1 5.468 5.469 5.475 5.475 0 0 1-5.469 5.469 5.475 5.475 0 0 1-5.469-5.47zm5.47 7.813c2.745 0 5.367.993 7.413 2.765l-7.414 2.78-7.414-2.78a11.31 11.31 0 0 1 7.414-2.765zM2.343 25.782c0-.862.7-1.563 1.562-1.563h.782v3.125h-.782a1.564 1.564 0 0 1-1.562-1.562zm3.125 3.867a1.957 1.957 0 0 0 1.562-1.914v-3.906c0-.944-.672-1.733-1.562-1.914v-2.49l10.156 3.81v13.903L5.47 33.329v-3.68zm12.5 7.489V23.235l10.157-3.81v2.49c-.89.181-1.563.97-1.563 1.914v3.906c0 .943.672 1.732 1.563 1.914v3.68l-10.157 3.809zm11.72-9.794h-.782V24.22h.781c.862 0 1.563.701 1.563 1.563 0 .861-.701 1.562-1.563 1.562z" fill="#0078D4" fill-rule="nonzero" />
        </svg>

    );
}
