import React from "react";
import { SelectBox as DevextremeSelectBox, ISelectBoxOptions } from 'devextreme-react/select-box';
import { DataSourceLike } from "devextreme/data/data_source";
import { CollectionWidgetItem } from "devextreme/ui/collection/ui.collection_widget.base";

export interface ISelectBoxProps extends ISelectBoxOptions {
    /**
    * Specifies the UI component&apos;s width.
    */
    width?: number | string | (() => number | string);
    height?: number;
    className?: string;
    text?: string;
    displayExpr?: string | ((item: any) => string);
    valueExpr?: string;
    placeholder?: string;
    style?: any;
    name?: any;
    value?: any;
    onValueChange?: () => void
    backgroundColor?: string;
    /**
         * Binds the UI component to data.
         */
    dataSource?: DataSourceLike<CollectionWidgetItem | any> | null;

    /**
    * Specifies whether or not the UI component changes its state when interacting with a user.
    */
    activeStateEnabled?: boolean;

    /**
   * Specifies whether the UI component can be focused using keyboard navigation.
   */
    focusStateEnabled?: boolean;
}

function SelectBox({ className, text, displayExpr, valueExpr, dataSource, onValueChange, placeholder, style, name, value, backgroundColor, activeStateEnabled=false, focusStateEnabled=false, ...props }: ISelectBoxProps) {
    return (
        <DevextremeSelectBox
            displayExpr={displayExpr}
            valueExpr={valueExpr}
            dataSource={dataSource}
            name={name}
            value={value}
            placeholder={placeholder}
            activeStateEnabled={activeStateEnabled}
            focusStateEnabled={focusStateEnabled}
            className={className}
            style={{ ...style }}
            {...props}
        />
    )
}

export default SelectBox; 