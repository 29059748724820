import React, { useEffect, useRef } from 'react';

import TextBox, { Button as TextBoxButton } from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import './FormTextbox.scss';

type TextboxProps = {
  value: string,
  label?: string,
  isEditing: boolean,
  onValueChange: (data) => void,
  mask?: string,
  icon?: string,
  validationRules?: any[],
  onValueValidated?: (e: any) => void;
  disabled?: boolean;
}

export const FormTextbox = ({ value, label, isEditing, onValueChange, mask = '', icon, validationRules, onValueValidated, disabled }: React.PropsWithChildren<TextboxProps>) => {
  const textBoxRef = useRef(null);
  const autoComplete = label === 'Last Name' || label === 'Password' ? 'off' : 'on';


  useEffect(() => {
    if (textBoxRef.current) {
      textBoxRef.current.instance.option('inputAttr', { autoComplete: 'off' });
    }
  }, []);
  return (
    <div className="form-textbox-container">
      {label && <label className="form-textbox-label">{label}</label>}
      <div className="form-textbox-wrapper">
        <TextBox
          ref={textBoxRef}
          value={value}
          mask={mask}
          readOnly={isEditing}
          elementAttr={{ class: 'form-editor' }}
          inputAttr={{ class: 'form-editor-input' }}
          stylingMode='outlined'
          onValueChange={onValueChange}
          valueChangeEvent='keyup'
          disabled={disabled}

        >
          {icon &&
            <TextBoxButton
              name='icon'
              location='before'
              options={{
                icon: icon,
                stylingMode: 'text',
                elementAttr: { class: 'form-editor-icon' }
              }} />
          }
          {validationRules && (
            <Validator onValidated={onValueValidated}>
              {validationRules.map((rule, index) => (
                <RequiredRule key={index} {...rule} />
              ))}
            </Validator>
          )}
        </TextBox>
      </div>
    </div>
  );
};
