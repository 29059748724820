import React, { useCallback, useMemo } from 'react';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getEnvelopeList } from '../../redux/actions/envelopeActions';
import { toggleLoader } from '../../redux/actions/loader.action';

// MUI
import { Tooltip, IconButton, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

// Services
import Toaster from '../../services/toaster.services';
import RestApiService from '../../services/http-services';

// Packages
import moment from 'moment';
import * as _ from 'lodash';

// Api
import { ApiEndPoints } from '../../models/api-endpoint';

// Utils
import { changeEpochToDate } from '../../utils/utilityFunctions';

// SVG Images
import { EyeOopen } from '../../../assets/svg';

// Common Component
import MultiSelectTag from '../../shared/components/commonui/MultiSelect/MultiSelectTag';
import Button from '../../shared/components/commonui/Button';
import ModalBox from '../../shared/components/commonui/Modal/ModalBox';
import Buttons from '../../shared/components/commonui/Button';
import DynamicTableList from '../../shared/components/commonui/DynamicTableList/DynamicTableList';

// Devextreme
import { ToolbarItem } from 'devextreme-react/popup';

// Scss
import "../../../assets/themes/default/share.scss";
import "./envelopeList.scss";


const EnvelopeListComponent: React.FC<Props> = (props: Props) => {
  const [envelopeData, setEnvelopeData] = React.useState(false);
  const [activeEncryptedHash, setActiveEncryptedHash] = React.useState<any>();
  const [activeDcryptedHash, setActiveDcryptedHash] = React.useState<any>();
  const [activeHashDH, setActiveHashDH] = React.useState<any>();
  const [showDecrypt, setShowDecrypt] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [direction, setDirection] = React.useState('desc');
  const [sortedBy, setSortedBy] = React.useState('UpdatedAt');
  const [search, setSearch] = React.useState<any>();
  const [selectedEnvelope, setSelectedEnvelope] = React.useState<any>();
  const [etIdsList, setEtidsList] = React.useState<any>();
  const [svList, setSvList] = React.useState<any>([]);
  const [etiSelected, setEtiSelect] = React.useState<any>();
  const [divSelected, setDvSelect] = React.useState<any>();
  const [totalReq, setTotalReq] = React.useState(true);
  const [eId, setEId] = React.useState<any>();
  const [eS, setES] = React.useState<any>();
  const [etidDropDownValue, setEtidDropDownValue] = React.useState<Array<any>>([]);
  const [svListValue, setSvListValue] = React.useState<Array<any>>([]);
  const [envelopeHashData, setEnvelopeHashData] = React.useState<any>();
  const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'EId', 'order': 'desc', 'isActive': false }, { 'orderBy': 'ETId', 'order': 'desc', 'isActive': false }, { 'orderBy': 'CreatedAt', 'order': 'desc', 'isActive': false }, { 'orderBy': 'UpdatedAt', 'order': 'desc', 'isActive': false }])


  const getConvertFunctionByColumn = useCallback((value: string) => {
    const date = changeEpochToDate(parseInt(value, 10));
    const stillUtc = moment.utc(date).toDate();
    return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss');
  }, [])

  React.useEffect(() => {
    getEtidsList({});
  }, []);


  React.useEffect(() => {

    if (pageSize && currentPageNo && direction && sortedBy) {
      getEnvelopeList({});
    }

  }, [pageSize, currentPageNo, direction, sortedBy]);


  const getEnvelopeList = (query) => {

    props.toggleLoader(true);
    let baseQuery = "/ui/api/envelopes?pageNo=" + currentPageNo + "&pageSize=" + pageSize + "&totalReq=" + totalReq + "&order=" + direction + "&orderBy=" + sortedBy;
    let firstQuery = ""; let secondQuery = "";
    if (etiSelected && etiSelected.length > 0) {
      let etiQuery = "&etid=" + etiSelected.join("&etid=");
      firstQuery = etiQuery;
    }
    if (divSelected && divSelected.length > 0) {
      let dvQuery = "&sv=" + divSelected.join("&sv=");
      secondQuery = dvQuery;
    }

    RestApiService.getRequest(baseQuery + firstQuery + secondQuery, query, {
      ETId: 1000,
      SV: 1
    }).then((res: any) => {
      props.toggleLoader(false);
      setEnvelopeData(res.data.data);
      setTotalRecord(res.data.totalRecord);
      if (parseInt(res.data.totalRecord) % pageSize === 0) {
        setTotalPage((parseInt(res.data.totalRecord) / pageSize));
      }
      else {
        setTotalPage((Math.floor(parseInt(res.data.totalRecord) / pageSize)) + 1);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const openHashesModel = (DH: any, decryptType?: any) => {
    setActiveHashDH(DH);
    let query;
    if (decryptType === 'EID') {
      query = { DH: DH, EId: eId };
    }
    else {
      query = { DH: DH };
    }
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.VIEW_DATAHASH, query, null, null, {
      ETId: 1000,
      SV: 1
    }).then((res: any) => {
      props.toggleLoader(false);
      if (res.data.success) {
        setActiveEncryptedHash(res.data.data);
        setOpen1(true);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });

  }


  const dcryptHash = (DataHash: any) => {
    let query = { DH: DataHash, EId: eId };
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.DECRYPT_DATAHASH, query, null, null, {
      ETId: 1000,
      SV: 1

    }).then((res: any) => {
      props.toggleLoader(false);
      if (res.data.success) {
        setActiveDcryptedHash(res.data.data);
        setShowDecrypt(true);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }



  const onSearchData = (searchData: any) => {
    setSearch(searchData);
  }
  const onSortingData = useCallback((order, orderBy, data) => {
    if (orderBy && order && data) {
      setSortedBy(orderBy);
      setDirection(order);
      setOrderIndexedFields(data);
    }
  }, [])

  const handleAction = useCallback((value: any, action: string) => {
    if (action == 'view') {
      setSelectedEnvelope(value);
      setEId(value.EId);
      setES(value.ES);
      setOpen(true);
    }
  }, []);

  const goToPreviousPage = useCallback((page: any) => {
    setCurrentPageNo(page);
  }, [])

  const goToNextPage = useCallback((page: any) => {
    setCurrentPageNo(page);
  }, []);

  const isLessthanTotalElement = useCallback(() => {
    if ((currentPageNo * pageSize) === (totalPage - 1))
      return true;
    else
      return false;
  }, [currentPageNo, pageSize, totalPage]);

  const changePageSize = useCallback((value: any) => {
    setPageSize(value);
    setCurrentPageNo(1);
  }, []);

  const changeDirection = (value: any) => {

  }


  const handleClose = useCallback((text) => {
    if (text === 'env') {
      setOpen(false);
    }
    else {
      setOpen1(false);
      setShowDecrypt(false);
    }
  }, []);

  const getEtidsList = (query) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_ETIDs, null, null, query, {
      ETId: 50,
      SV: 1
    }).then((res: any) => {
      props.toggleLoader(false);
      res.data.data.unshift({ ETId: 50, TableName: "schemas" });
      let etidListValue = [];
      res.data.data.map((item: any, index: any) => {
        etidListValue.push({ value: String(item.ETId), label: `${item.TableName} (${item.ETId})` })
      });
      setEtidsList(etidListValue);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const getDvByEtidsHandle = (etids: any, isEtid: boolean) => {
    setCurrentPageNo(1);
    let query = "";
    if (isEtid == true) {
      setEtiSelect(etids);
      query = "?ETIds=" + etids.join("&ETIds=");
    }
    else {
      setEtiSelect(null);
    }
    RestApiService.invoke(ApiEndPoints.GET_VERSIONS, { queryString: query }, null, null, null).then((res: any) => {
      let dvListValue = [];
      let objArray = []
      res.data.data.forEach((item: any, index: any) => {
        item.versions.map((vers: any, index: any) => {
          objArray.push({ value: String(vers), label: String(vers) })
          dvListValue = _.uniqWith(objArray, _.isEqual);
        });
      });
      setSvList(dvListValue);
    }).catch(error => {
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const getDvValueHandler = (dvs: any) => {
    setCurrentPageNo(1);
    if (dvs) {
      setDvSelect(dvs);
    } else {
      setDvSelect(null);
    }

  }

  const getFilter = () => {
    setCurrentPageNo(1);
    getEnvelopeList({});
  }

  const ViewIconCellRenderer = useCallback((value: any): React.ReactNode => {
    return (
      <div className='action-eye'>

        <Tooltip title="View" placement="right">
          <span><EyeOopen onClick={(e: any) => handleAction(value?.data, 'view')} /></span>
        </Tooltip>
      </div>
    )
  }, [handleAction]);

  const customActionColumnFormatters = [
    ViewIconCellRenderer
  ]

  const onEtidSelectionChanged = useCallback((e: any) => {
    setEtidDropDownValue(e.value);
    getDvByEtidsHandle(e.value, e.value != null)
  }, []);

  const onSvSelectionChanged = useCallback((e: any) => {
    setSvListValue(e.value);
    getDvValueHandler(e.value);
  }, []);


  const tableColumnList = useMemo(() => [
    { name: "Envelope Id", db: "EId", sort: true },
    { name: "ETId", db: "ETId", sort: true },
    { name: "SV", db: "SV" },
    { name: "Envelope Status", db: "ES" },
    { name: "Created At", db: "CreatedAt", sort: true, format: getConvertFunctionByColumn },
    { name: "Updated At", db: "UpdatedAt", sort: true, format: getConvertFunctionByColumn }
  ], [getConvertFunctionByColumn]);


  return (
    <div className="Innerheading">
      <div className="Innerdetails dx-theme-background-color">
        <div className="envelope-filter">
          <div className="filter-container">
            <div className="grid-item xs-2 md-1">
              <span className='title'>ETId</span>
            </div>
            <div className="grid-item xs-10 md-4">
              <MultiSelectTag
                stylingMode='outlined'
                items={etIdsList}
                displayExpr="label"
                valueExpr="value"
                showClearButton={true}
                value={etidDropDownValue}
                onValueChanged={onEtidSelectionChanged}
                hideSelectedItems
                searchEnabled
                className='basic-multi-select-tag'

              />
            </div>
            <div className="grid-item xs-2 md-1 spacing-sv">
              <span className='title'>SV</span>
            </div>
            <div className="grid-item xs-10 md-4">
              <MultiSelectTag
                value={svListValue}
                stylingMode='outlined'
                dataSource={svList}
                displayExpr="label"
                valueExpr="value"
                onValueChanged={onSvSelectionChanged}
                hideSelectedItems
                className='basic-multi-select-tag'
              />
            </div>
            <div className="grid-item xs-12 md-2 filter-section">
              <Button type='default' color='white' text='Filter' onClick={() => getFilter()} />
            </div>
          </div>


        </div>
        <div className="grid">
          <DynamicTableList
            dataSource={envelopeData}
            columnList={tableColumnList}
            onSortingData={onSortingData}
            orderIndexedFields={orderIndexedFields}
            fromComponent={'EnvelopeListComponent'}
            columnAutoWidth={true}
            showPagination
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            changePageSize={changePageSize}
            pageSize={pageSize}
            currentPageNo={currentPageNo}
            totalPage={totalPage}
            totalRecord={totalRecord}
            isLessthanTotalElement={isLessthanTotalElement()}
            showCustomActions
            customActionColumnFormatters={customActionColumnFormatters}

          />
        </div>
      </div>

      {selectedEnvelope && <ModalBox
        visible={open}
        onHiding={() => handleClose('env')}
        showCloseButton={true}
        dragEnabled={false}
        showTitle={true}
        wrapperAttr={{ class: 'envelope-list-popup' }}
        title='Envelope Detail'
        width={800}
        height={'auto'}
        hideOnOutsideClick
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><strong>ES</strong></TableCell>
                <TableCell>{selectedEnvelope.ES != null ? selectedEnvelope.ES : ''}</TableCell>

              </TableRow>
              <TableRow>
                <TableCell><strong>Envelope ID</strong></TableCell>
                <TableCell>{selectedEnvelope.EId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>ETId</strong></TableCell>
                <TableCell>{selectedEnvelope.ETId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>TS</strong></TableCell>
                <TableCell>{moment.utc(selectedEnvelope.TS).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>SV</strong></TableCell>
                <TableCell>{selectedEnvelope.SV}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>DH</strong></TableCell>
                {selectedEnvelope.ES > 40 && <TableCell>{selectedEnvelope.DH} <IconButton onClick={() => openHashesModel(selectedEnvelope.DH)}><Visibility /></IconButton></TableCell>}
                {selectedEnvelope.ES <= 10 && <TableCell> {JSON.stringify(selectedEnvelope.Data, undefined, 4)}</TableCell>}
                {selectedEnvelope.ES > 10 && selectedEnvelope.ES <= 40 && <TableCell>{selectedEnvelope.DH} <IconButton onClick={() => openHashesModel(selectedEnvelope.DH, 'EID')}><Visibility /></IconButton></TableCell>}
              </TableRow>
              <TableRow>
                <TableCell><strong>Created At</strong></TableCell>
                <TableCell>{moment.utc(selectedEnvelope.CreatedAt).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Updated At</strong></TableCell>
                <TableCell>{moment.utc(selectedEnvelope.UpdatedAt).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Data</strong></TableCell>
                <TableCell>
                  {
                    selectedEnvelope.ES > 40 && selectedEnvelope.Data &&
                    <table className="statusinfo">
                      <tbody>
                        {
                          selectedEnvelope.Data.map((item: any) => {
                            return (
                              <React.Fragment key={item._id}>
                                <TableRow>
                                  <TableCell><strong>Status Info</strong></TableCell>
                                  <TableCell>{(item.StatusInfo && item.StatusInfo.label) ? item.StatusInfo.label : ''}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><strong>UID</strong></TableCell>
                                  <TableCell>{item.UID}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><strong>Deleted</strong></TableCell>
                                  <TableCell>{item.Deleted}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><strong>Blockchain Info</strong></TableCell>
                                  <TableCell>{(item.BlockChainInfo && item.BlockChainInfo.label) ? item.BlockChainInfo.label : ''}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><strong>Blockchain Entity</strong></TableCell>
                                  <TableCell>{item.BlockChainEntity}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><strong>Item Type</strong></TableCell>
                                  <TableCell>{item.ItemType}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><strong>Item</strong></TableCell>
                                  <TableCell>{item.Item}</TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ModalBox>}

      <ModalBox
        visible={open1}
        onHiding={handleClose}
        showCloseButton={true}
        dragEnabled={false}
        showTitle={true}
        title={activeHashDH}
        wrapperAttr={{ class: 'envelope-list-encrypt-popup' }}
        width={800}
        height={'auto'}
        hideOnOutsideClick
      >
        <div className='dx-field'>
          <div className='dx-field-label'><strong>Encrypted DataHash</strong></div>
          <div className="activeEncryptedHash dx-field-value">{activeEncryptedHash}</div>
        </div>

        {showDecrypt && <div className='dx-field'>
          <div className='dx-field-label'><strong>Decrypted DataHash</strong></div>
          <div className="decrypted-text dx-field-value">
            {JSON.stringify(activeDcryptedHash, undefined, 4)}
          </div>
        </div>}


        {!showDecrypt && eS > 40 && <ToolbarItem
          toolbar='bottom'
          location='after'
        >
          <div className={`form-popup-buttons-container`}>
            <Buttons text='Decrypt' type='default' onClick={() => dcryptHash(activeHashDH)} />
          </div>
        </ToolbarItem>}


        {!showDecrypt && eS <= 40 &&
          <ToolbarItem
            toolbar='bottom'
            location='after'
          >
            <div className={`form-popup-buttons-container`}>
              <Buttons text='Decrypt' type='default' onClick={() => dcryptHash(activeHashDH)} />
            </div>
          </ToolbarItem>}
      </ModalBox>
    </div>
  );
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader, getEnvelopeList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopeListComponent);


interface Props {
  toggleLoader: any;

}