import React from "react";
import TagBox, { ITagBoxOptions } from 'devextreme-react/tag-box';

interface IPropsMultiSelectTagWithLabel extends ITagBoxOptions {
    inputLabel?: string;
}

function MultiSelectTagWithLabel({ inputLabel, ...props }: IPropsMultiSelectTagWithLabel): React.ReactElement {
    return (
        <div className='dx-field'>
            <div className="dx-field-label">
                <strong>{inputLabel}</strong>
            </div>
            <div className='dx-field-value'>
                <TagBox
                    {...props}
                />
            </div>
        </div>
    )
}

export default React.memo(MultiSelectTagWithLabel);