import React, { useEffect } from 'react';
import './Login.scss';
import "../../../assets/themes/default/share.scss";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, AppBar, Toolbar, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { ApiEndPoints } from '../../models/api-endpoint';
import RestApiService from "../../services/http-services";
import { AuthService } from '../../core/interceptors/auth.interceptor'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toaster from '../../services/toaster.services';
import { connect } from 'react-redux';
import { setCurrentUser, changePassword } from '../../redux/actions/authActions';
import { toggleLoader } from '../../redux/actions/loader.action';
import axios from 'axios';
import { CookieStorage } from 'cookie-storage';
import { ChangeProfilePasswordForm } from '../../shared/components/change-password-form/ChangePasswordForm';

const cookies = new CookieStorage();




const LoginComponent = (props: any) => {

  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isFirstUser, setIsFirstUser] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState('');
  // const [rememberMe, setRememberMe] = React.useState(true);
  // const [newPassword, setNewPassword] = React.useState<any>();
  const authService = new AuthService();

  useEffect(() => {
    // if (!email && email != null && email != "" && email != undefined) {
    //   setEmail(email);
    // }

    // if (!password && password != null && password != "" && password != undefined) {
    //   setPassword(password);
    // }

    // if (!email || email == null || email == "" || email == undefined || !password || password == null || password == "" || password == undefined)
    //   setRememberMe(false);
    // else
    //   setRememberMe(true);

    if (props.auth.isAuthenticated && props.auth.user.resetPasswordCount > 0) {
      // props.history.push({
      //   pathname: `/page/dashboard`
      // });
      props.handleAction(props, '/page/dashboard');
    }

  }, []);

  useEffect(() => {

    if (props.auth.isAuthenticated) {
      setIsFirstUser(props.auth.user.resetPasswordCount === 0);
    }

    if ((props.auth.user.resetPasswordCount > 0)) {
      props.handleAction(props, '/page/dashboard');
      // props.history.push({
      //   pathname: `/page/dashboard`
      // });
    }

  }, [props.auth]);


  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRememberMe(event.target.checked);
  // };


  const login = (userName: any, password: any) => {

    authService.loginAndSetToken(userName, password).then(res => {
      if (res.data && res.data.data) {
        cookies.setItem("firstname", res.data.data.user.firstName);
        cookies.setItem("lastname", res.data.data.user.lastName);
        cookies.setItem("usershortname", res.data.data.user.firstName.charAt(0) + '' + res.data.data.user.lastName.charAt(0));
        props.setCurrentUser(res.data.data);
      }
      else {
        props.setCurrentUser(res);
      }
      // if (rememberMe == true) {
      //   cookies.setItem("epicenter_username", email, { path: '/' });
      //   cookies.setItem("epicenter_password", password, { path: '/' });
      // }
      // else {
      //   cookies.setItem("epicenter_username", "", { path: '/' });
      //   cookies.setItem("epicenter_password", "", { path: '/' });
      // }

    }).catch(error => {
      toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {

          if (error.response.status !== 422)
            Toaster.errorToaster(response.message);
          if (error.response.status === 422) {
            const responseMessage = response.message.split(' ');
            const errorMessage = response.message.split('for');
            const time = secondsToHms(responseMessage[4]);
            setAuthMessage(errorMessage[0] + 'for ' + time);
            Toaster.errorToaster(errorMessage[0] + 'for ' + time);
            const milesecond = parseInt((responseMessage[4] * 1000).toFixed(0));
            setTimeout(() => {
              setAuthMessage('');
            }, milesecond);

          }
        });
      }
    });
  }

  const resetPassword = async (userData: any) => {
    toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.RESET_PASSWORD, null, userData, null, {
      ETId: 10,
      SV: 1,
    }).then((response: any) => {

      toggleLoader(false);
      Toaster.successToaster("passwordChange", "Password Changed Successfully. Please Login with New password");
      if (response) {
        delete axios.defaults.headers.common['Authorization'];
        //   cookies.clear();
        setIsFirstUser(false);
      }
    }).catch(error => {
      toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });


  }


  const onSubmit = (event: any) => {
    if (event && event.preventDefault) {
      event.preventDefault();
      login(userName, password);
    } else {
      resetPassword(event);
    }
  }


  const submit = (event: any) => {
    if (event.keyCode === 13) {
      onSubmit(event);
    }
  }
  function secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);
    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  return (
    <div className="content-box">
      {/* <div className="login-header">
        <AppBar
          position="fixed"
          className={'appBar-close'}
        >
          <Toolbar className={'toolbarStyle-close'}>
            <span className="logo">
              <img src={require('../../../assets/images/logo.png')} alt="The Platform" title={"The Platform"} />
            </span>
            <Typography variant="h6" noWrap>
              The Platform
                   </Typography>
          </Toolbar>
        </AppBar>
      </div> */}
      <div className="prelogin-box">

        <form action="post" className="form">

          <div className="loginimg">
            <img src={require('../../../assets/images/output-onlinepngtools.png')} alt="logoimage"></img>
          </div>
          <h1 className="form-heading" color="primary">Login</h1>
          <div className="form-group">
            <TextField
              id="standard-uncontrolled"
              label="Username/Email"
              margin="normal"
              onChange={(text) => setUserName(text.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />	</div>
          <div className="form-group password-field">
            <TextField
              id="standard-password-input"
              label="Password"
              type="password"
              margin="normal"
              onChange={(text) => setPassword(text.target.value)}
              onKeyDown={(event) => submit(event)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <FormControlLabel control={
                <Checkbox
                  color="primary"
                  checked={rememberMe}
                  onChange={handleChange}
                />
              } label="Remember me" />
            </Grid>
            <Grid item>
              <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary">Forgot password ?</Button>
            </Grid>
          </Grid> */}
          {
            isFirstUser &&
            <ChangeProfilePasswordForm
              visible={isFirstUser}
              onSubmitForm={(resp: any) => onSubmit(resp)}
              setVisible={() => setIsFirstUser(false)} />
          }
          <div className="login-button">
            <Button className="submit-button" variant="contained" color="primary" onClick={(res: any) => onSubmit(res)}>
              <ExitToAppIcon />  LOGIN
            </Button>
            {/* <Typography className="sign-up-cover" >
              <Link href="#" to={'/signup'} className="sign-up-link" >
                Don't have an account? Register here
           </Link>
            </Typography> */}
          </div>
          <div className="login-lock">
            <span>{authMessage}</span>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});
export default connect(mapStateToProps, { setCurrentUser, changePassword })(LoginComponent);
interface DynamicListProps {
  handleAction?: any;
  pathName?: string;
}
