import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApiEndPoints } from '../../../../models/api-endpoint';
import Toaster from '../../../../services/toaster.services';
import { toggleLoader } from '../../../../redux/actions/loader.action';
import RestApiService from '../../../../services/http-services';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import FileDownloadService from '../../../../services/file-download-services';

import "./ClusterCompare.scss"
import AppConstants from '../../../../core/constants/appConstants';
import { Delete } from '@material-ui/icons';
import DynamicTableList from '../../../../shared/components/commonui/DynamicTableList/DynamicTableList';
import NewSubHeaderComponent from '../../../../shared/components/new-sub-header/new-sub-header';

const ClusterComparison = (props: ClusterComparisonProps) => {

    const [comparisonData, setComparisonData] = useState<Array<any>>([]);
    const [openPopUp, setOpenPopUp] = useState<boolean>(false);
    const [nodesCompareList, setNodesCompareList] = useState<Array<any> | undefined>(props.location?.state?.clusterCompareList);

    let history = useHistory();
    let fileDownloadService = new FileDownloadService();
    const [nodeETId, nodeSV] = [
        AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
        AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV,
        AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.ETId,
        AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.SV]

    useEffect(() => {
        if (nodesCompareList?.length > 0) {
            nodesCompareList.forEach((cluster: any) => {
                getNodesInfo(cluster, cluster.NodeUrl);
            })
        }
    }, [nodesCompareList]);

    function createComparision(cluster, resData, nodetotalRecord) {
        return {
            ...cluster,
            ...resData,
            isDeleted: true,
            envelopeCount: nodetotalRecord
        }
    }

    const getNodesInfo = (cluster: any, url: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.BLOCKCHAIN_INFO, null, { clusterUrl: url }, null, null).then(res => {
            RestApiService.invoke(ApiEndPoints.GET_ENVELOPE_COUNT, null, { url: url }, null, {
                ETId: nodeETId,
                SV: nodeSV
            }).then(nodeResponse => {
                // setComparisonData((prev) => [...prev, { ...cluster, ...res.data.data, isDeleted: true, envelopeCount: nodeResponse.data.totalRecord }])
                const nodeCompresion = createComparision(cluster, res.data.data, nodeResponse.data.totalRecord);
                setComparisonData((prev) => [...prev,nodeCompresion ]);
                props.toggleLoader(false);

            }).catch(error => {
                props.toggleLoader(false);
                if (error.message === 'Network Error') {
                    Toaster.errorToasterWithId("getNodesInfo", error.message)
                }
                if (error?.response?.data?.errors?.length > 0) {
                    error.response.data.errors.forEach((response) => {
                        Toaster.errorToasterWithId("getNodesInfo", `${url} down`);
                    });
                }
            })
        }).catch(error => {
            setComparisonData((prev) => [...prev, {
                ...cluster,
                blockChainType: "N.A",
                blockChainHeight: "N.A",
                blockChainMempool: 'N.A',
                blockChainSize: "N.A",
                blockChainName: "N.A",
                envelopeCount: "N.A",
                isDeleted: false,
            }]);
            props.toggleLoader(false);
            if (error.message === 'Network Error') {
                Toaster.errorToaster(error.message)
            }
            if (error?.response?.data?.errors?.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToasterWithId("getNodesInfo", `${url} is down`);
                });
            }
        })
    }

    const downloadxls = (data: Array<any>) => {
        fileDownloadService.arrayOfObjectToXLSXFileDownload(comparisonData, "Node Comparison Result.xlsx");
    }

    const handleAction = (item: any, action: string) => {
        if (action === 'delete') {
            const elementDeleted = comparisonData.filter((compareObject) => compareObject.UID !== item.UID);
            setComparisonData(elementDeleted);
        }
        else if (action === 'edit') {
            setOpenPopUp(true)
        }
    }

    const renderStatus = useCallback((item: any) => {
        return (
            <div className='statusData'>
                {
                    item.data.isDeleted ? <span className='up status-box-style'>Up</span> : <span className='down status-box-style'>Down</span>
                }
            </div>
        )
    }, []);

    const ViewIconCellRenderer = useCallback((value: any): React.ReactNode => {
        return (
            <div className='role-icon'>
                <Tooltip title="Delete" placement="right">
                    < Delete color="primary" onClick={(e: any) => handleAction(value?.data, 'delete')} />
                </Tooltip>
            </div>
        )
    }, [handleAction]);

    const customActionColumnFormatters = [
        ViewIconCellRenderer
    ]

    const tableColumnList = useMemo(() => [
        { name: "Nodes", db: "NodeName", sort: false },
        { name: "BlockChain Type", db: "blockChainType", sort: false },
        { name: "Envelope Count", db: "envelopeCount", sort: false },
        { name: "Blockchain Height", db: "blockChainHeight", sort: false },
        { name: "Node Url", db: "NodeUrl" },
        { name: "Status", db: "isDeleted", cellRender: renderStatus }
    ], [renderStatus]);

    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='setting-page cluster-node'>
                        <div className="Innerheading cluster-Compare">
                            <div className='view-wrapper view-wrapper-user-details dx-theme-background-color'>
                                <NewSubHeaderComponent
                                    headingText={'Comparison Result'}
                                    searchLabel={'Nodes'}
                                    btnText={"Export Result"}
                                    isbackEnabled
                                    onBackButtonClick={() => history.goBack()}
                                    onButtonClick={downloadxls}
                                    showBtn
                                />
                                <div className="dx-theme-background-color Innerdetails dx-card">

                                    <div className="grid grid-status">
                                        {/* <DynamicList
                                        dataList={comparisonData}
                                        columnList={[{ name: "Nodes", db: "NodeName", sort: true }, { name: "BlockChain Type", db: "blockChainType", sort: true }, { name: "Envelope Count", db: "envelopeCount", sort: true }, { name: "Blockchain Height", db: "blockChainHeight", sort: true }, { name: "Node Url", db: "NodeUrl" }, { name: "Status", db: "isDeleted", renderer: renderStatus }]}
                                        primaryColumn={"id"}
                                        orderIndexedFields={false}
                                        onSortingData={false}
                                        showEdit={true}
                                        showAdd={false}
                                        showDelete={true}
                                        showView={false}
                                        showActions={true}
                                        handleAction={(item: any, action: string) => handleAction(item, action)}
                                        showChangePassword={false}
                                        isOrderIconChange={true}
                                        keysToHide={['']}
                                        fromComponent={'SettingComponent'}
                                        goToPreviousPage={false}
                                        goToNextPage={false}
                                        changePageSize={false}
                                        pageSize={pageSize}
                                        currentPageNo={currentPageNo}
                                        totalPage={totalPage}
                                        totalRecord={totalRecord}
                                        isLessthanTotalElement={false}
                                        SNo={false}
                                        showPagination={false}
                                    /> */}

                                        {/* <DynamicTable
                                        dataSource={comparisonData}
                                        columnList={tableColumnList}
                                        primaryColumn={"id"}
                                        fromComponent={'SettingComponent'}
                                        showPagination={false}
                                        showCustomActions
                                        customActionColumnFormatters={customActionColumnFormatters}
                                        showBorders
                                        showColumnLines
                                        showRowLines
                                    /> */}

                                        <DynamicTableList
                                            dataSource={comparisonData}
                                            columnList={tableColumnList}
                                            fromComponent={'SettingComponent'}
                                            showCustomActions
                                            customActionColumnFormatters={customActionColumnFormatters}
                                        />
                                    </div>
                                    {/* <div className='comparison-result-btn'>
                                    <Tooltips
                                        target='#comparison-result'
                                        showEvent="dxhoverstart"
                                        hideEvent="dxhoverend"
                                        position={'bottom'}
                                        hideOnOutsideClick={true}
                                        contentRender={addCompressionList}
                                    />
                                    <Buttons
                                        id='comparison-result'
                                        className={"btn-primarys"}
                                        type='default'
                                        text={'Add to comparison list'}
                                        color='#fff'
                                        onClick={() => { setOpenPopUp(true) }}
                                    />
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Dialog open={openPopUp} onClose={() => setOpenPopUp(false)}>
                <DialogTitle disableTypography >
                    <Typography variant="h6">Add nodes to comparison table</Typography>
                    <IconButton aria-label="close" onClick={() => setOpenPopUp(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='viewCredential'>
                    <ClusterCompareMultiSelect dialogHandler={(val: any) => setOpenPopUp(false)} compareCallBack={setNodesCompareList} fromComponent='ComparisonTable' history={props.location} />
                </DialogContent>
            </Dialog> */}

            {/* <ModalBox
                visible={openPopUp}
                onHiding={() => setOpenPopUp(false)}
                showCloseButton={true}
                dragEnabled={false}
                showTitle={true}
                title='Add nodes to comparison table'
                contentRender={() => <ClusterCompareMultiSelect dialogHandler={(val: any) => setOpenPopUp(false)} compareCallBack={setNodesCompareList} fromComponent='ComparisonTable' history={props.location} />}
                height={'auto'}
                width={'40%'}
                hideOnOutsideClick
            /> */}
        </div>
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClusterComparison);


interface ClusterComparisonProps {
    toggleLoader: any;
    location: any;
}

ClusterComparison.defaultProps = {

}
