import {
  PROFILE_GET_PROFILE,
  PROFILE_GET_PROFILES,
  PROFILE_LOADING,
  PROFILE_CLEAR_CURRENT_PROFILE
} from '../types/profileTypes';

const initialState = {
  profile: null,
  profiles: null,
  loading: false
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true
      };
    case PROFILE_GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loading: false
      };
    case PROFILE_GET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
        loading: false
      };
    case PROFILE_CLEAR_CURRENT_PROFILE:
      return {
        ...state,
        profile: null
      };
    default:
      return state;
  }
}
