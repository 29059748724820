import * as React from 'react';

export function GroupAddIcons({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g fill="none" fill-rule="evenodd">
                <rect fill="#0078D4" width="30" height="30" rx="5" />
                <g transform="translate(7 8)" fill="#FFF" fill-rule="nonzero">
                    <circle cx="8" cy="2.719" r="2.719" />
                    <circle cx="13.5" cy="3.719" r="1.719" />
                    <circle cx="2.5" cy="3.719" r="1.719" />
                    <path d="M4.193 6.98c-.676-.553-1.289-.48-2.071-.48C.952 6.5 0 7.446 0 8.61v3.412c0 .505.412.915.92.915 2.187 0 1.924.04 1.924-.094 0-2.418-.287-4.192 1.35-5.862z" />
                    <path d="M8.744 6.512c-1.366-.113-2.554.002-3.578.847C3.45 8.732 3.78 10.581 3.78 12.843c0 .598.487 1.094 1.095 1.094 6.596 0 6.858.213 7.25-.653.128-.293.093-.2.093-3.002 0-2.226-1.928-3.77-3.475-3.77zM13.878 6.5c-.786 0-1.396-.073-2.071.48 1.623 1.659 1.35 3.311 1.35 5.863 0 .135-.22.094 1.891.094A.951.951 0 0 0 16 11.99V8.61c0-1.163-.952-2.109-2.122-2.109z" />
                </g>
            </g>
        </svg>



    );
}
