import {
  GET_FILE_LIST,
  GET_FILE_INFO,
  UPLOAD_FILE,
  FILE_UPDATE_BY_KEY
} from '../types/fileTypes';

const initialState = {
  dataList: [],
  data: null,
  fetching: false,
  completed: false,
  updated: false,
};

export function fileReducer(state = initialState, action: any) {
  switch (action.type) {

    case FILE_UPDATE_BY_KEY:
      return Object.assign({}, state, { [action.key]: action.value });
    case GET_FILE_LIST:
      return {
        ...state,
        dataList: action.payload
      };
    case GET_FILE_INFO:
      return {
        ...state,
        data: action.payload
      };
    // case POC_CREATE:
    //   return {
    //     ...state,
    //     data: action.payload
    //   };
    // case POC_UPDATE:
    //   return {
    //     ...state,
    //     data: action.payload
    //   };
    // case POC_GET_LIST:
    //   return {
    //     ...state,
    //     dataList: action.payload.data,
    //     total: action.payload.total
    //   };
    // case POC_GET_HISTORIES:
    //   return {
    //     ...state,
    //     histories: action.payload,
    //   };
    // case POC_GET_BLOCKCHAIN_LIST:
    //   return {
    //     ...state,
    //     targetBlocakChains: action.payload
    //   }
    default:
      return state;
  }
}