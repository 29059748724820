import * as React from 'react';

export function Speedometers({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M10 2.902c2 0 3.833.893 5.167 2.277l1.916-2.054C15.292 1.205 12.792 0 10 0 7.25 0 4.75 1.205 2.917 3.125l1.916 2.054C6.167 3.75 8 2.902 10 2.902z" fill="#F8B64C" />
                <path d="M4.833 5.179 2.917 3.125A11.256 11.256 0 0 0 0 10.715c0 2.946 1.125 5.624 2.917 7.589l1.916-2.054c-1.333-1.429-2.125-3.393-2.125-5.536S3.5 6.607 4.833 5.18z" fill="#54C0EB" />
                <path d="m17.083 3.125-1.916 2.054c1.333 1.428 2.125 3.392 2.125 5.535a8.286 8.286 0 0 1-2.125 5.536l1.916 2.054c1.792-1.92 2.917-4.599 2.917-7.59 0-2.946-1.125-5.625-2.917-7.589z" fill="#F1543F" />
                <path d="m15.167 5.179-1.834 1.964C12.458 6.25 11.292 5.67 10 5.67c-1.292 0-2.5.58-3.333 1.473L4.833 5.179C6.167 3.75 8 2.902 10 2.902s3.833.848 5.167 2.277z" fill="#FFD15C" />
                <path d="M6.667 14.286 4.833 16.25c-1.333-1.429-2.125-3.393-2.125-5.536s.834-4.107 2.125-5.535l1.834 1.964c-.834.893-1.375 2.187-1.375 3.571a4.922 4.922 0 0 0 1.375 3.572z" fill="#84DBFF" />
                <path d="M17.292 10.714a8.286 8.286 0 0 1-2.125 5.536l-1.834-1.964a5.378 5.378 0 0 0 1.375-3.572c0-1.384-.541-2.678-1.375-3.571l1.834-1.964c1.333 1.428 2.125 3.392 2.125 5.535z" fill="#FF7058" />
                <path d="M16.417 6.473 11 10.893c-.083.536-.5.893-1 .893-.583 0-1.042-.491-1.042-1.116 0-.625.459-1.116 1.042-1.116a.86.86 0 0 1 .333.044l6.084-3.125z" fill="#40596B" />
                <path d="M13.583 20H6.417c-.875 0-1.584-.759-1.584-1.696 0-.938.709-1.697 1.584-1.697h7.125c.875 0 1.583.76 1.583 1.697.042.937-.667 1.696-1.542 1.696z" fill="#CDD6E0" />
                <circle fill="#FFF" cx="10" cy="10.714" r="1" />
            </g>
        </svg>
    );
}
