import * as React from 'react';

export function ExamResult({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="2.4vw"  height="2.7vw" viewBox="0 0 35 40" xmlns="http://www.w3.org/2000/svg">
            <g fill="#0078D4" fill-rule="nonzero">
                <path d="M17.333 10.667H8A1.333 1.333 0 1 1 8 8h9.333a1.333 1.333 0 1 1 0 2.667zM21.333 17.333H8a1.333 1.333 0 1 1 0-2.666h13.333a1.333 1.333 0 1 1 0 2.666zM15.813 22.667H8A1.333 1.333 0 1 1 8 20h7.813a1.333 1.333 0 1 1 0 2.667zM12.613 28H8a1.333 1.333 0 1 1 0-2.667h4.613a1.333 1.333 0 1 1 0 2.667z" />
                <path d="M12.613 36H6.667A6.674 6.674 0 0 1 0 29.333V6.667A6.674 6.674 0 0 1 6.667 0h16a6.674 6.674 0 0 1 6.666 6.667V17.6a1.333 1.333 0 1 1-2.666 0V6.667c0-2.206-1.795-4-4-4h-16c-2.206 0-4 1.794-4 4v22.666c0 2.206 1.794 4 4 4h5.946a1.333 1.333 0 1 1 0 2.667z" />
                <path d="M25.333 40C20.188 40 16 35.813 16 30.667c0-5.147 4.188-9.334 9.333-9.334 5.146 0 9.334 4.187 9.334 9.334 0 5.146-4.188 9.333-9.334 9.333zm0-16a6.674 6.674 0 0 0-6.666 6.667 6.674 6.674 0 0 0 6.666 6.666A6.674 6.674 0 0 0 32 30.667 6.674 6.674 0 0 0 25.333 24z" />
                <path d="M24.444 34.044a1.33 1.33 0 0 1-.943-.39l-1.777-1.778a1.332 1.332 0 0 1 0-1.885 1.332 1.332 0 0 1 1.885 0l.835.834 2.613-2.612a1.332 1.332 0 0 1 1.886 0 1.332 1.332 0 0 1 0 1.886l-3.556 3.556c-.26.26-.602.39-.943.39v-.001z" />
            </g>
        </svg>
    );
}
