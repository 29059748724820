import Form, { ColCountByScreen, GroupItem, SimpleItem } from 'devextreme-react/form'
import React from 'react'
import { getSizeQualifier } from '../../../../utils/media-query'
import { FormTextbox } from '../../../../shared/components/commonui/form-textbox/FormTextbox'
import { Checkbox, Chip, FormControlLabel, Tooltip } from '@material-ui/core'
import { Create } from '@material-ui/icons';

function EditUserForm({
  UserName,
  handleuserName,
  FirstName,
  handleFirstName,
  handleEditFirstNameValid,
  LastName,
  handleLastName,
  user,
  mode,
  openUserRoleModal,
  hasBlockChainAddress,
  setHasBlockChainAddress
}) {
  return (
    <Form
      id='form'
      labelMode='outside'
      showColonAfterLabel
      labelLocation='top'
      screenByWidth={getSizeQualifier}
    >
      <SimpleItem colSpan={2}>
        <FormTextbox label='User Name'
          value={UserName}
          isEditing={false}
          onValueChange={handleuserName}
          disabled
        />
      </SimpleItem>
      <GroupItem itemType='group'>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <SimpleItem cssClass='accent'>
          <FormTextbox
            label='First Name'
            value={FirstName}
            isEditing={false}
            onValueChange={handleFirstName}
            onValueValidated={handleEditFirstNameValid}
            validationRules={[{ type: 'required', pattern: /^[a-zA-Z_0-9 ]+$/, message: 'FirstName should begin with an alphabet and spaces are not allowed' }]}
          />
        </SimpleItem>
        <SimpleItem cssClass='accent'>
          <FormTextbox
            label='Last Name'
            value={LastName}
            isEditing={false}
            onValueChange={handleLastName}
          />
        </SimpleItem>
      </GroupItem>
      <SimpleItem colSpan={2}>
        <div className='role-main'><span className='role-assigned'>Roles Assigned</span></div>
        <div className="userroles">
          {user && user.roles?.length > 0 ? user.roles.map((userrole: any, index: any) =>
            <div key={`userrole_${userrole.id}`} className="form-group">
              <Tooltip title={userrole.roleName}>
                <Chip label={userrole.roleName}
                  icon={mode === 'edit' && <Create />}
                  disabled={mode === 'view'}
                  color="primary"
                  onClick={() => openUserRoleModal('edit', [userrole])} />
              </Tooltip>
            </div>) :
            <div className="form-group">
              <Tooltip title="None">
                <Chip label="None" disabled={mode === 'view'} color="primary" />
              </Tooltip>
            </div>
          }
        </div>
      </SimpleItem>

      <SimpleItem colSpan={2}>
        <div className='address-assigned'>
          <span className='address-title'>Assign Blockchain Address</span>
          <FormControlLabel className="checkbox-control"
            control={
              <Checkbox
                disabled={hasBlockChainAddress}
                checked={hasBlockChainAddress}
                value={hasBlockChainAddress}
                color="primary"
                onChange={(event) => { event.target.checked ? setHasBlockChainAddress(true) : setHasBlockChainAddress(false) }}
              />
            }
            label=""
          />
        </div>
      </SimpleItem>

      <SimpleItem colSpan={2}>
        <div className='blockChainAddress'>
          <span className='blockchain-title'>Blockchain Address</span>
          <div className='addressNot'>
            <span>{user.userAddress === null ? "Address not assigned" : user.userAddress?.BlockChainAddress ? user.userAddress.BlockChainAddress : "Address not assigned"}</span>
          </div>
        </div>
      </SimpleItem>
    </Form>
  )
}

export default EditUserForm

