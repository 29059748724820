import * as React from 'react';

export function Leadership({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="2.7vw" height="2.7vw" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <g fill="#0078D4" fill-rule="nonzero">
                <path d="M31.529 34.106a1.563 1.563 0 0 0-1.159 1.881.708.708 0 0 1-.136.612.714.714 0 0 1-.57.276H10.492a.715.715 0 0 1-.569-.276.708.708 0 0 1-.136-.612c1.134-4.767 5.366-8.096 10.292-8.096 2.676 0 5.224.99 7.175 2.788a1.563 1.563 0 0 0 2.118-2.298 13.63 13.63 0 0 0-4.059-2.586A7.794 7.794 0 0 0 27.891 20c0-4.308-3.505-7.813-7.813-7.813S12.266 15.692 12.266 20c0 2.3 1 4.372 2.587 5.803a13.689 13.689 0 0 0-8.107 9.461 3.822 3.822 0 0 0 .724 3.272A3.83 3.83 0 0 0 10.491 40h19.174a3.83 3.83 0 0 0 3.021-1.464 3.822 3.822 0 0 0 .724-3.272 1.562 1.562 0 0 0-1.881-1.158zm-11.45-18.794A4.693 4.693 0 0 1 24.765 20a4.693 4.693 0 0 1-4.688 4.688A4.693 4.693 0 0 1 15.391 20a4.693 4.693 0 0 1 4.687-4.688z" />
                <path d="M35.313 14.531h-.788a.484.484 0 0 1-.462-.312c-.085-.203-.071-.477.075-.623l.557-.557a4.693 4.693 0 0 0 0-6.629L33.59 5.305a4.657 4.657 0 0 0-3.315-1.372c-1.252 0-2.43.487-3.314 1.372l-.557.557c-.146.146-.4.175-.603.09a.522.522 0 0 1-.332-.477v-.787A4.693 4.693 0 0 0 20.78 0H19.22a4.693 4.693 0 0 0-4.688 4.688v.787c0 .206-.13.394-.332.478-.203.084-.457.055-.603-.091l-.557-.557a4.657 4.657 0 0 0-3.314-1.372c-1.252 0-2.43.487-3.315 1.372L5.305 6.41a4.693 4.693 0 0 0 0 6.63l.557.556c.146.146.16.42.075.623a.484.484 0 0 1-.462.312h-.787A4.693 4.693 0 0 0 0 19.22v1.562a4.693 4.693 0 0 0 4.688 4.688h.786c.207 0 .395.13.479.332l.01.026a1.563 1.563 0 0 0 2.888-1.194l-.01-.025a3.637 3.637 0 0 0-3.367-2.264h-.787c-.861 0-1.562-.701-1.562-1.563V19.22c0-.862.7-1.563 1.563-1.563h.787a3.637 3.637 0 0 0 3.366-2.264l.01-.023a3.639 3.639 0 0 0-.78-3.983l-.556-.556c-.61-.61-.61-1.6 0-2.21L8.62 7.515c.698-.697 1.631-.579 2.21 0l.556.557a3.64 3.64 0 0 0 3.986.777l.02-.008a3.637 3.637 0 0 0 2.264-3.366v-.787c0-.862.701-1.563 1.563-1.563h1.562c.862 0 1.563.7 1.563 1.563v.787c0 1.476.889 2.798 2.264 3.366l.02.008a3.64 3.64 0 0 0 3.986-.777l.556-.557c.58-.579 1.512-.697 2.21 0l1.105 1.105c.61.61.61 1.6 0 2.21l-.556.556a3.639 3.639 0 0 0-.78 3.983l.01.023a3.637 3.637 0 0 0 3.366 2.264h.788c.861 0 1.562.701 1.562 1.563v1.562c0 .862-.7 1.563-1.563 1.563h-.786a3.637 3.637 0 0 0-3.367 2.264l-.01.025a1.563 1.563 0 0 0 2.888 1.194l.01-.026a.522.522 0 0 1 .479-.332h.786A4.693 4.693 0 0 0 40 20.78V19.22a4.693 4.693 0 0 0-4.688-4.688z" />
            </g>
        </svg>
    );
}
