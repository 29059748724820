import React from "react";
import { AssignmentIcon, MenubookIcon, PhotoAlbum, VideoLabelIcon } from "../../../svgs";
import './ProductContent.scss';
import { TickerCard } from "../../../ticker-card/TickerCard";
import { Demo, ExamResult, ReadingBook } from "../../../../../../../assets/svg";


const content = [
    {
        id: 1,
        content: "Learn",
        name: <ReadingBook />
    },
    {
        id: 2,
        content: "Documents",
        name: <ExamResult />
    },
    {
        id: 3,
        content: "Use Demo Case",
        name: <Demo />
    },
    // {
    //     id: 4,
    //     content: "Installation Guides",
    //     name: '4'
    // }
]

interface IContentProps {

}


function ProductContent({ ...props }: IContentProps) {
    return (
        <div className="product-content">
            <div className="title">How It Works</div>
            <div className="middle">
                {
                    content.map((item, index) => (
                        <div className="wrapper" key={index}>
                            <div className="icon-wrapper">{item.name}</div>
                            <p className="product-title">{item.content}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ProductContent;