import React from "react";
import TextBox, { ITextBoxOptions } from "devextreme-react/text-box";

interface IPropsInputWithLabel extends ITextBoxOptions {
    inputLabel?: string;
    error?: any;
    touched?: any;
    children?: React.ReactNode;
}

function InputWithLabel({ inputLabel, error, touched, children, ...props }: IPropsInputWithLabel) {
    return (
        <div className='dx-field'>
            <div className="dx-field-label">
                <strong>{inputLabel}</strong>
            </div>
            <div className='dx-field-value'>
                <TextBox
                    focusStateEnabled={false}
                    hoverStateEnabled={false}
                    {...props}
                >
                    {children}
                </TextBox>
                {error && touched ? <div className="alert alert-danger my-3">{error}</div> : null}
            </div>
        </div>
    )
}

export default React.memo(InputWithLabel);

