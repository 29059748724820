import React from "react";
import { IButtonProps } from "../Button/Button";
import Button from "../Button";

import "./SubHeader.scss"
import SearchBox from "../SearchBox/SearchBox";
import { TextBox } from "devextreme-react";

export interface IBtnProps {
    showBtn?: boolean;
    btnProps?: IButtonProps;
}
export interface SubHeaderProps extends IItemRendererProps {
    ItemRender?: (...params: any) => React.ReactNode,
    onValueChange?: () => void;
    className?: any;
    children?: React.ReactNode;
    value?: any;
    onInput?: (e: any) => void;
    onCancelSearch?: any;
}

interface IItemRendererProps {
    title?: string;
    itemRendererOption?: {
        btn1Props?: IBtnProps,
        btn2Props?: IBtnProps,
        btn3Props?: IBtnProps,
        btn4Props?: IBtnProps,
        searchBoxProps?: boolean,
    };
    onInput?: (e: any) => void;
    value?: any;
}

const ItemRenderer = ({ title, itemRendererOption, onInput, value, ...props }: IItemRendererProps) => {
    return (
        <div className="SubHeaders">
            <div className="subheader-title">
                <span className="title" >{title} </span>
            </div>
            <div className="sub-header-search">
                {
                    itemRendererOption?.searchBoxProps ? <TextBox
                        stylingMode='outlined'
                        className={'search-header dx-theme-background-color'}
                        placeholder={`Search`}
                        value={value}
                        onInput={onInput}
                        buttons={[
                            {
                                name: 'cancel',
                                location: 'after',
                                options: {
                                    // onClick: () => onCancelSearch(true),
                                    icon: 'search',
                                    stylingMode: 'text',
                                },
                            },
                        ]}
                    /> : null
                }

                <div className="sub-header-buttons">
                    {itemRendererOption?.btn1Props?.showBtn ? <Button {...itemRendererOption.btn1Props.btnProps} >{itemRendererOption?.btn1Props?.btnProps?.children}</Button> : null}
                    {itemRendererOption?.btn2Props?.showBtn ? <Button {...itemRendererOption.btn2Props.btnProps} >{itemRendererOption?.btn2Props?.btnProps?.children}</Button> : null}
                    {itemRendererOption?.btn3Props?.showBtn ? <Button {...itemRendererOption.btn3Props.btnProps} > {itemRendererOption?.btn3Props?.btnProps?.children}</Button> : null}
                    {itemRendererOption?.btn4Props?.showBtn ? <Button {...itemRendererOption.btn4Props.btnProps} > {itemRendererOption?.btn4Props?.btnProps?.children}</Button> : null}
                </div>
            </div>

        </div>
    )
}

function SubHeader({ className, ItemRender, title, children, itemRendererOption, onInput, value, ...props }: SubHeaderProps) {
    return (
        <div>
            {ItemRender ? <> </> : <ItemRenderer onInput={onInput} value={value} title={title} itemRendererOption={itemRendererOption} />}
        </div>
    )
}
export default SubHeader;