import { useEffect, useState } from "react";


function useDebounce<T>(value: T, delay?: number) {

    const [debouncedValue, setDebouncedValue] = useState<T>();

    useEffect(() => {
        const timeoutReference = setTimeout(() => { setDebouncedValue(value) }, delay || 500);
        return () => { clearTimeout(timeoutReference) };
    }, [value, delay]);

    return debouncedValue;
}

export { useDebounce };