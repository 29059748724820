
function isValidJson(json: any) {
    try {
        JSON.parse(json);
        return true;
    } catch (e) {
        return false;
    }
}


function isNullOrUndefined(pValue: any) {
    if (pValue === undefined || pValue === null || (typeof pValue === 'string' && pValue.trim() === '') || pValue === 'null' || pValue === 'undefined') {
        return true;
    }
    return false;
}

export { isValidJson, isNullOrUndefined }
export const stripTrailingSlash = (inputUrl: string) => {
    inputUrl = inputUrl.replace(/^\s+|\s+$/gm, '')
    if (!/^https?:\/\//i.test(inputUrl)) {
        inputUrl = 'https://' + inputUrl;
    }
    if (inputUrl && inputUrl.substr(-1) === '/') {
        return inputUrl.substr(0, inputUrl.length - 1);
    }
    return inputUrl;
};

export const trimSpaces = (inputString: string) => {
    inputString = inputString.trim();
    return inputString;
}

