import jwtDecode from 'jwt-decode';
import { CookieStorage } from 'cookie-storage';
import * as _ from 'lodash';
const cookies = new CookieStorage();
export class JwtServices {
    constructor() {
    }
    public getJWTToken() {
        return cookies.getItem('access_token');
    }

    public getDecodedJWTToken(): any {
        try {
            let result: any = this.getJWTToken();
            return jwtDecode(result);
        } catch {
            console.log('not the valid jwt token');
        }
    }

    public getUserRoles() {
        return !_.isEmpty(this.getDecodedJWTToken()) ? this.getDecodedJWTToken().roles : [];
    }

    public isSupperAdmin() {
        var data = this.getUserRoles();
        return data === "System Admin";
    }

    public isLogin() {
        return this.getJWTToken() != null;
    }
}