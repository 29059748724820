import { Form, Item, Label } from 'devextreme-react/form'
import React from 'react'
import { PasswordTextBox } from '../../../../shared/components/commonui/password-text-box/PasswordTextBox'

function EditChangePassword({
    Password,
    handleSetPassword,
    passwordValidated,
    confirmField,
    confirmPasswordValidators,
    retypePassword,
    handleReTypePassword,
    retypePasswordValidated
}) {
    return (
        <Form labelMode='outside'
            showColonAfterLabel
            labelLocation='top'>
            <Item>
                <Label text={'New Password'}></Label>
                <PasswordTextBox
                    value={Password}
                    onValueChange={handleSetPassword}
                    onValueValidated={passwordValidated}
                />
            </Item>
            <Item>
                <Label text={'Confirm New Password *'}></Label>
                <PasswordTextBox
                    ref={confirmField}
                    validators={confirmPasswordValidators}
                    value={retypePassword}
                    onValueChange={handleReTypePassword}
                    onValueValidated={retypePasswordValidated}
                />
            </Item>
        </Form>
    )
}

export default EditChangePassword