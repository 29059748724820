import React from 'react'
import { Button} from 'devextreme-react'
import DataGrid, { Column, MasterDetail, Paging, Scrolling, Selection, Sorting, } from 'devextreme-react/data-grid'
import './NestedTable.scss'
import { changeEpochToDate } from '../../../../utils/utilityFunctions';
import moment from 'moment';

const NestedTable = (props: NestedTable) => {
    
    const onSelectionChanged = (e: { currentSelectedRowKeys: any; selectedRowsData: any; }) => {
        props.gridSelectData(e.selectedRowsData)
    }

    function statusColumnFormatter(value: any) {
        if (value.data.ES > 70) {
            return (
                <div className='status-column'>
                    <div className="status-column-circle green">
                    </div>
                    <div className='status-column-btn'>
                        <span className='status-column-text'>Done</span>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='status-column'>
                    <div className="status-column-circle yellow">
                    </div>
                    <span className='status-column-text'>In Progress</span>
                </div>
            )
        }
    }
    const actionColumnFormatter = (value: any) => {
        return (
            <div className="actionBtn">
                <Button
                    onClick={() => {
                        props.viewCredential(true)
                        props.viewCredentialData(value.data)
                    }}
                    width={100}
                    text="View Details"
                    type="default"
                    stylingMode="contained"
                /></div>
        )
    }

    const convertValue = (value, columnname) => {
        const date = changeEpochToDate(value);
        const stillUtc = moment.utc(date).toDate();
        return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss');
    }

    const NestedData = (NestedEvent: any) => {

        return <DataGrid
            id="grid-container-nested"
            dataSource={props.nestedDataSource.filter((data: any) => data.ClusterUID === NestedEvent.data.data.UID)}
            keyExpr="UID"
            showBorders={true}
            showColumnLines={false}
            onSelectionChanged={onSelectionChanged}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnMinWidth={120}
            columnAutoWidth={true}
            allowColumnReordering={true}
            scrolling={{
                mode: 'virtual',
                rowRenderingMode: 'virtual',
                showScrollbar: 'always',
                useNative: false
            }}
        >
            <Column dataField="NodeType" cssClass='cls' minWidth={200} defaultSortOrder="asc"/>
            <Column dataField="NodeName" minWidth={200} />
            <Column alignment="left" format={convertValue} caption="Created At" dataField="CreatedAt" minWidth={200} />
            <Column caption="Node Url" dataField="NodeUrl" minWidth={200} />
            {props.showActions ? <Column
                type="buttons"
                cellRender={actionColumnFormatter}
                dataField={"showAction"}
                minWidth={200}
            />
                : null}
            <Selection mode="multiple" />
            <Sorting mode='single' />
        </DataGrid>
    }

    return (
        <DataGrid
            id="grid-container"
            dataSource={props.dataSource}
            keyExpr="_id"
            showBorders={true}
            showColumnLines={false}
            onSelectionChanged={onSelectionChanged}
            width={'100%'}
            columnAutoWidth={true}
            allowColumnReordering={true}

        >
            <Scrolling columnRenderingMode="virtual" />
            <Paging enabled={false} />
            <Sorting mode='single' />
            <Column dataField="ClusterName" width={240} />
            <Column width={200} dataField="ClusterFamily" />
            <Column width={200} alignment="left" format={convertValue} caption="Created At" dataField="CreatedAt" />
            <MasterDetail enabled={true} autoExpandAll component={NestedData} />
        </DataGrid>
    )
}

export default React.memo(NestedTable)
interface NestedTable {
    showActions?: boolean,
    actionBtnIcon?: string,
    viewCredential?: any,
    viewCredentialData?: any,
    gridSelectData?: any
    toggleLoader?: any;
    dataSource?: any;
    nestedDataSource?: any;
}

