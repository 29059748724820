
import * as XLSX from "xlsx";

class FileDownloadService {
    public arrayOfObjectToXLSXFileDownload(data: Array<any>, fileName: string = "default"): any {
        try {
            let ws = XLSX.utils.json_to_sheet(data);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "sheet");
            return XLSX.writeFile(wb, fileName);
        }
        catch (downloadXLSError: any) {
            return downloadXLSError
        }
    }
    public objectToJsonFile(data: any, fileName: string = "default"): boolean {
        try {
            // create file in browser
            const json = JSON.stringify(data, null, 2);
            const blob = new Blob([json], { type: "application/json" });
            const href = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return true;
        }
        catch (objectToJsonError) {
            console.log(objectToJsonError, "objectToJsonError");
            return false
        }
    }
    public getFileAndParseToJson(file: any): Promise<unknown> {
        return new Promise((res, rej) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsText(file, "UTF-8");

                fileReader.onload = (e: ProgressEvent<FileReader>) => {
                    res(e.target.result);
                };
            }
            catch (error: any) {
                console.log("getFileAndParseToJson Error", error);
                rej(error);
            }
        })
        // try {
        //     const fileReader = new FileReader();
        //     fileReader.readAsText(file, "UTF-8");

        //     fileReader.onload = (e: ProgressEvent<FileReader>) => {
        //         return (e.target.result);
        //     };
        // }
        // catch (error: any) {
        // }
    }
}



export default FileDownloadService