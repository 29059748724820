const AppConstants = {
	API_URL: '/api',
	SCHEMA: {
		ETId: 50,
		SV: 1,
	},
	SCHEMA_DATA: {
		ETId: 10000,
		SV: 1,
	},
	THEME: {
		LIGHT: 'Light',
		DARK_BLUE: 'Dark Blue',
		ALICE_BLUE: 'Alice Blue'
	},
	SYSTEM_GENERATED_ETIDS: {
		clustering: {
			clusters: { ETId: 30, SV: 1 },
			nodes: { ETId: 32, SV: 1 }
		},
		system: {
			binary_files: { ETId: 17, SV: 1 },
			hash_supported: { ETId: 43, SV: 1 },
		},
		ORGANIZATION: { ETId: 5, SV: 1 },
		CONTRACT: { ETId: 20, SV: 1 },
		DATA_SHARING: { ETId: 22, SV: 1 },
		SCHEMAS: { ETId: 50, SV: 1 }

	},
	CONTRACT_DATA: {
		ETId: 20,
		SV: 1
	},
	CONTRACT_STATUS: {
		SH_ETId_Submitted: { name: 'Sharing ETId Envelope Submitted', shortForm: 'Submitted', code: 10 },
		SH_ETId_SenderApproved: { name: 'Sharing ETId Envelope SenderApproved', shortForm: 'Sender approved', code: 20 },
		SH_ETId_SenderApprovedSentToReceiver: { name: 'Sharing ETId Envelope SenderApprovedAndSentToReceiver', shortForm: 'Sender approved and sent to receiver', code: 30 },
		SH_ETId_ReceiverApproved: { name: 'Sharing ETId Envelope ReceiverApproved', shortForm: 'Receiver approved', code: 40 },
		SH_ETId_ReceiverSent: { name: 'Sharing ETId Envelope ReceiverSent', shortForm: 'Receiver Sent', code: 50 },
		SH_ETId_DataSharing: { name: 'Start data sharing', shortForm: 'Sharing', code: 60 },
	}
}
export default AppConstants;

