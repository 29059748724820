import React from "react";
import './Header.scss';

interface IHeaderProps {
    children?: React.ReactNode
    type?: "notary" | "platform" | "composer",
    image?: string,
    icon?: string,
    title?: string,
    onClick?: () => void;
    isLogin?: boolean
}


const Header: React.FC<IHeaderProps> = ({ children, image, icon, title, isLogin, ...props }) => {
    return (
        <div className="InnerHeaderLanding">
            {children}
        </div>
    )
}

export default Header;
