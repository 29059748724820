import React from 'react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleLoader } from '../redux/actions/loader.action';
import { setCurrentUser, changePassword } from '../redux/actions/authActions';

// Axios
import axios, { AxiosError } from 'axios';

// Cookies
import { CookieStorage } from 'cookie-storage';

// Header and Footer
import Header from '../shared/components/commonui/Header';
import Footer from '../shared/components/commonui/Footer';

// Devextreme
import { ScrollView } from 'devextreme-react';
import { ClickEvent } from 'devextreme/ui/button';
import { refreshTheme } from "devextreme/viz/themes"

// High Order Component
import ProductCard from '../shared/components/commonui/HigherOrderComponents/Product/ProductCard/ProductCard';
import ProductContent from '../shared/components/commonui/HigherOrderComponents/Product/ProductContent/ProductContent';
import ProductDocument from '../shared/components/commonui/HigherOrderComponents/Product/ProductDocument/ProductDocument';
import ProductSupport from '../shared/components/commonui/HigherOrderComponents/Product/ProductSupport/ProductSupport';
import ProductTechnical from '../shared/components/commonui/HigherOrderComponents/ProductSub/ProductTechnical/ProductTechnical';
import ProductRelease from '../shared/components/commonui/HigherOrderComponents/ProductSub/ProductRelease/ProductRelease';
import ProductSocial from '../shared/components/commonui/HigherOrderComponents/ProductSub/ProductSocial/ProductSocial';
import ProductText from '../shared/components/commonui/HigherOrderComponents/Product/ProductText/ProductText';

// Services
import { AuthService } from '../core/interceptors/auth.interceptor';
import Toaster from '../services/toaster.services';
import RestApiService from "../services/http-services";
import ThemeService from '../services/theme-services';

// Constant
import appConstants from "../core/constants/appConstants";

// Api
import { ApiEndPoints } from '../models/api-endpoint';
import { trimSpaces } from '../utils/validation/common.validation';

// Loader
import Loader from '../shared/components/loader/loader';


// Componet
import { ChangeProfilePasswordForm } from '../shared/components/change-password-form/ChangePasswordForm';
import CustomHeader from '../shared/components/commonui/CustomHeader/CustomHeader';


// Scss
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./landingPage.scss";

interface ILandingPageComponentProps {
  toggleLoader?: any;
  setCurrentUser?: any,
  history?: any,
  isLoaderShow: boolean,
}

interface ILandingPageComponentState {
  openLogin: boolean,
  userName: string,
  userFullName: string,
  password: string,
  isLogin: boolean,
  isFirstUser: boolean,
  selectedTheme: string,
  authMessage: string,
  selectedFont: string,
  themeArray: any,

}

class LandingPage extends React.Component<ILandingPageComponentProps, ILandingPageComponentState> {
  public themeService = new ThemeService();
  public authSerivce = new AuthService();
  public cookies = new CookieStorage();

  public state = {
    openLogin: false,
    userName: '',
    userFullName: '',
    password: '',
    isLogin: false,
    isFirstUser: false,
    selectedTheme: '',
    authMessage: '',
    selectedFont: 'small',
    themeArray: ["Light", "Dark Blue", "Alice Blue"],
  }
  constructor(props: any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const themeFromCookie = this.cookies.getItem('theme');
    if (themeFromCookie === null || themeFromCookie === 'null') {
      this.cookies.setItem("theme", 'material.blue.light');
    } else {
      this.setState({ selectedTheme: themeFromCookie });
    }
    this.setState({ userFullName: this.cookies.getItem('firstname') + " " + this.cookies.getItem('lastname') });
    this.setState({ userName: this.cookies.getItem('firstname') });
    this.dynamicThemeApply();
    this.onChangeFontSize(this.cookies.getItem('fontSize'));
    document.addEventListener('keydown', this.handleKeyPress);
    this.themeService.initializeTheme();
    refreshTheme();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
  componentDidUpdate() {
    this.dynamicThemeApply();
  }

  dynamicThemeApply() {
    if (this.state.selectedTheme === appConstants.THEME.DARK_BLUE) {
      document.documentElement.style.setProperty('--color', '#fff');
      document.documentElement.style.setProperty('--font-color', '#fff');
      document.documentElement.style.setProperty('--tab_background_color', '#23395d');
      document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
      document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
      document.documentElement.style.setProperty('--border_color', '#0078d4');
      document.documentElement.style.setProperty('--border_color1', 'transparent');
      document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
      document.documentElement.style.setProperty('--selected_background_color', '#7488aa');
      document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
      document.documentElement.style.setProperty('--Bad_color', '#c9817d');
      document.documentElement.style.setProperty('--Good_color', '#9bc798');
      document.documentElement.style.setProperty('--background-color', '#23395d');
      document.documentElement.style.setProperty('--hover_background_color', '#305695');
      document.documentElement.style.setProperty('--link-color', '#0078d4');
      document.documentElement.style.setProperty('--table_header_border_color', 'darkslateblue');
      document.documentElement.style.setProperty('--social_media_icon_color', 'white');
    }
    else if (this.state.selectedTheme === appConstants.THEME.ALICE_BLUE) {
      document.documentElement.style.setProperty('--color', '#fff');
      document.documentElement.style.setProperty('--font-color', '#fff');
      document.documentElement.style.setProperty('--tab_background_color', '#3E849E');
      document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
      document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
      document.documentElement.style.setProperty('--border_color', '#06b5f4');
      document.documentElement.style.setProperty('--border_color1', 'transparent');
      document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
      document.documentElement.style.setProperty('--selected_background_color', '#2e7691');
      document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
      document.documentElement.style.setProperty('--Bad_color', '#c9817d');
      document.documentElement.style.setProperty('--Good_color', '#9bc798');
      document.documentElement.style.setProperty('--background-color', '#3E849E');
      document.documentElement.style.setProperty('--hover_background_color', '#2e7691');
      document.documentElement.style.setProperty('--link-color', '#1b09b0f2');
      document.documentElement.style.setProperty('--table_header_border_color', '#d6d6d6');
      document.documentElement.style.setProperty('--social_media_icon_color', 'white');
    }

    else {
      document.documentElement.style.setProperty('--color', '#0078d4');
      document.documentElement.style.setProperty('--font-color', '#000000');
      document.documentElement.style.setProperty('--tab_background_color', '#ffffff');
      document.documentElement.style.setProperty('--table_header_color', '#c2c0bd');
      document.documentElement.style.setProperty('--table_header_background', '#c2c0bd');
      document.documentElement.style.setProperty('--border_color', '#f1f6ff');
      document.documentElement.style.setProperty('--border_color1', '#f1f6ff');
      document.documentElement.style.setProperty('--btn_background_color', '#0078d4');
      document.documentElement.style.setProperty('--selected_background_color', '#c7e0f4');
      document.documentElement.style.setProperty('--Pending_color', '#fdf0c8');
      document.documentElement.style.setProperty('--Bad_color', '#f8cecc');
      document.documentElement.style.setProperty('--Good_color', '#d5e8d4');
      document.documentElement.style.setProperty('--background-color', '#f1f6ff');
      document.documentElement.style.setProperty('--hover_background_color', '#edebe9');
      document.documentElement.style.setProperty('--link-color', '#0078d4');
      document.documentElement.style.setProperty('--table_header_border_color', '#d6d6d6');
      document.documentElement.style.setProperty('--social_media_icon_color', '#0078d4');
    }
  }
  handleClose = () => {
    this.setState({ openLogin: false });
  };
  onClickPD = (pDirctry: any) => {
    if (this.cookies.getItem('firstname') != null && this.state.userName != '' && this.state.userName != undefined && this.cookies.getItem('access_token') != null && this.cookies.getItem('access_token') != undefined) {
      if (pDirctry === "FiVe") {
        window.open(window.location.origin + "/notary", "_blank")
      }
      else {
        window.open(window.location.origin + "/page/dashboard", "_blank")
      }
    }
    else {

      if (pDirctry === "FiVe") {
        window.open(window.location.origin + "/notary", "_blank")
      }
      else {
        alert("Please Sign-in");
      }
    }
  };

  onClickDocumentation = (lDocument: any) => {
    if (lDocument === "API Document") {
      window.open("https://apidoc.walacor.com/", "_blank", "noopener");
    }
  }
  onLogin = (propsData: any, pathName: any) => {
    this.props.history.push({
      pathname: pathName
    });
  }
  secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);
    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  login = (userName: string, password: string) => {
    this.props.toggleLoader(true);
    this.authSerivce.loginAndSetToken(userName, password).then(res => {
      this.props.toggleLoader(false);
      if (res?.data?.data) {
        const themeFromCookie = this.cookies.getItem('theme');
        if (themeFromCookie === null || themeFromCookie === 'null') {
          this.cookies.setItem("theme", 'material.blue.light');
        } else {
          this.setState({ selectedTheme: themeFromCookie });
        }
        if (res.data.data.user.resetPasswordCount === 0) {
          this.setState({ isFirstUser: true });
          this.cookies.setItem("theme", this.state.selectedTheme);
        }
        else {
          this.setState({ isLogin: false });
          document.cookie = "theme" + "=" + this.state.selectedTheme;
          document.cookie = "firstname" + "=" + res.data.data.user.firstName;
          document.cookie = "lastname" + "=" + res.data.data.user.lastName;
          document.cookie = "usershortname" + "=" + res.data.data.user.firstName.charAt(0) + '' + res.data.data.user.lastName.charAt(0);
          this.cookies.setItem("theme", this.state.selectedTheme);
          this.props.setCurrentUser(res.data.data);
          this.setState({ userFullName: res.data.data.user.firstName + " " + res.data.data.user.lastName });
        }
      }
      else {
        this.props.setCurrentUser(res);
      }
    }).catch((error: AxiosError) => {
      if (error.message === 'Network Error') {
        Toaster.errorToaster(error.message)
      }
      this.props.toggleLoader(false);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          if (error.response.status !== 422)
            Toaster.errorToaster(response.message);
          if (error.response.status === 422) {
            const responseMessage = response.message.split(' ');
            const errorMessage = response.message.split('for');
            const time = this.secondsToHms(responseMessage[4]);
            Toaster.errorToaster(errorMessage[0] + 'for ' + time + ".Please try later.");
            this.setState({ authMessage: errorMessage[0] + 'for ' + time + ".Please try later." });
            const milesecond = parseInt((responseMessage[4] * 1000).toFixed(0));
            setTimeout(() => {
              this.setState({ authMessage: '' });
            }, milesecond);
          }
        });
      }
    });
  }

  onSubmit = (event: ClickEvent) => {
    this.login(this.state.userName, this.state.password);
  }

  resetPasswordHandler = (event: ClickEvent) => {
    this.resetPassword(event)
  }


  resetPassword = async (userData: any) => {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.RESET_PASSWORD, null, userData, null, {
      ETId: 10,
      SV: 1,
    }).then((response: any) => {
      this.props.toggleLoader(false);
      Toaster.successToaster("passwordChange", "Password Changed Successfully. Please Login with New password");
      if (response) {
        delete axios.defaults.headers.common['Authorization'];
        this.cookies.clear();
        this.setState({ isFirstUser: false })
        this.setState({ password: '' });
      }
    }).catch(error => {
      if (error.message === 'Network Error') {
        Toaster.errorToaster(error.message)
      }
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response?.message);
        });
      }
      this.props.toggleLoader(false);
    });
  }

  onChangeTheme = (theme: any) => {
    this.cookies.removeItem('theme');
    this.setState({ selectedTheme: theme });
    this.cookies.setItem('theme', theme);
    this.dynamicThemeApply();
  }

  onLogout = () => {
    this.cookies.removeItem("firstname");
    this.cookies.removeItem("lastname");
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    this.cookies.clear();
    this.setState({ isLogin: false })
    this.setState({ userName: '' });
  }
  onChangeFontSize = (fontSize?: any) => {
    this.setState({ selectedFont: fontSize });
    this.cookies.setItem('fontSize', fontSize);
    switch (fontSize) {
      case 'Large':
        document.documentElement.style.setProperty('--h3-font-size', '22px');
        return document.documentElement.style.setProperty('--font-text-size', '20px');
      case 'Medium':
        document.documentElement.style.setProperty('--h3-font-size', '20px');
        return document.documentElement.style.setProperty('--font-text-size', '18px');
      default:
        document.documentElement.style.setProperty('--h3-font-size', '17px');
        return document.documentElement.style.setProperty('--font-text-size', '15px');
    }

  }




  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.onSubmit(event)
    }
  };
  private ContentSignOut = (): React.JSX.Element => {
    return (
      <span>Sign-out</span>
    )
  }
  render(): React.ReactNode {
    return (
      <>
        <Header>
          <CustomHeader
            isLogin={this.state.isLogin}
            userName={this.state.userName}
            userFullName={this.state.userFullName}
            password={this.state.password}
            authMessage={this.state.authMessage}
            onSubmit={this.onSubmit}
            onLogout={this.onLogout}
            setLogin={() => this.setState({ isLogin: true })}
            setUserName={(textBoxEvent: any) => this.setState({ userName: trimSpaces(textBoxEvent.event.target.value) })}
            setPassword={(textBoxEvent: any) => this.setState({ password: textBoxEvent.event.target.value })}
            ContentSignOut={this.ContentSignOut}
          />
        </Header>
        <ScrollView>
          <div className='main-landingpage'>
            <div className='view-wrapper view-wrapper-dashboard'>
              <ProductText />
              <div className='cards compact'>
                <ProductCard onClick={this.onClickPD} />
                <ProductDocument onClick={this.onClickDocumentation} />
                <ProductContent />
                <ProductSupport />
              </div>
              <div className='cards normal'>
                <ProductTechnical />
                <ProductRelease />
                <ProductSocial />
              </div>
            </div>
          </div>
        </ScrollView>
        {
          this.state.isFirstUser &&
          <ChangeProfilePasswordForm
            visible={this.state.isFirstUser}
            onSubmitForm={this.resetPasswordHandler}
            setVisible={() => this.setState({ isFirstUser: false })} />
        }

        {<div className='loader-landingPage'>
          {this.props.isLoaderShow ? <Loader /> : null}
        </div>}
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  isLoaderShow: state.appLoaderReducer.isLoaderShow
});


const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader, setCurrentUser: setCurrentUser, changePassword: changePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);





