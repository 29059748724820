import React from "react";
import './CheckBox.scss';
import { CheckBox as DevextremeCheckhBox } from "devextreme-react";
import { ICheckBoxOptions } from "devextreme-react/check-box";


export interface ICheckBoxProps extends ICheckBoxOptions {

    width?: number | string | (() => number | string);

    /**
   * Specifies the UI component&apos;s width.
   */

    height?: number | string | (() => number | string);
    /**
    * Specifies the UI component&apos;s height.
    */

    className?: string;


    /**
     * Specifies a text string displayed when the editor&apos;s value is empty.
     */

    color?: string;

    backgroundColor?: string;

    style?: any;

    text?: string;

    name?: any;

    /**
     * The value to be assigned to the `name` attribute of the underlying HTML element.
     */


    value?: any;

    /**
    * Specifies a value the UI component displays.
    */


    onValueChanged?: (e: any) => void;



    iconSize?: any;
    /**
     * Specifies how the UI component&apos;s iconSize.
     */

}

function CheckBox({ className, value, text, onValueChanged, iconSize, style, name, ...props }: ICheckBoxProps) {
    return (
        <DevextremeCheckhBox
            className={className}
            value={value}
            text={text}
            name={name}
            style={style}
            iconSize={iconSize}
            onValueChanged={onValueChanged}
            {...props}
        ></DevextremeCheckhBox>
    )
}

export default React.memo(CheckBox); 