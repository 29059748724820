import React, { useState, useEffect } from "react";
import './pagination.scss';
import Icon from "../Icon/Icon";
import { Pagination } from 'react-bootstrap';
import { SelectBox, Button } from 'devextreme-react';

interface IPaginationProps {
    totalRecord: number;
    currentPageNo: number;
    goToPreviousPage: (pageNumber: number) => void;
    goToNextPage: (pageNumber: number) => void;
    changePageSize: (pageSize: number) => void;
    totalPage: number;
    isLessthanTotalElement: boolean;
    dataLength: number;
}

function DynamicPagination(props: IPaginationProps) {
    const [prePage, setPrePage] = useState();
    const [nextPage, setNextPage] = useState();
    const [pageSize, setPageSize] = useState();
    const [pageSizeList, setPageSizeList] = useState<any>([{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }]);
    const defaultPageSize = 10;
    let pageSizeLst = [{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }]
    const noOfPageDropdown = () => {
        if (props.totalRecord <= 10) setPageSizeList(pageSizeLst.slice(0, 1))
        else if (props.totalRecord > 10 && props.totalRecord <= 25) setPageSizeList(pageSizeLst.slice(0, 2))
        else if (props.totalRecord > 25 && props.totalRecord <= 50) setPageSizeList(pageSizeLst.slice(0, 3))
        else setPageSizeList(pageSizeLst.slice(0, 4))

    }

    useEffect(() => {
        noOfPageDropdown()
    }, [props?.totalRecord])

    useEffect(() => {
        if (prePage) {
            props.goToPreviousPage(prePage);
        }
    }, [prePage]);

    const goToPreviousPage = (page: any) => {
        setPrePage(page);
        props.goToPreviousPage(page);
    }

    useEffect(() => {
        if (nextPage) {
            props.goToNextPage(nextPage);
        }
    }, [nextPage]);

    const goToNextPage = (page: any) => {
        setNextPage(page);
        props.goToNextPage(page);
    }

    useEffect(() => {
        if (pageSize) {
            props.changePageSize(pageSize);
        }
    }, [pageSize]);

    const changePageSize = (value: any) => {
        setPageSize(value);
    }

    return (
        <div className={`pagination-cover dx-theme-background-color`} >
            <div className="total-result">
                <div className="total-size">
                    {/* <p className="dx-theme-text-color">Showing {props.currentPageNo} of {props.totalPage} pages ({props.totalRecord} records)</p> */}
                    <p>Showing {props.currentPageNo} of {props.totalPage} Pages | ({props.totalRecord} Records)</p>
                    <div className="filter-box pagination-pageSize">
                        <div className='option'>
                            <SelectBox
                                stylingMode='outlined'
                                items={pageSizeList}
                                id='outlined-select-currency-native'
                                displayExpr='name'
                                valueExpr='name'
                                // label='page size'
                                value={pageSize ? pageSize : defaultPageSize}
                                defaultValue={defaultPageSize}
                                onValueChange={(event: any) => { changePageSize(event) }}
                                height={"35px"}
                            />
                        </div>
                    </div>
                </div>
                <Pagination>
                    <Pagination.Item
                        onClick={() => {
                            if (props.currentPageNo && props.currentPageNo > 1)
                                goToPreviousPage(1)
                        }}
                        disabled={props.currentPageNo === 1}

                    >
                        First Page
                    </Pagination.Item>

                    <Pagination.Prev
                        onClick={() => {
                            if (props.currentPageNo !== 1) {
                                const page = props.currentPageNo ? props.currentPageNo - 1 : 1;
                                goToPreviousPage(page)
                            } else {
                                goToPreviousPage(props.currentPageNo)
                            }
                        }}
                        disabled={props.currentPageNo === 1}
                    />

                    <Pagination.Item disabled>{props.currentPageNo}</Pagination.Item>

                    <Pagination.Next
                        onClick={() => {
                            if ((!props.isLessthanTotalElement && props.currentPageNo && props.totalPage) && (props.currentPageNo < props.totalPage)) {
                                const page = props.currentPageNo + 1;
                                goToNextPage(page)
                            }
                            else {
                                goToNextPage(props.currentPageNo);
                            }
                        }}
                        disabled={(props.currentPageNo === props.totalPage) || (props.dataLength === 0)}
                    />
                    <Pagination.Item
                        onClick={() => {
                            if ((props.currentPageNo && props.totalPage) && props.currentPageNo < props.totalPage)
                                goToNextPage(props.totalPage)
                        }}
                        disabled={(props.currentPageNo === props.totalPage) || (props.dataLength === 0)}
                    >
                        Last Page
                    </Pagination.Item>
                </Pagination>
            </div>

        </div>
    );
}

export default React.memo(DynamicPagination);