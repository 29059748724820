import React from "react";
import Button from "../../../Button/Button";
import Card from "../../../Cards/Card";
import './ProductSupport.scss';
import { TickerCard } from "../../../ticker-card/TickerCard";
import Icon from "../../../Icon/Icon";
import { Comment, Gmail } from "../../../../../../../assets/svg";



interface ISupportProps {

}

const TicketButton = ({ iconName, buttonText }) => {
    return (
        <div className="ticket-button">
            <div className="icon-container">
                <Icon iconName={iconName} size="24px" />
            </div>
            <p>{buttonText}</p>
        </div>
    )
}

function ProductSupport({ ...props }: ISupportProps) {
    return (
        <div className="support-card">

            <div className="title">Support Desk</div>
            <div className="sub-title">Direct ways to get in touch with us.</div>
            <div className="ticket-button">
                <div className="icon-container">
                    <Comment />
                </div>
                <p>{'Submit a ticket'}</p>
            </div>

            <div className="ticket-button">
                <div className="icon-container">
                    <Gmail />
                </div>
                <p>{'Email Us'}</p>
            </div>

        </div>
    )
}

export default ProductSupport;