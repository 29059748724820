import * as React from 'react';

export function Hierarchical({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="21" height="19" viewBox="0 0 21 19" xmlns="http://www.w3.org/2000/svg">
            <g transform="rotate(90 10.5 10.5)" fill-rule="nonzero" fill="none">
                <path d="M14 10.667a.667.667 0 0 0 0-1.334h-3.333V4c0-.368.298-.667.666-.667H14A.667.667 0 0 0 14 2h-2.667a2 2 0 0 0-2 2v5.333H6a.667.667 0 0 0 0 1.334h3.333V16a2 2 0 0 0 2 2H14a.667.667 0 0 0 0-1.333h-2.667a.667.667 0 0 1-.666-.667v-5.333H14z" fill="#837A87" />
                <rect fill="#FC5B66" x="13" width="6" height="6" rx="1" />
                <rect fill="#F1AF29" x="13" y="7" width="6" height="6" rx="1" />
                <rect fill="#43A5FF" y="7" width="6" height="6" rx="1" />
                <rect fill="#58E98E" x="13" y="15" width="6" height="6" rx="1" />
                <path d="M13.501.767h3.666c.675 0 1.222.687 1.222 1.535V5.37c0 .217-.038.431-.11.629.438-.247.72-.794.721-1.396v-3.07C19 .688 18.453 0 17.778 0h-3.666c-.48.001-.915.355-1.112.905.157-.09.328-.137.501-.138z" fill="#FD3A4B" />
                <path d="M13.501 7.767h3.666c.675 0 1.222.687 1.222 1.535v3.069c0 .217-.038.431-.11.629.438-.247.72-.794.721-1.396v-3.07C19 7.688 18.453 7 17.778 7h-3.666c-.48.001-.915.355-1.112.905.157-.09.328-.137.501-.138z" fill="#D89507" />
                <path d="M13.501 15.767h3.666c.675 0 1.222.687 1.222 1.535v3.069c0 .217-.038.431-.11.629.438-.247.72-.794.721-1.396v-3.07c0-.847-.547-1.534-1.222-1.534h-3.666c-.48.001-.915.355-1.112.905.157-.09.328-.137.501-.138z" fill="#5BC483" />
                <path d="M.501 7.767h3.666c.675 0 1.222.687 1.222 1.535v3.069c0 .217-.038.431-.11.629.438-.247.72-.794.721-1.396v-3.07C6 7.688 5.453 7 4.778 7H1.112c-.48.001-.915.355-1.112.905.157-.09.328-.137.501-.138z" fill="#198DFC" />
            </g>
        </svg>
    );
}
