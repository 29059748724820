export const filterParams = (filter: any): Object => {
  Object.keys(filter).forEach(element => {
    if (filter[element] === '' || filter[element] === null || filter[element] === undefined) {
      delete filter[element];
    }
  });
  return { ...filter };
}

export const changeUTCTimeToMilliSecond = (date: Date) => {
  return date.getTime() + date.getTimezoneOffset() * 60000;
}

export const changeUTCMillisecondToDate = (date: number) => {
  const utcTime = new Date(date);
  return new Date(utcTime.getTime() + (utcTime.getTimezoneOffset() * -1) * 60000);
}

export const changeEpochToDate = (date: number) => {
  if (Math.floor(date).toString().length === 10) {
    return new Date(date * 1000);
  }

  if (Math.floor(date).toString().length === 13) {
    return new Date(date);
  }
  else {
    return date;
  }
}

export const flatten = (function (isArray, wrapped) {
  return function (table) {
    return reduce("", {}, table);
  };

  function reduce(path, accumulator, table) {
    if (isArray(table)) {
      var length = table.length;

      if (length) {
        var index = 0;

        while (index < length) {
          var property = path + "[" + index + "]", item = table[index++];
          if (wrapped(item) !== item) accumulator[property] = item;
          else reduce(property, accumulator, item);
        }
      } else accumulator[path] = table;
    } else {
      var empty = true;

      if (path) {
        for (var property in table) {
          var item = table[property], property = path + "." + property, empty = false;
          if (wrapped(item) !== item) accumulator[property] = item;
          else reduce(property, accumulator, item);
        }
      } else {
        for (var property in table) {
          var item = table[property], empty = false;
          if (wrapped(item) !== item) accumulator[property] = item;
          else reduce(property, accumulator, item);
        }
      }

      if (empty) accumulator[path] = table;
    }

    return accumulator;
  }
}(Array.isArray, Object));

export function jsonifyArrayObj<T>(arr: Array<T>) {
  arr.forEach((dataObject: T) => {
    Object.keys(dataObject).forEach((key: string) => {
      if (typeof dataObject[key] === "object") {
        dataObject[key] = JSON.stringify(dataObject[key]);
      }
    })
  });
  return arr;
}