import * as React from 'react';

export function Bullet({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M17.578.586v18.828a.58.58 0 0 1-.586.586H.586A.58.58 0 0 1 0 19.414V.586A.58.58 0 0 1 .586 0h1.758l1.172 1.172h10.546L15.235 0h1.758a.58.58 0 0 1 .586.586z" fill="#5969E8" />
                <path d="M17.578.586v18.828a.58.58 0 0 1-.586.586H8.79V1.172h5.274L15.233 0h1.758a.58.58 0 0 1 .586.586z" fill="#433ECC" />
                <path d="M15.234 0v12.93l-4.687 4.687H2.93a.58.58 0 0 1-.586-.586V0h3.515l1.172 1.172h3.516L11.719 0h3.515z" fill="#ECECF1" />
                <path fill="#E2E2E7" d="M15.234 0v12.93l-4.687 4.687H8.789V1.172h1.758L11.719 0z" />
                <path d="m11.548 17.445 3.514-3.514a.593.593 0 0 0 .172-.415v-.586h-4.101a.586.586 0 0 0-.586.586v4.101h.586a.593.593 0 0 0 .415-.172z" fill="#BABAC0" />
                <path d="M10.547 6.484a.58.58 0 0 1-.586.586H5.273a.58.58 0 0 1-.585-.586.58.58 0 0 1 .585-.586h4.688a.58.58 0 0 1 .586.586zM10.547 8.828a.58.58 0 0 1-.586.586H5.273a.58.58 0 0 1-.585-.586.58.58 0 0 1 .585-.586h4.688a.58.58 0 0 1 .586.586zM10.547 11.172a.58.58 0 0 1-.586.586H5.273a.58.58 0 0 1-.585-.586.58.58 0 0 1 .585-.586h4.688a.58.58 0 0 1 .586.586z" fill="#979FEF" />
                <g fill="#7984EB">
                    <path d="M12.89 6.484a.586.586 0 1 1-1.171 0 .586.586 0 0 1 1.172 0zM12.89 8.828a.586.586 0 1 1-1.171 0 .586.586 0 0 1 1.172 0zM12.89 11.172a.586.586 0 1 1-1.171 0 .586.586 0 0 1 1.172 0zM10.547 11.172a.58.58 0 0 1-.586.586H8.789v-1.172h1.172a.58.58 0 0 1 .586.586zM10.547 8.828a.58.58 0 0 1-.586.586H8.789V8.242h1.172a.58.58 0 0 1 .586.586zM9.96 7.07H8.79V5.898h1.17a.58.58 0 0 1 .587.586.58.58 0 0 1-.586.586z" />
                </g>
                <path d="M11.719 0v1.758a.58.58 0 0 1-.586.586H6.445a.58.58 0 0 1-.586-.586V0h5.86z" fill="#FDBF00" />
                <path d="M11.719 0v1.758a.58.58 0 0 1-.586.586H8.789V0h2.93z" fill="#FF9F00" />
            </g>
        </svg>
    );
}
