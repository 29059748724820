import React from "react";
import Icon from "../../../Icon/Icon";
import { FaceBookIcon, LinkedInIcon, TwitterIcon, YoutubeIcon } from "../../../svgs";
import Card from "../../../Cards/Card";
import './ProductSocial.scss'
import { TickerCard } from "../../../ticker-card/TickerCard";
import { Facebook, Globes, Linkedin, Twitter, Youtube } from "../../../../../../../assets/svg";
import { CardTask } from "../../../card-Task/CardTask";

const socialData = [
    { id: 1, name: 'https://www.walacor.com/', iconname: 'globe', iconname2: <Globes /> },
    { id: 2, name: 'https://youtube.com/Walacor', iconname: 'youtube', iconname2: <Youtube /> },
    { id: 3, name: 'https://www.facebook.com/Walacor', iconname: 'facebook', iconname2: <Facebook /> },
    { id: 4, name: 'https://twitter.com/@walacorcorp', iconname: 'walacor', iconname2: <Twitter /> },
    { id: 5, name: 'https://linkedin.com/company/walacor', iconname: 'linkedin', iconname2: <Linkedin /> },
]
interface ISocialProps {

}


const SocialMediaLink = ({ socialData }) => {
    return (
        <div className="inner">
            {socialData.map((item, index) => (
                <div className="inner-wrapper" key={item.id}>
                    <a target="_blank" rel="noopener noreferrer" href={item.name}>
                        <span>{item.iconname2}</span>
                    </a>
                </div>
            ))}
        </div>
    );
};

function ProductSocial({ ...props }: ISocialProps) {
    return (
        <div className="social-card">
            <div className="title">Visit Walacor Online</div>
            <SocialMediaLink socialData={socialData} />
        </div>
    )
}

export default ProductSocial;