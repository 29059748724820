import * as React from 'react';

export function Crossplatform({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="2.7vw" height="2.0vw" viewBox="0 0 40 29" xmlns="http://www.w3.org/2000/svg">
            <g fill="#0078D4" fill-rule="nonzero">
                <path d="M37.143 5.714V1.43c0-.79-.64-1.429-1.429-1.429H4.286c-.79 0-1.429.64-1.429 1.429V20A2.857 2.857 0 0 0 0 22.857v1.429a4.286 4.286 0 0 0 4.286 4.285h32.857A2.857 2.857 0 0 0 40 25.714V8.571a2.857 2.857 0 0 0-2.857-2.857zM5.714 2.857h28.572v2.857h-7.143a2.857 2.857 0 0 0-2.857 2.857v12.272l-.586.586h-7.4l-1-1.015A1.429 1.429 0 0 0 14.286 20H5.714V2.857zM4.286 25.714c-.79 0-1.429-.64-1.429-1.428v-1.429H13.7l1 1.014c.27.268.635.417 1.014.415h8.572v1.428h-20zm32.857 0h-10V8.571h10v17.143z" />
                <path d="M32.857 21.429H31.43a1.429 1.429 0 0 0 0 2.857h1.428a1.429 1.429 0 1 0 0-2.857z" />
            </g>
        </svg>
    );
}
