import * as React from 'react';

export function Youtube({ fill = '#fff', width = 15, height = 15, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="65" height="65" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
            <g fill="#0078D4" fill-rule="nonzero">
                <path d="M30.814 28.295 41.733 35l-10.92 6.71z" />
                <path d="M35 0C15.67 0 0 15.67 0 35s15.67 35 35 35 35-15.67 35-35S54.33 0 35 0zm16.68 43.423c-.7 2.15-3.016 3.459-5.148 3.758-7.667.815-15.397.815-23.064 0-2.132-.3-4.448-1.607-5.148-3.758a43.722 43.722 0 0 1 0-16.841c.7-2.174 3.016-3.459 5.167-3.758a109.062 109.062 0 0 1 23.063 0c2.132.299 4.449 1.602 5.149 3.758a43.722 43.722 0 0 1-.019 16.841z" />
            </g>
        </svg>


    );
}
