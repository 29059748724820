import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleLoader } from '../../redux/actions/loader.action';

// Common Component
import FileListComponent from '../file/fileList';
import EnvelopeListComponent from '../envelope/envelopeList';

// Devextreme
import Tabs from 'devextreme-react/tabs';

// Media
import { useScreenSize } from '../../utils/media-query';

// Scss
import "../../../assets/themes/default/share.scss";
import "./List.scss";




const PageListComponent: React.FC<Props> = (props: Props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabsWidth, setTabsWidth] = useState<number | string>('auto');
    const { isXSmall } = useScreenSize();

    const tabs = useMemo(() => [
        { tagId: 1, name: "Envelope" },
        { tagId: 2, name: "File" }
    ], []);


    const itemRender = useCallback((params: any) => {
        return (
            <span>
                {params.name}
            </span>
        )
    }, []);

    const handleTab = useCallback((event: any) => {
        setSelectedTab(event.itemIndex)
    }, [])

    useEffect(() => {
        setTabsWidth(isXSmall ? '100%' : 'auto');
      }, []);

    return (
        <div className='view-wrapper view-wrapper-list-details'>
            <span className='lists'>Lists</span>
            <div className='dx-card details-card'>
                <Tabs
                    width={tabsWidth}
                    scrollByContent
                    showNavButtons={false}
                    dataSource={tabs}
                    selectedIndex={selectedTab}
                    itemRender={itemRender}
                    onItemClick={handleTab}
                    className='tabFileList'
                />
                {selectedTab === 0 ? <EnvelopeListComponent /> : null}
                {selectedTab === 1 ? <FileListComponent /> : null}
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageListComponent);

interface Props {
    toggleLoader: (show: boolean) => void;
}