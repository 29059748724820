import React from "react";
import { Wbincandescent } from "../../../svgs";
import './ProductTechnical.scss'
import Card from "../../../Cards/Card";
import { TickerCard } from "../../../ticker-card/TickerCard";
import { CardTask } from "../../../card-Task/CardTask";


interface ITechnicalProps {

}


const TechnicalItem = ({ text }) => {
    return (
        <div className="content">
            <Wbincandescent />
            <p className="content-title">{text}</p>
        </div>
    )
}

function ProductTechnical({ ...props }: ITechnicalProps) {
    return (
        <div className="technical-card">
            <div className="title">Technical Tips & Tricks</div>
            <div className="sub-title">Get the latest technical bulletins and news to help enhance your product experience.</div>
            <TechnicalItem text={'Get the latest technical bulletins and news to help enhance.'} />
            <TechnicalItem text={'Get the latest technical bulletins and news to help enhance.'} />
        </div>
    )
}

export default ProductTechnical;