import axios from 'axios';
import { CookieStorage } from 'cookie-storage';
import Toaster from '../../services/toaster.services';
const cookies = new CookieStorage();
export class AuthService {


    public setAuthorizationToken = (token: any) => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    }
    public loginAndSetToken = (userName: any, password: any): any => {
        return new Promise((resolve, reject) => {
            axios.post(`/ui/api/auth/login`, { userName, password })
                .then((res: any) => {
                    if (res?.data?.data) {
                        const date = new Date();
                        const days = 0.5;
                        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                        let expires = "expires=" + date.toUTCString();
                        document.cookie = "access_token" + "=" + res.data.data.api_token + ";" + expires + ";path=/";
                        document.cookie = "firstname" + "=" + res.data.data.user.firstName;
                        document.cookie = "lastname" + "=" + res.data.data.user.lastName;
                        cookies.removeItem('CanChangePassword');
                        this.setAuthorizationToken(res.data.data.api_token);
                        this.getUserRole(res.data.data.user.id, res.data.data.api_token);
                        resolve(res)
                    }
                    else {
                        const date = new Date();
                        const days = 0.5;
                        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                        let expires = "expires=" + date.toUTCString();
                        document.cookie = "access_token" + "=" + res.data.data.api_token + ";" + expires + ";path=/";
                        this.setAuthorizationToken(res.api_token);
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err);
                })
        })
    }
    public getUserRole = (uid: any, token: any): any => {
        return new Promise((resolve, reject) => {
            const headers: any = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ETId: 10,
                Authorization: token
            }
            axios.post(`/ui/api/user/getUserAssignRoles`, { UID: uid }, { headers: headers })
                .then((res: any) => {
                    if (res?.data?.data) {
                        cookies.setItem('CanChangePassword', res.data.data.canChangePassword);
                        resolve(res)
                    }
                    else {
                        resolve(res)
                    }
                }).catch(err => {
                    console.log(err, "errooorrr");
                    err.response.data.errors.forEach((response) => {
                        Toaster.errorToaster(response.message);
                      });
                    // reject(err);
                })
        })
    }
    public logout() {

    }



}