import React, { useState, forwardRef, useMemo, useCallback } from 'react';
import TextBox, { Button } from 'devextreme-react/text-box';
import Validator from 'devextreme-react/validator';
import { Template } from 'devextreme-react';
import { EyeClose, EyeOopen } from '../../../../../assets/svg';


interface PasswordTextBoxProps {
  value?: string,
  onValueChange: (value) => void,
  placeholder?: string,
  stylingMode?: 'outlined' | 'underlined' | 'filled',
  validators?: any[],
  onValueValidated?: (e: any) => void;
}

export const PasswordTextBox = forwardRef<Validator, PasswordTextBoxProps>(({
  value = '',
  onValueChange,
  placeholder,
  stylingMode = 'filled',
  validators,
  onValueValidated
}, ref) => {
  const [isPasswordMode, setIsPasswordMode] = useState(true);
  const buttonStylingMode: any = 'text';

  const validationRules = useMemo<any[] | undefined>(() =>
    validators || [{
      type: 'required',
      message: 'Password is required'
    }],
    [validators]);

  const switchMode = useCallback(() => {
    setIsPasswordMode(!isPasswordMode);
  }, [isPasswordMode]);

  const renderopeneyes = () => (
    <EyeOopen />
  )
  const rendercloseeyes = () => (
    <EyeClose />
  )
  const buttonOptions = useMemo(() =>
  ({
    visible: value?.length > 0,
    template: isPasswordMode ? "eyeOpen" : "eyeClose",
    hoverStateEnabled: false,
    activeStateEnabled: false,
    stylingMode: buttonStylingMode,
    onClick: switchMode
  }),
    [value, switchMode]);

  return <TextBox
    value={value}
    stylingMode={stylingMode}
    valueChangeEvent='keyup input change'
    placeholder={placeholder}
    mode={isPasswordMode ? 'password' : 'text'}
    onValueChange={onValueChange}
    inputAttr={{ autoComplete: 'chrome-off' }}
  >
    <Button
      name='today'
      location='after'
      options={buttonOptions}
    >
      <Template name={isPasswordMode ? "eyeOpen" : "eyeClose"} render={isPasswordMode ? renderopeneyes : rendercloseeyes} />
    </Button>
    <Validator ref={ref} validationRules={validationRules} onValidated={onValueValidated} />
  </TextBox>;
});
