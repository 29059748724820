import * as React from 'react';

export function Api({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="2.7vw" height="2.7vw" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <g fill="#0078D4" fill-rule="nonzero">
                <path d="M38.828 15.619h-2.615a16.613 16.613 0 0 0-1.652-3.984l1.85-1.85c.458-.458.458-1.2 0-1.658L31.873 3.59a1.172 1.172 0 0 0-1.657 0l-1.851 1.85a16.613 16.613 0 0 0-3.984-1.652V1.172C24.381.525 23.856 0 23.21 0h-6.418c-.648 0-1.172.525-1.172 1.172v2.615a16.614 16.614 0 0 0-3.984 1.652l-1.85-1.85a1.172 1.172 0 0 0-1.658 0L3.59 8.127a1.172 1.172 0 0 0 0 1.657l1.85 1.851a16.613 16.613 0 0 0-1.652 3.984H1.172C.525 15.619 0 16.143 0 16.79v6.418c0 .647.525 1.172 1.172 1.172h2.615a16.614 16.614 0 0 0 1.652 3.984l-1.85 1.85a1.172 1.172 0 0 0 0 1.658l4.538 4.538c.458.458 1.2.458 1.657 0l1.851-1.85a16.612 16.612 0 0 0 3.984 1.652v2.615c0 .647.524 1.172 1.172 1.172h6.418c.647 0 1.172-.525 1.172-1.172v-2.615a16.607 16.607 0 0 0 3.984-1.652l1.85 1.85c.458.458 1.2.458 1.658 0l4.538-4.538c.458-.458.458-1.2 0-1.658l-1.85-1.85a16.613 16.613 0 0 0 1.651-3.984h2.616c.647 0 1.172-.525 1.172-1.172v-6.418c0-.648-.525-1.172-1.172-1.172zm-1.172 6.419h-2.368c-.555 0-1.034.389-1.147.932a14.312 14.312 0 0 1-2.043 4.928 1.172 1.172 0 0 0 .152 1.47l1.676 1.676-2.882 2.882-1.676-1.676a1.172 1.172 0 0 0-1.47-.152 14.31 14.31 0 0 1-4.928 2.043 1.172 1.172 0 0 0-.932 1.147v2.368h-4.076v-2.368c0-.555-.389-1.034-.932-1.147a14.31 14.31 0 0 1-4.928-2.043 1.172 1.172 0 0 0-1.47.152l-1.676 1.676-2.882-2.882 1.676-1.676a1.172 1.172 0 0 0 .152-1.47A14.31 14.31 0 0 1 5.86 22.97a1.172 1.172 0 0 0-1.147-.932H2.344v-4.076h2.368c.555 0 1.034-.389 1.147-.932a14.31 14.31 0 0 1 2.043-4.928 1.172 1.172 0 0 0-.152-1.47L6.074 8.956l2.882-2.882 1.676 1.676a1.172 1.172 0 0 0 1.47.152A14.31 14.31 0 0 1 17.03 5.86c.543-.113.933-.592.933-1.147V2.344h4.075v2.368c0 .555.389 1.034.932 1.147a14.312 14.312 0 0 1 4.928 2.043c.464.304 1.078.24 1.47-.152l1.676-1.676 2.882 2.882-1.676 1.676a1.172 1.172 0 0 0-.152 1.47 14.31 14.31 0 0 1 2.043 4.928c.113.543.592.932 1.147.932h2.368v4.076z" />
                <path d="M20 8.594C13.71 8.594 8.594 13.71 8.594 20c0 6.29 5.117 11.406 11.406 11.406 6.29 0 11.406-5.117 11.406-11.406 0-6.29-5.117-11.406-11.406-11.406zm0 20.468c-4.997 0-9.063-4.065-9.063-9.062 0-4.997 4.066-9.063 9.063-9.063 4.997 0 9.063 4.066 9.063 9.063 0 4.997-4.066 9.063-9.063 9.063z" />
                <path d="M15.293 17.959h6.768l-.357.394a1.172 1.172 0 0 0 1.735 1.575l2.136-2.353a1.172 1.172 0 0 0 0-1.575l-2.136-2.354a1.172 1.172 0 1 0-1.735 1.575l.357.394h-6.768a1.172 1.172 0 0 0 0 2.344zM24.707 22.041h-6.768l.357-.394a1.172 1.172 0 0 0-1.735-1.575l-2.136 2.353a1.172 1.172 0 0 0 0 1.575l2.136 2.354a1.169 1.169 0 0 0 1.655.08c.48-.435.515-1.176.08-1.655l-.357-.394h6.768a1.172 1.172 0 0 0 0-2.344z" />
            </g>
        </svg>

    );
}
