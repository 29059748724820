import * as React from 'react';

export function Twitter({ fill = '#fff', width = 15, height = 15, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="65" height="65" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 0C15.67 0 0 15.67 0 35s15.67 35 35 35 35-15.67 35-35S54.33 0 35 0zm17.43 24.408a14.778 14.778 0 0 1-2.997 3.293 1.28 1.28 0 0 0-.46.999v.115a20.99 20.99 0 0 1-1.355 7.304 21.391 21.391 0 0 1-3.983 6.678A19.678 19.678 0 0 1 35 48.503a21.599 21.599 0 0 1-6.788 1.054c-3.656 0-7.247-.968-10.408-2.804a.599.599 0 0 1-.286-.664.576.576 0 0 1 .562-.432h.875c2.778.004 5.495-.82 7.802-2.368a7.576 7.576 0 0 1-5.987-5.144.396.396 0 0 1 .46-.51c.42.078.845.118 1.271.119h.162a7.562 7.562 0 0 1-4.408-6.959.396.396 0 0 1 .585-.345 6.65 6.65 0 0 0 1.723.668 7.562 7.562 0 0 1-1.22-9.17.396.396 0 0 1 .648-.05 19.803 19.803 0 0 0 13.59 7.263h.037a.286.286 0 0 0 .277-.309 8.009 8.009 0 0 1 .175-2.763 7.327 7.327 0 0 1 4.927-5.269 8.1 8.1 0 0 1 2.39-.368 7.534 7.534 0 0 1 4.993 1.884c.178.16.41.248.649.248a.99.99 0 0 0 .23-.032 13.954 13.954 0 0 0 3.684-1.52.391.391 0 0 1 .576.46 7.474 7.474 0 0 1-1.985 3.165 13.53 13.53 0 0 0 2.413-.816.396.396 0 0 1 .484.567z" fill="#0078D4" fill-rule="nonzero" />
        </svg>


    );
}
