import * as React from 'react';

export function Shares({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M14.614 17.323a.63.63 0 0 1-.324-.09L3.06 10.508A.593.593 0 0 1 2.768 10c0-.207.11-.399.292-.507l11.23-6.727a.63.63 0 0 1 .85.193c.18.28.09.648-.2.822L4.557 10l10.383 6.219c.29.174.38.541.2.822a.622.622 0 0 1-.526.282z" fill="#19CFFC" />
                <path d="m3.06 10.507 11.23 6.727a.63.63 0 0 0 .85-.193.586.586 0 0 0-.2-.822L4.557 10h-1.79c0 .207.111.399.293.507z" fill="#4AB8F7" />
                <path d="M3.385 6.726C1.52 6.726 0 8.195 0 10s1.519 3.274 3.385 3.274c1.866 0 3.385-1.469 3.385-3.274S5.251 6.726 3.385 6.726zM14.615 0c-1.867 0-3.385 1.469-3.385 3.274s1.518 3.273 3.385 3.273C16.482 6.547 18 5.08 18 3.274 18 1.469 16.482 0 14.615 0z" fill="#FF5E95" />
                <g fill="#FF3F5C">
                    <path d="M14.615 13.453c-1.867 0-3.385 1.468-3.385 3.273 0 1.805 1.518 3.274 3.385 3.274C16.482 20 18 18.531 18 16.726s-1.518-3.273-3.385-3.273zM3.385 13.274c1.866 0 3.385-1.469 3.385-3.274H0c0 1.805 1.519 3.274 3.385 3.274z" />
                </g>
            </g>
        </svg>
    );
}
