import * as React from 'react';

export function Keys({ fill = '#fff', width = 15, height = 15, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M18.11 1.89a6.43 6.43 0 0 0-9.105 0 6.409 6.409 0 0 0-1.57 6.539c-2.649 2.636-4.622 4.61-7.26 7.259a.55.55 0 0 0-.175.41v3.316c0 .164.059.305.176.41a.532.532 0 0 0 .41.176h3.316a.55.55 0 0 0 .41-.176c.305-.293.528-.527.833-.82a.57.57 0 0 0 .164-.48c-.036-.329-.07-.575-.106-.891.445-.047.785-.082 1.242-.117a.598.598 0 0 0 .528-.528c.035-.445.07-.797.117-1.242.316.047.562.07.89.106a.578.578 0 0 0 .645-.575V14.18h1.078c.152 0 .305-.04.41-.157.54-.539.92-.907 1.458-1.458a6.409 6.409 0 0 0 6.54-1.57 6.43 6.43 0 0 0 0-9.105zm-1.652 4.136a1.747 1.747 0 0 1-2.484 0 1.747 1.747 0 0 1 0-2.484 1.747 1.747 0 0 1 2.484 0c.691.68.691 1.805 0 2.484z" fill="#FCBF29" />
            </g>
        </svg>
    );
}
