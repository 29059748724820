import React from "react";
import { TextBox as DevextremeTextBox, ITextBoxOptions } from 'devextreme-react/text-box';
import './InputBox.scss';


export interface ITextBoxProps extends ITextBoxOptions {
    width?: number;
    height?: number;
    className?: string;
    placeholder?: string;
    color?: string;
    backgroundColor?: string;
    style?: any;
    name?: any;
    value?: any;
    onFocusOut?: any;
    onValueChange?: () => void;
    focusStateEnabled?:boolean;
    activeStateEnabled?:boolean;
}

function InputBox({ width, height, className, placeholder, color, backgroundColor, style, name, value, onFocusOut, onValueChange,focusStateEnabled=false,activeStateEnabled=false, ...props }: ITextBoxProps) {
    const styleSheet = { color: color, backgroundColor: backgroundColor, ...style };
    return (
        <DevextremeTextBox
            width={width}
            height={height}
            className={className}
            placeholder={placeholder}
            style={styleSheet}
            name={name}
            value={value}
            onFocusOut={onFocusOut}
            focusStateEnabled={focusStateEnabled}
            activeStateEnabled={activeStateEnabled}
            {...props}
        />
    )
}

export default InputBox; 