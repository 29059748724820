import * as React from 'react';

export function Building({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
                <path d="M20 7.258a.586.586 0 0 1-.586.586h-.188v11.57h-8.638V6.672h8.826c.324 0 .586.262.586.586z" fill="#EE5454" />
                <path d="M20 7.258a.586.586 0 0 1-.586.586h-8.826V6.672h8.826c.324 0 .586.262.586.586z" fill="#FF9C20" />
                <path fill="#1B6AA6" d="M11.76.586v18.828H.822L.83.586z" />
                <path fill="#173755" d="M6.28.586h5.48v18.828H6.28z" />
                <path d="M6.877 2.96v.986a.586.586 0 0 1-.586.586h-.01a.585.585 0 0 1-.576-.586v-.985c0-.32.256-.58.576-.586h.01c.323 0 .586.262.586.586zM3.869 2.375a.586.586 0 0 0-.586.586v.985a.586.586 0 1 0 1.172 0v-.985a.586.586 0 0 0-.586-.586z" fill="#FFF" />
                <path d="M8.713 2.375a.586.586 0 0 0-.586.586v.985a.586.586 0 1 0 1.172 0v-.985a.586.586 0 0 0-.586-.586z" fill="#D9EEFF" />
                <path d="M6.871 6.3v.985a.586.586 0 0 1-.585.586H6.28a.586.586 0 0 1-.581-.586V6.3c0-.323.26-.584.58-.586h.006c.323 0 .585.262.585.586zM3.864 5.714a.586.586 0 0 0-.586.585v.986a.586.586 0 0 0 1.172 0V6.3a.586.586 0 0 0-.586-.585z" fill="#FFF" />
                <path d="M8.708 5.714a.586.586 0 0 0-.586.585v.986a.586.586 0 0 0 1.172 0V6.3a.586.586 0 0 0-.586-.585z" fill="#D9EEFF" />
                <path d="M6.871 9.615v.985a.586.586 0 0 1-.585.586H6.28A.586.586 0 0 1 5.7 10.6v-.985c0-.322.26-.583.58-.586h.006c.323 0 .585.262.585.586zM3.864 9.029a.586.586 0 0 0-.586.586v.985a.586.586 0 1 0 1.172 0v-.985a.586.586 0 0 0-.586-.586z" fill="#FFF" />
                <path d="M8.708 9.029a.586.586 0 0 0-.586.586v.985a.586.586 0 1 0 1.172 0v-.985a.586.586 0 0 0-.586-.586z" fill="#D9EEFF" />
                <path d="M6.866 12.954v.986a.586.586 0 0 1-1.171 0v-.986a.586.586 0 1 1 1.171 0zM3.859 14.525a.586.586 0 0 0 .586-.585v-.986a.586.586 0 1 0-1.172 0v.986c0 .323.262.585.586.585z" fill="#FFF" />
                <path d="M8.702 14.525a.586.586 0 0 0 .586-.585v-.986a.586.586 0 1 0-1.172 0v.986c0 .323.263.585.586.585z" fill="#D9EEFF" />
                <path d="M6.866 16.266v2.901a.586.586 0 1 1-1.171 0v-2.901a.586.586 0 1 1 1.171 0zM3.859 15.68a.586.586 0 0 0-.586.586v2.901a.586.586 0 1 0 1.172 0v-2.901a.586.586 0 0 0-.586-.586z" fill="#FFF49C" />
                <path d="M8.702 15.68a.586.586 0 0 0-.586.586v2.901a.586.586 0 1 0 1.172 0v-2.901a.586.586 0 0 0-.586-.586z" fill="#FEE276" />
                <g fill="#D9EEFF">
                    <path d="M6.28 12.368v2.157c.543 0 .983-.262.983-.585v-.986c0-.324-.44-.586-.982-.586zM6.289 5.714h-.008V7.87h.008c.543 0 .982-.262.982-.586V6.3c0-.324-.44-.586-.982-.586zM6.289 9.029h-.008v2.157h.008c.543 0 .982-.262.982-.586v-.985c0-.324-.44-.586-.982-.586zM6.298 2.375h-.017v2.157h.017c.542 0 .983-.262.983-.586v-.985c0-.324-.44-.586-.983-.586z" />
                </g>
                <path d="M6.866 16.266v2.901a.586.586 0 0 1-.585.586V15.68c.323 0 .585.262.585.586z" fill="#FEE276" />
                <path d="M16.12 9.029a.586.586 0 0 0-.585.586v.985a.586.586 0 1 0 1.171 0v-.985a.586.586 0 0 0-.586-.586zM13.699 9.029a.586.586 0 0 0-.586.586v.985a.586.586 0 1 0 1.172 0v-.985a.586.586 0 0 0-.586-.586zM16.115 14.525a.586.586 0 0 0 .586-.585v-.986a.586.586 0 1 0-1.172 0v.986c0 .323.263.585.586.585zM13.693 14.525a.586.586 0 0 0 .586-.585v-.986a.586.586 0 1 0-1.172 0v.986c0 .323.263.585.586.585z" fill="#D9EEFF" />
                <path d="M16.118 15.68a.586.586 0 0 0-.586.586v2.855a.586.586 0 0 0 1.172 0v-2.855a.586.586 0 0 0-.586-.586zM13.696 15.68a.586.586 0 0 0-.586.586v2.855a.586.586 0 0 0 1.172 0v-2.855a.586.586 0 0 0-.586-.586z" fill="#FEE276" />
                <path d="M20 19.414a.586.586 0 0 1-.586.586H.586a.586.586 0 1 1 0-1.172h18.828c.324 0 .586.263.586.586z" fill="#00DB7E" />
                <path d="M20 19.414a.586.586 0 0 1-.586.586H6.281v-1.172h13.133c.324 0 .586.263.586.586z" fill="#00A863" />
                <path d="M12.571.586a.586.586 0 0 1-.586.586H.585A.586.586 0 1 1 .586 0h11.4c.324 0 .586.262.586.586z" fill="#D9EEFF" />
                <path d="M12.571.586a.586.586 0 0 1-.586.586H6.281V0h5.704c.324 0 .586.262.586.586z" fill="#B0E3FC" />
            </g>
        </svg>
    );
}
