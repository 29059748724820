import axios from 'axios';
import setAuthToken from '../../services/setAuthToken';
import appConstants from '../../core/constants/appConstants';
import jwt_decode from 'jwt-decode';

import { AUTH_SET_CURRENT_USER, AUTH_CHANGE_PASSWORD } from '../types/authTypes';
import isEmpty from '../../utils/validation/is-empty';




// Set logged in user
export const setCurrentUser = (decoded: any) => {
  return {
    type: AUTH_SET_CURRENT_USER,
    payload: decoded
  };
};

export const changePassword = (resp: any) => {
  return {
    type: AUTH_CHANGE_PASSWORD,
    payload: resp
  };
};

// Log user out
export const logoutUser = () => (dispatch: any) => {
  dispatch(setCurrentUser({
    isAuthenticated: false,
    isPasswordChange: false,
    user: {},
    admin: false
  }));
};
