import * as React from 'react';

export function LockOpenIcons({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg id={id} className={className} style={{ cursor: 'pointer' }} width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g fill="none" fill-rule="evenodd">
                <rect fill="#0078D4" width="30" height="30" rx="5" />
                <path d="M15.095 7a3.773 3.773 0 0 1 3.81 3.81v1.523h.762c.838 0 1.523.686 1.523 1.524v7.62c0 .837-.685 1.523-1.523 1.523h-9.143A1.528 1.528 0 0 1 9 21.476v-7.619c0-.838.686-1.524 1.524-1.524h6.933V10.81a2.374 2.374 0 0 0-2.362-2.362 2.374 2.374 0 0 0-2.362 2.362h-1.447A3.773 3.773 0 0 1 15.096 7zm0 9.143c-.838 0-1.524.686-1.524 1.524s.686 1.523 1.524 1.523 1.524-.685 1.524-1.523c0-.838-.686-1.524-1.524-1.524z" fill="#FFF" fill-rule="nonzero" />
            </g>
        </svg>


    );
}
