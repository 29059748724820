import { ApiEndPoints } from "../../models/api-endpoint";
import RestApiService from "../../services/http-services";
import appConstants from "../../core/constants/appConstants";


const getOrganization = async (body: any) => {
    return RestApiService.invoke(ApiEndPoints.GET_ORGANIZATION_INFO, null, body, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.SV
    })
}
const getOrganizationList = async (body: any) => {
    return RestApiService.invoke(ApiEndPoints.GET_ORGANIZATION_LIST, null, body, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.SV
    })
}

const addOrganization = async (orgData: any) => {
    const body = {
        "ETId": appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        "Data": orgData
    }
    return RestApiService.invoke(ApiEndPoints.CREATE_ORGANIZATION, null, body, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.SV
    })
}
const updateOrganization = async (orgData: any) => {
    const body = {
        "ETId": appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        "Data": orgData
    }
    return RestApiService.invoke(ApiEndPoints.UPDATE_ORGANIZATION, null, body, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.SV
    })
}

const getDefaultOrganizations = async (query: any) => {
    return RestApiService.invoke(ApiEndPoints.GET_CURRENT_ORG, null, query, null, {
        ETId: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.ETId,
        SV: appConstants.SYSTEM_GENERATED_ETIDS.ORGANIZATION.SV
    })
}

export { addOrganization, getDefaultOrganizations, getOrganization, getOrganizationList, updateOrganization }; 
