import React, { useEffect } from 'react';

// MUI
import SearchBar from 'material-ui-search-bar';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Tooltip, Toolbar, Typography, Button } from '@material-ui/core';

// Devextreme
import { DropDownButton, Tooltip as Tooltips } from 'devextreme-react';

// Common Component
import Buttons from '../commonui/Button';
import Icon from '../commonui/Icon/Icon';
import SelectBox from '../commonui/SelectBox/SelectBox';

// Scss
import '../../../../app.config.scss';
import "../../../../assets/themes/default/share.scss";
import "./sub-header.scss";
const SubHeaderComponent = (props: SubHeaderProps) => {

    const [searchData, setSearchData] = React.useState<any>(props.searchKey);
    const [userStatus, setUserStatus] = React.useState('');
    const [selectedFont, setSelectedFont] = React.useState('Small');
    const fontOptions = [
        { value: 'Small', label: 'Small', fontSize: 12 },
        { value: 'Medium', label: 'Medium', fontSize: 16 },
        { value: 'Large', label: 'Large', fontSize: 20 },
    ];
    useEffect(() => {
        if (props.showFontSizeDDL) {
            let selectedFontSize = document.documentElement.style.getPropertyValue('--font-text-size');
            selectedFontSize === '20px' ? setSelectedFont('Large') : selectedFontSize === '16px' ? setSelectedFont('Medium') : setSelectedFont('Small')
        }
    }, [searchData]);

    const handleChangeSearch = (text: any) => {
        setSearchData(text);
        props.onSearchData(text);
    };
    useEffect(() => {
        if (userStatus) {
            props.filterUserStatus(userStatus);
        }
    }, [userStatus]);

    const onChangeUserStatus = (status: any) => {
        setUserStatus(status);
    }
    const onChangeFontSize = (fontSize: any) => {
        setSelectedFont(fontSize);
        switch (fontSize) {
            case 'Large':
                return document.documentElement.style.setProperty('--font-text-size', '20px');
            case 'Medium':
                return document.documentElement.style.setProperty('--font-text-size', '16px');
            default:
                return document.documentElement.style.setProperty('--font-text-size', '12px');
        }

    }

    return (
        <div className="SubHeader dx-theme-background-color">
            <Toolbar className='subHeaderContainer'>
                <div className="headDiv">
                    {props.isbackEnabled && <Icon size={'25px'} iconName='arrowleft' onClick={props.onBackButtonClick} />}
                    <Typography className="sub-header-text" >
                        {props.headingText}
                    </Typography>
                </div>
                <div className="subheader-filter">
                    {props.showFontSizeDDL &&
                        <div className="user-filter">
                            <p className="statuslabel">Font Size: </p>
                            <div className="filter-ddl">
                                <DropDownButton
                                    stylingMode='outlined'
                                    items={fontOptions}
                                    displayExpr="label"
                                    keyExpr='value'
                                    text={selectedFont}
                                    onItemClick={(e: any) => onChangeFontSize(e.itemData.value)}
                                />
                            </div>
                        </div>
                    }
                    {props.showFilter &&
                        <div className="user-filter">
                            <p className="statuslabel">{props.filterHeading} </p>
                            <div className="filter-ddl">
                                <SelectBox
                                    stylingMode='outlined'
                                    dataSource={props.filterArray}
                                    name={''}
                                    value={props.userStatus}
                                    onValueChanged={(e) => onChangeUserStatus(e.value)}
                                    className='selectAllFilter'
                                />
                            </div>
                        </div>
                    }
                    {props.showSearch &&
                        <SearchBar
                            className={'search-bar'}
                            placeholder={`Search ${props.searchLabel}`}
                            value={props.searchKey}
                            onChange={handleChangeSearch}
                            onCancelSearch={() => props.onCancelSearch(true)}
                        />
                    }
                    {props.showBtn3 && <>
                        <Tooltips
                            target='#my-button3'
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position={'bottom'}
                            hideOnOutsideClick={true}
                            contentRender={() => <span>{props.btnText3}</span>}
                        />
                        <Buttons
                            id='my-button3'
                            className={`btn-primarys ${props.blurBtn === false && "compare"}`}
                            type='normal'
                            stylingMode='outlined'
                            text={props.btnText3}
                            onClick={() => props.onButtonClick3(true)}
                        />
                    </>}

                    {props.showBtn && <>
                        <Tooltips
                            target='#my-button'
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position={'bottom'}
                            hideOnOutsideClick={true}
                            contentRender={() => <span>{props.btnText}</span>}
                        />
                        <Buttons
                            id='my-button'
                            className={"btn-primary"}
                            type='default'
                            text={props.btnText}
                            color='#fff'
                            onClick={() => props.onButtonClick(true)}
                            disabled={props.blurBtn}
                        />
                    </>}

                    {props.showBtn2 && <>
                        <Tooltips
                            target='#my-button2'
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position={'bottom'}
                            hideOnOutsideClick={true}
                            contentRender={() => <span>{props.btnText2}</span>}
                        />
                        <Buttons
                            id='my-button2'
                            className={"btn-primary"}
                            type='default'
                            text={props.btnText2}
                            color='#fff'
                            onClick={() => props.onButtonClick2(true)}
                            disabled={props.blurBtn}
                        />
                    </>}

                    {props.fromFileList && <form
                        onSubmit={(e: any) => e.preventDefault()}
                    > <div className="file-section">
                            <span className="file-name">{props.fileDetails}</span>
                            <input
                                type="file"
                                className={"hidefile"}
                                onChange={props.onFileChange(true)}
                                id="icon-button-file"
                            />
                            <label htmlFor="icon-button-file">
                                <Tooltip title="Choose File" placement="bottom" key={"choosefile1"}>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        size="large"

                                        className={"btn-primary"}
                                    >
                                        <span className="labelspan">Choose File</span>
                                        <InsertDriveFileIcon />
                                    </Button>
                                </Tooltip>
                            </label>
                        </div>
                    </form>}
                </div>
            </Toolbar>

        </div>
    );

}

export default React.memo(SubHeaderComponent);

interface SubHeaderProps {
    headingText: string;
    btnText?: string;
    searchLabel?: string;
    showBtn?: boolean;
    showSearch?: boolean;
    onButtonClick?: any;
    fromFileList?: boolean;
    fileDetails?: any;
    onFileChange?: any;
    onSearchData?: any;
    onCancelSearch?: any;
    searchKey?: any;
    userStatus?: any;
    filterUserStatus?: any;
    fontSizes?: any;
    showFilter: boolean;
    showFontSizeDDL: boolean;
    filterHeading?: string;
    filterArray?: any;
    btnText2?: string;
    showBtn2?: boolean;
    onButtonClick2?: (arg: any) => void;
    btnText3?: string;
    showBtn3?: boolean;
    onButtonClick3?: (arg: any) => void;
    isbackEnabled?: boolean;
    onBackButtonClick?: () => void;
    blurBtn?: boolean
}
SubHeaderComponent.defaultProps = {
    showFilter: false,
    showFontSizeDDL: false,
    showBtn2: false,
    showBtn3: false,
    isbackEnabled: false,
    blurBtn: false
}