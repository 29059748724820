import * as React from 'react';
export function EyeClose({ fill = '#fff', width = 15, height = 15, className, color, id, onClick, ...props }: any) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Group 2</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="19-Walacor-Dashboard-Update_v2" transform="translate(-1230.000000, -268.000000)">
                    <g id="Group-2" transform="translate(1230.000000, 268.000000)">
                        <rect id="Rectangle-Copy-9" x="0" y="0" width="20" height="20" rx="3" fill="#0078D4"></rect>
                        <g id="hidden" transform="translate(5.000000, 7.000000)" fill="#fff" fill-rule="nonzero">
                            <path d="M8.19774648,6.50489537 L1.80192902,0.109273224 L1.8005618,0.107710696 C1.70635923,0.0142757002 1.56946549,-0.0218053221 1.44144712,0.0130590006 C1.31342876,0.0479233233 1.2137348,0.148436271 1.17991901,0.276735589 C1.14610322,0.405034908 1.18330301,0.541628878 1.27750558,0.635063874 L1.83747653,1.19503482 C1.15838435,1.66013135 0.573853256,2.2500312 0.114984795,2.93334715 C-0.0383282652,3.15970469 -0.0383282652,3.45661239 0.114984795,3.68296993 C1.2862948,5.41659467 3.14316393,6.43223783 5.00003307,6.41954229 C5.60150337,6.42172085 6.19896394,6.3215937 6.76686153,6.12344324 L7.67332305,7.02990476 C7.81860951,7.17049973 8.04970512,7.16892624 8.19306363,7.02636594 C8.33642214,6.88380564 8.33928591,6.65272242 8.19950433,6.50665322 L8.19774648,6.50489537 Z M5.00003307,5.41151875 C4.28519479,5.41125238 3.61933602,5.04824867 3.23183263,4.44755328 C2.84432924,3.84685789 2.78813809,3.09056287 3.08261597,2.43919769 L3.66758737,3.02416909 C3.57185527,3.47528115 3.71083906,3.94420002 4.03692703,4.27028799 C4.363015,4.59637596 4.83193388,4.73535976 5.28304594,4.63962765 L5.86801733,5.22459905 C5.59537294,5.34822205 5.29939475,5.41195992 5.00003307,5.41151875 L5.00003307,5.41151875 Z" id="Shape"></path>
                            <path d="M9.88508134,2.93237057 C8.71377133,1.19776925 6.8569022,0.182126089 5.00003307,0.194821628 C4.39856276,0.192643066 3.80110219,0.292770218 3.2332046,0.490920672 L4.13165817,1.38937423 C4.92994751,1.02811214 5.8685355,1.19911483 6.48812253,1.81870186 C7.10770956,2.43828889 7.27871225,3.37687688 6.91745016,4.17516622 L8.16239429,5.42011036 C8.84160251,4.95508798 9.4262106,4.36517732 9.88508134,3.68179803 C10.0383062,3.45548468 10.0383062,3.15868392 9.88508134,2.93237057 L9.88508134,2.93237057 Z" id="Path"></path>
                            <path d="M5.00003307,1.94504823 C4.90491892,1.94504823 4.81006634,1.95501027 4.71702019,1.97473626 L6.33247876,3.58999951 C6.41779103,3.18806436 6.3171096,2.76909564 6.0584828,2.44981171 C5.79985599,2.13052779 5.41092241,1.94504823 5.00003307,1.94504823 L5.00003307,1.94504823 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}