import * as React from 'react';

export function Demo({ fill = '#000', width = 25, height = 25, className, id, onClick }: any) {
    return (
        <svg width="2.6vw" height="2.7vw" viewBox="0 0 38 40" xmlns="http://www.w3.org/2000/svg">
            <g fill="#0078D4" fill-rule="nonzero">
                <path d="M7.031 22.357V17.67a3.52 3.52 0 0 0-3.515-3.516H0v11.72h3.516a3.52 3.52 0 0 0 3.515-3.517zm-4.687-5.859h1.172c.646 0 1.172.525 1.172 1.172v4.687c0 .647-.526 1.172-1.172 1.172H2.344V16.5zM16.406 16.498v-2.344H9.375v11.72h7.031v-2.345H11.72v-2.343h2.344v-2.344h-2.344v-2.344h4.687zM28.125 25.873V14.154h-3.068l-1.62 3.239-1.619-3.239H18.75v11.72h2.344v-7.927l2.344 4.687 2.343-4.687v7.926zM33.984 14.154a3.52 3.52 0 0 0-3.515 3.516v4.687a3.52 3.52 0 0 0 3.515 3.516 3.52 3.52 0 0 0 3.516-3.516V17.67a3.52 3.52 0 0 0-3.516-3.516zm1.172 8.203a1.173 1.173 0 0 1-2.343 0V17.67a1.173 1.173 0 0 1 2.343 0v4.687zM32.058 30.519l-11.55 6.67a3.531 3.531 0 0 1-3.516 0l-11.55-6.67a3.51 3.51 0 0 1-1.378-1.455l-2.09 1.062A5.848 5.848 0 0 0 4.27 32.55l11.55 6.67a5.87 5.87 0 0 0 2.93.781 5.87 5.87 0 0 0 2.93-.782l11.55-6.67a5.849 5.849 0 0 0 2.296-2.422l-2.09-1.062a3.51 3.51 0 0 1-1.378 1.455zM5.442 9.48l11.55-6.669a3.531 3.531 0 0 1 3.516 0l11.55 6.67a3.51 3.51 0 0 1 1.378 1.454l2.09-1.062a5.849 5.849 0 0 0-2.296-2.422L21.68.78a5.885 5.885 0 0 0-5.86 0L4.27 7.451a5.848 5.848 0 0 0-2.296 2.422l2.09 1.062a3.51 3.51 0 0 1 1.378-1.454z" />
                <path d="m25.195 10.932 1.172-2.03-7.617-4.4L11.133 8.9l1.172 2.03 6.445-3.722z" />
            </g>
        </svg>

    );
}
