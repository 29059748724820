import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from '../src/app/redux/store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'


const theme = createMuiTheme({
    palette: {
        primary: {
            // main: '#044C92',
            main: '#0078D4',
        },
        secondary: {
            main: '#fff',
        },
    },
    typography: {
        "fontFamily": "Helvetica"
    },

});


ReactDOM.render(<Provider store={store}><MuiThemeProvider theme={theme}>
    <App />
</MuiThemeProvider></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
