import { SIDEBAR_STATUS } from '../types/sidebarTypes';
const initialState = {
    isSidebarShow : true
}
const appSidebarReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case SIDEBAR_STATUS:
        state =  {...state, isSidebarShow: action.payload};
        break;
    }
    return state;
}

export default appSidebarReducer;
