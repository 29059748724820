import React, { useState, useEffect, useCallback, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Formik
import * as yup from 'yup';
import { Formik, FormikHelpers } from "formik";

// Devextreme 
import DataGrid, { Scrolling } from 'devextreme-react/data-grid';

//services local
import { toggleLoader } from "../../../../redux/actions/loader.action";
import {  getOrganizationList, updateOrganization } from "../../../../api/organization/organization";

//common components
import Sharing from "../../../../shared/components/commonui/Sharing";
import Button from "../../../../shared/components/commonui/Button";
import ModalBox from "../../../../shared/components/commonui/Modal/ModalBox";
import Toaster from '../../../../services/toaster.services';
import { FormPopup } from "../../../../shared/components/commonui/form-popup/FormPopup";


//style files
import './organization.scss';
import InputWithLabel from "../../../../shared/components/commonui/HigherOrderComponents/InputWithLabel";


const OrganizationTable = (props: IOrganizationTableProps) => {
    const [organizationList, setOrganizationList] = useState([]);
    const [orderIndexedFields, setOrderIndexedFields] = useState([{ 'orderBy': 'signedAt', 'order': 'desc', 'isActive': false }]);
    const [order, setOrder] = useState<number>(-1);
    const [orderBy, setOrderBy] = useState<string>("CreatedAt");
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const [prePage, setPrePage] = useState<any>();
    const [nextPage, setNextPage] = useState<any>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [orgEditVisible, setOrgEditVisible] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    const [orgInitialValue, setorgInitialValue] = useState<any>({
        UID: "",
        ORGName: "",
        ChainAddress: ""
    });
    const tabColumnList = useMemo(() => [
        { name: "Organization Name", db: "ORGName" },
        { name: "Organization Id", db: "ORGId" },
        { name: "Chain Address", db: "ChainAddress" }
    ], []);

    const validationSchema = yup.object().shape({
        ORGName: yup.string().required('Organization Name is required'),
        ChainAddress: yup.string().required('Chain Address is Required')
    });

    // organizationDataCreate
    const OrganizationDataCreate = useCallback((OrgValues: any) => {
        props.setActiveTab(1);
        props.setisVisisblepopup(true);
        props.actionButtonHanlder(OrgValues);
    }, []);

    const OrganizationContract = useCallback(() => {

    }, []);

    const organizationEditFormHandler = (orgvalues: any) => {
        setorgInitialValue((prevValues: any) => {
            return { ORGName: orgvalues.ORGName, ChainAddress: orgvalues.ChainAddress, UID: orgvalues.UID }
        });

    }

    const organizationEditForm = useCallback((values: any) => { organizationEditFormHandler(values) }, []);

    const organizationVisibleEdit = useCallback((value: any) => {
        setOrgEditVisible(value)
    }, [])

    // Sorting Data
    const onSortingData = useCallback((orderBy: any, order: any, data: any) => {
        if (orderBy && order && data) {
            setOrder(order === 'desc' ? -1 : 1);
            setOrderBy(orderBy);
            setOrderIndexedFields(data);
        }
    }, [])


    // Previous Page
    const goToPreviousPage = useCallback((page: any) => {
        setCurrentPageNo(page);
    }, [])

    useEffect(() => {
        if (prePage) {
            goToPreviousPage(prePage);
        }
    }, [prePage]);

    // Goto Next Page
    const goToNextPage = useCallback((page: any) => {
        setCurrentPageNo(page);
    }, [])

    useEffect(() => {
        if (nextPage) {
            goToNextPage(nextPage);
        }
    }, [nextPage]);


    // Change Page Size
    const changePageSize = useCallback((value: any) => {
        setPageSize(value);
        setCurrentPageNo(1);
    }, [])

    useEffect(() => {
        if (pageSize) {
            changePageSize(pageSize);
        }
    }, [pageSize]);


    // IsLessThenElement
    const isLessthanTotalElement = useCallback(() => {
        if ((currentPageNo * pageSize) === (totalPage - 1))
            return true;
        else
            return false;
    }, [currentPageNo, pageSize, totalPage])

    const updateOrganizationDetails = async (dataObject: any,) => {
        props.toggleLoader(true);
        const orgData = [{
            "ChainAddress": dataObject["ChainAddress"],
            "ORGName": dataObject["ORGName"],
            "UID": dataObject["UID"]
        }]

        try {
            const addOrganizationResult = await updateOrganization(orgData);
            if (addOrganizationResult.status == 200) {
                if (addOrganizationResult?.data?.data?.ES > 20) {
                    Toaster.successToaster("createOrganizationAndContract", "Updated organization");
                    setForceUpdate((prev) => !prev);
                }
                else {
                    Toaster.errorToaster("Failed updating organization")
                }
            }
            props.toggleLoader(false);
        }
        catch (error: any) {
            props.toggleLoader(false);
            if (error?.response?.data?.errors?.length > 0) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                    Toaster.errorToaster(error.response.data.errors[i]?.message);
                }
            }
        }
        handleEditVisiblePopUp();
    };



    const getOrganizations = (query: any) => {
        props.toggleLoader(true);
        getOrganizationList(query).then((res) => {
            props.toggleLoader(false);
            setOrganizationList(res.data.data);
            setTotalRecord(res.data.total);
            if (parseInt(res.data.total) % pageSize === 0) {
                setTotalPage((parseInt(res.data.total) / pageSize));
            }
            else {
                setTotalPage((Math.floor(parseInt(res.data.total) / pageSize)) + 1);
            }
            props.toggleLoader(false);
        }).catch((err) => {
            props.toggleLoader(false);
            console.log(err, "Error data")
        })
    }

    useEffect(() => {
        if (pageSize && currentPageNo) {
            getOrganizations({ pageNo: currentPageNo, pageSize: pageSize })
        }
    }, [pageSize, currentPageNo, props.forceUpdate]);

    useEffect(() => {
        if (pageSize && currentPageNo) {
            getOrganizations({ pageNo: currentPageNo, pageSize: pageSize })
        }
    }, [props.forceUpdate, forceUpdate]);

    const orgTable = ({ data }) => {
        return (
            <tbody>
                <tr>
                    <td className="td-title">ORG Id</td>
                    <td>{data.ORGId}</td>
                </tr>

                <tr>
                    <td className="td-title">Chain Address</td>
                    <td>{data.ChainAddress}</td>
                </tr>

                <tr>
                    <td className="td-title">ORG Name</td>
                    <td>{data.ORGName}</td>
                </tr>
            </tbody>

        )
    }

    const confirmOrganization = () => {
        props.handleOrganizationDetails(props.importedOrgDetails)
    }

    const handleOrganizationSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
        updateOrganizationDetails(values);
        formikHelpers.resetForm();
    }

    const handleEditVisiblePopUp = useCallback(() => {
        setOrgEditVisible(false)
    }, [])


    return (
        <>
            <Sharing
                dataSource={organizationList}
                columnList={tabColumnList}
                primaryColumn={"_id"}
                showEdit={false}
                showDelete={false}
                showView={false}
                showActions={true}
                keysToHide={['']}
                showUserEdit={true}
                showAdd={true}
                isOrderIconChange={true}
                columnAutoWidth={true}
                fromComponent={'UserComponent'}
                orderIndexedFields={orderIndexedFields}
                onSortingData={onSortingData}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                changePageSize={changePageSize}
                pageSize={pageSize}
                currentPageNo={currentPageNo}
                totalPage={totalPage}
                totalRecord={totalRecord}
                shareData={false}
                createContract={false}
                isLessthanTotalElement={isLessthanTotalElement()}
                viewCredentialApprove={OrganizationContract}
                viewCredentialApproveData={OrganizationDataCreate}
                viewCredentialExport={organizationVisibleEdit}
                viewCredentialExportData={organizationEditForm}
                fromOrganization={true}
            />
            <FormPopup
                title="Organization Details"
                visible={props.orgVisible}
                setVisible={() => props.setOrgVisible(false)}
                width={580}
                height={'auto'}
                onSave={confirmOrganization}
                wrapperAttr={{ class: 'organization-details' }}
            >
                <DataGrid
                    dataSource={[props.importedOrgDetails]}
                    rowRender={orgTable}
                    showColumnHeaders={false}
                    className="horizontal-grid"
                >
                    <Scrolling mode='virtual' />
                </DataGrid>
            </FormPopup>


            <ModalBox
                visible={orgEditVisible}
                onHiding={handleEditVisiblePopUp}
                showCloseButton={true}
                showTitle={true}
                dragEnabled
                title="Edit Organization Details"
                height={'auto'}
                width={480}
                wrapperAttr={{ class: 'edit-organization-details' }}
            >
                <Formik
                    initialValues={orgInitialValue}
                    validationSchema={validationSchema}
                    onSubmit={handleOrganizationSubmit}
                    enableReinitialize={true}
                >
                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }) => (
                        <form onSubmit={handleSubmit} className="dx-form">
                            <InputWithLabel
                                stylingMode='outlined'
                                inputLabel="Organization Name"
                                placeholder="Organization Name"
                                value={values.ORGName}
                                name="ORGName"
                                onInput={(e: any) => { handleChange(e.event) }}
                                onFocusOut={(e: any) => handleChange(e.event)}
                                error={touched.ORGName && errors.ORGName}
                                touched={touched.ORGName}
                            />

                            <InputWithLabel
                                stylingMode='outlined'
                                inputLabel="Chain Address"
                                placeholder="Chain Address"
                                value={values.ChainAddress}
                                name="ChainAddress"
                                onInput={(e: any) => { handleChange(e.event) }}
                                onFocusOut={(e: any) => handleChange(e.event)}
                                error={touched.ChainAddress && errors.ChainAddress}
                                touched={touched.ChainAddress}
                            />

                            <div className="org-btn">
                                <Button text="Cancel" className="edit-cancel" onClick={handleEditVisiblePopUp} />
                                <Button text="Save" useSubmitBehavior={true} type="default" className="btn-create" onClick={() => handleSubmit} />
                            </div>
                        </form>
                    )}
                </Formik>
            </ModalBox>
        </>
    )
}


const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationTable);


interface IOrganizationTableProps {
    toggleLoader?: any;
    orgVisible?: boolean;
    setOrgVisible?: React.SetStateAction<any>;
    importedOrgDetails?: any;
    handleOrganizationDetails?: any;
    setActiveTab?: React.SetStateAction<any>;
    setisVisisblepopup?: React.SetStateAction<any>;
    actionButtonHanlder?: any;
    forceUpdate?: boolean;
}
