import axios from 'axios';
import appConstants from '../../core/constants/appConstants';
import { GET_ALL_ENVELOPES } from '../types/envelope';
import { ERROR_GET_ERRORS } from '../types/errorTypes';
import { filterParams } from '../../utils/utilityFunctions';
import { JwtServices } from '../../services/jwt.services';

export const getEnvelopeList = (query: object) => (dispatch: any) => {
  const jwtService = new JwtServices();
  const token = jwtService.getJWTToken();
  axios.defaults.headers.common['Authorization'] = `${token}`;
  axios
    .get(`${appConstants.API_URL}/envelopes`, {
      params: filterParams({ ...query }),
      headers: { ETId: appConstants.SCHEMA_DATA.ETId, SV: appConstants.SCHEMA_DATA.SV }
    })
    .then((res: any) => {
      dispatch({ type: GET_ALL_ENVELOPES, payload: { data: res.data.data, total: res.data.total } });
    })
    .catch((err: any) => {
      console.log('pocActions -> getList -> error :', err);
      if (err.response && err.response.data) {
        console.log('pocActions -> err.response.data', err.response.data);
        dispatch({ type: ERROR_GET_ERRORS, payload: err.response.data });
      }
    });
}