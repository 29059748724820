import React from "react";
import './ProductRelease.scss'

const releaseUpcoming = [
    { id: 1, launch: "Platform Version 2.7.0", date: "01 Oct 2024", status: "Current" },
    { id: 2, launch: "FiVe Version 3.0.0.0", date: "01 Oct 2024", status: "Current" },
    { id: 3, launch: "Platform Version 2.5.1", date: "26 Jun 2024", status: "Previous" },
    { id: 4, launch: "FiVe Version 2.1.0.0", date: "26 Jun 2024", "status": "Previous" },
]

function ProductRelease() {
    return (
        <div className="release-card">
            <div className="title">Product Release Schedule</div>
            <div className="overflow-custom-table">
                <table className="custom-table">
                    <thead>
                        <tr>
                            <th><span>Launch</span></th>
                            <th><span>Date</span></th>
                            <th><span>Product Status</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {releaseUpcoming.map((item, index) => (
                            <tr key={item.id}>
                                <td><span className="version-launch">{item.launch}</span></td>
                                <td>{item.date}</td>
                                <td>{item.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProductRelease;