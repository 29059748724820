import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';
import RestApiService from '../../../../services/http-services';
import { useHistory } from 'react-router-dom';
import { ApiEndPoints } from '../../../../models/api-endpoint';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Toaster from '../../../../services/toaster.services';
import { toggleLoader } from '../../../../redux/actions/loader.action';
import "../ClusterCompareMultiSelect/ClusterCompareMultiSelect.scss"
import AppConstants from '../../../../core/constants/appConstants';
import Button from '../../../../shared/components/commonui/Button';
import NewSubHeaderComponent from '../../../../shared/components/new-sub-header/new-sub-header';
import { Form } from 'devextreme-react';
import { getSizeQualifier } from '../../../../utils/media-query';
import { ColCountByScreen, GroupItem, SimpleItem } from 'devextreme-react/form';
import DynamicTableList from '../../../../shared/components/commonui/DynamicTableList/DynamicTableList';
import FileDownloadService from '../../../../services/file-download-services';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete } from '@material-ui/icons';

type Props = {
    toggleLoader: any;
    history: any;
    fromComponent?: string;
    compareCallBack?: (nodeSetter: any) => void;
    dialogHandler?: (dialogState: boolean) => void;
}

const ClusterCompareMultiSelect = (props: Props) => {
    const [clusterList, setClusterList] = useState([]);
    const [nodeDropdownValue, setNodeDropdownValue] = useState([])
    const [clusterDropdownValue, setClusterDropdownValue] = useState([])
    const [nodeList, setNodeList] = useState([]);
    const [selectedList, setSelectedList] = useState([])
    const [nodeRenderList, setNodeRenderList] = useState([...nodeList, ...clusterList])
    const [comparisonData, setComparisonData] = useState([]);
    const [clusterShowTable, setClusterShowTable] = useState(false);

    let history = useHistory();
    let fileDownloadService = new FileDownloadService();
    const [clusterETId, clusterSV, nodeETId, nodeSV] = [AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.SV]

    useEffect(() => {
        getClusterList();
        getNodeList();
    }, [])

    function getClusterList() {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_CLUSTER_LIST, null, null, null, {
            ETId: clusterETId,
            SV: clusterSV
        }).then(res => {
            setClusterList(res.data.data);
            props.toggleLoader(false);
        }).catch((error) => {
            props.toggleLoader(false);
            console.log(error, "clusterCompare.tsx=>error")
            Toaster.errorToaster(error);
        })
    }

    function getNodeList() {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_NODE_LIST, null, null, null, {
            ETId: nodeETId,
            SV: nodeSV
        }).then(res => {
            const tempData = res.data.data;
            tempData.forEach((clusterData) => {
                Object.assign(clusterData, { ["ClusterName"]: clusterData["NodeName"] });
            });
            setNodeList(tempData);
            props.toggleLoader(false);
        }).catch((error) => {
            props.toggleLoader(false);
            console.log(error, "error")
            Toaster.errorToaster(error);
        })
    }

    function treeViewItemSelectionChanged(e: any) {

        if (e.component.getSelectedNodeKeys() !== undefined) {
            setNodeDropdownValue(e.component.getSelectedNodeKeys())
        }

        let result = nodeList.filter(object => {
            if (object.selected === true && object.ClusterUID) {
                return true;
            }

        }).map((object => {
            return { ...object, NodeName: object.NodeName };
        }));
        clusterList.forEach((clusterObject) => {
            result.forEach((nodeObject) => {
                if (clusterObject.UID === nodeObject.ClusterUID) {
                    nodeObject["NodeName"] = `${nodeObject.NodeName} (${clusterObject.ClusterName})`
                }
            }
            )
        });
        setSelectedList(result)

    }

    const NodeViewRender = () => {
        return (
            <TreeView
                dataSource={[...nodeList, ...nodeRenderList]}
                dataStructure="plain"
                keyExpr="UID"
                parentIdExpr="ClusterUID"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={true}
                displayExpr="ClusterName"
                selectByClick={true}
                onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        )
    }

    const clusterViewRender = () => {
        return (
            <TreeView
                dataSource={clusterList}
                keyExpr="UID"
                parentIdExpr="_id"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={false}
                displayExpr="ClusterName"
                selectByClick={true}
                onSelectionChanged={onClusterSelection}
            />
        )
    }
    const onClusterSelection = (e) => {
        let result = [...clusterList].filter(object => {
            return e.component.getSelectedNodeKeys().includes(object.UID);
        })
        setNodeRenderList(result.map((data) => {
            data.selected = false;
            return data
        }))
        if (e.component.getSelectedNodeKeys() !== undefined) {
            setClusterDropdownValue(e.component.getSelectedNodeKeys())
        }
    }
    function compareBtnHandler() {
        setComparisonData([])
        if (selectedList.length === 0 && nodeRenderList.length === 0) {
            return
        }

        if (selectedList?.length > 0) {
            setClusterShowTable(true);
            selectedList.forEach((cluster: any) => {
                getNodesInfo(cluster, cluster.NodeUrl);
            })
        }

        selectedList.forEach((cluster) => {
            if (cluster?.url) {
                getNodesInfo(cluster, cluster.url);
            }
            else {
                console.error("NodeUrl is undefined for a selected cluster");
            }

        });
    }

    const renderStatus = useCallback((item: any) => {
        return (
            <div className='statusData'>
                {
                    item.data.isDeleted ? <span className='up status-box-style'>Up</span> : <span className='down status-box-style'>Down</span>
                }
            </div>
        )
    }, []);


    const tableColumnList = useMemo(() => [
        { name: "Nodes", db: "NodeName", sort: false },
        { name: "BlockChain Type", db: "blockChainType", sort: false },
        { name: "Envelope Count", db: "envelopeCount", sort: false },
        { name: "Blockchain Height", db: "blockChainHeight", sort: false },
        { name: "Node Url", db: "NodeUrl" },
        { name: "Status", db: "isDeleted", cellRender: renderStatus }
    ], [renderStatus]);

    function nodeCompare(cluster, resData,nodetotalRecord) {
        return {
            ...cluster,
            ...resData,
            isDeleted: true,
            envelopeCount: nodetotalRecord
        }
    }
    const getNodesInfo = (cluster: any, url: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.BLOCKCHAIN_INFO, null, { clusterUrl: url }, null, null).then(res => {
            RestApiService.invoke(ApiEndPoints.GET_ENVELOPE_COUNT, null, { url: url }, null, {
                ETId: nodeETId,
                SV: nodeSV
            }).then(nodeResponse => {
                // setComparisonData((prev) => [...prev, { ...cluster, ...res.data.data, isDeleted: true, envelopeCount: nodeResponse.data.totalRecord }])
                const nodeCompresion = nodeCompare(cluster,res.data.data, nodeResponse.data.totalRecord);
                setComparisonData((prev) => [...prev, nodeCompresion])
                props.toggleLoader(false);

            }).catch(error => {
                props.toggleLoader(false);
                if (error.message === 'Network Error') {
                    Toaster.errorToasterWithId("getNodesInfo", error.message)
                }
                if (error?.response?.data?.errors?.length > 0) {
                    error.response.data.errors.forEach((response) => {
                        Toaster.errorToasterWithId("getNodesInfo", `${url} down`);
                    });
                }
            })
        }).catch(error => {
            setComparisonData((prev) => [...prev, {
                ...cluster,
                blockChainType: "N.A",
                blockChainHeight: "N.A",
                blockChainMempool: 'N.A',
                blockChainSize: "N.A",
                blockChainName: "N.A",
                envelopeCount: "N.A",
                isDeleted: false,
            }]);
            props.toggleLoader(false);
            if (error.message === 'Network Error') {
                Toaster.errorToaster(error.message)
            }
            if (error?.response?.data?.errors?.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToasterWithId("getNodesInfo", `${url} is down`);
                });
            }
        })
    }


    const downloadX = () => {
        fileDownloadService.arrayOfObjectToXLSXFileDownload(comparisonData, "Node Comparison Result.xlsx");
    }

    const handleAction = (item: any, action: string) => {
        if (action === 'delete') {
            const elementDeleted = comparisonData.filter((compareObject) => compareObject.UID !== item.UID);
            setComparisonData(elementDeleted);
        }
    }
    const ViewIconCellRenderer = useCallback((value: any): React.ReactNode => {
        return (
            <div className='role-icon'>
                <Tooltip title="Delete" placement="right">
                    < Delete color="primary" onClick={(e: any) => handleAction(value?.data, 'delete')} />
                </Tooltip>
            </div>
        )
    }, [handleAction]);


    const customActionColumnFormatters = [
        ViewIconCellRenderer
    ]


    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='setting-page'>
                        <div className="Innerheading  cluster-Compare dx-theme-background-color">
                            <div className='view-wrapper view-wrapper-user-details'>
                                {props.fromComponent === 'ComparisonTable' ? null : <NewSubHeaderComponent
                                    headingText={'Cluster Comparison'}
                                    isbackEnabled
                                    onBackButtonClick={() => history.goBack()}
                                />}
                                <div className="dx-theme-background-color Innerdetails dx-card">
                                    <Form id='form'
                                        labelMode='outside'
                                        showColonAfterLabel
                                        labelLocation='top'
                                        screenByWidth={getSizeQualifier}>
                                        <GroupItem itemType='group'>
                                            <ColCountByScreen xs={1} sm={2} md={3} lg={3} />
                                            <SimpleItem cssClass='accent' colSpan={1}>
                                                <div className='main-title'>
                                                    <span className='title'><strong>Select Cluster</strong></span>
                                                </div>
                                                <DropDownBox
                                                    value={clusterDropdownValue}
                                                    valueExpr="UID"
                                                    displayExpr="ClusterName"
                                                    placeholder="Select a value..."
                                                    stylingMode='outlined'
                                                    dataSource={clusterList}
                                                    contentRender={clusterViewRender}
                                                />
                                            </SimpleItem>
                                            <SimpleItem cssClass='accent' colSpan={1}>
                                                <div className='main-title'>
                                                    <span className='title'><strong>Select Node</strong></span>
                                                </div>
                                                <DropDownBox
                                                    value={nodeDropdownValue}
                                                    valueExpr="UID"
                                                    displayExpr="ClusterName"
                                                    placeholder="Select a value..."
                                                    stylingMode='outlined'
                                                    dataSource={nodeList}
                                                    contentRender={NodeViewRender}
                                                />
                                            </SimpleItem>

                                            <SimpleItem cssClass='accent' colSpan={1}>
                                                <Button
                                                    onClick={compareBtnHandler}
                                                    text="Compare"
                                                    type="default"
                                                    stylingMode="contained"
                                                    color='#fff'
                                                    className='btn-compare'
                                                />
                                            </SimpleItem>
                                        </GroupItem>

                                    </Form>
                                </div>
                            </div>



                            {
                                clusterShowTable && <div className='view-wrapper view-wrapper-cluster'>
                                    <div className='dx-card cluster-card'>
                                        <div className='cluster-header'>
                                            <div className='cluster-title'>
                                                <div className='title'>Comparison Result</div>
                                            </div>
                                            <div className='cluster-filter-btn'>
                                                <Button
                                                    type='default'
                                                    text='Export Results'
                                                    onClick={downloadX}
                                                />
                                            </div>
                                        </div>

                                        <div className='cluster-table-list'>
                                            <DynamicTableList
                                                dataSource={comparisonData}
                                                columnList={tableColumnList}
                                                columnAutoWidth={true}
                                                fromComponent={'SettingComponent'}
                                                showCustomActions
                                                customActionColumnFormatters={customActionColumnFormatters}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClusterCompareMultiSelect);


