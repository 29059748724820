import React, { useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    IconButton, Typography, Dialog, DialogTitle, DialogContent, TableContainer, Table, TableBody, TableRow, TableCell
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import "./Setting.scss";
import { ApiEndPoints } from '../../../models/api-endpoint';
import Toaster from '../../../services/toaster.services';
import { toggleLoader } from '../../../redux/actions/loader.action';
import RestApiService from '../../../services/http-services';
import DynamicList from '../../../shared/components/dynamic-list/dynamic-list';
import SubHeaderComponent from '../../../shared/components/sub-header/sub-header';
const SettingComponent = (props: settingProps) => {
    const [settings, setsettings] = React.useState([]);
    const [selectedSetting, setselectedSetting] = React.useState<any>();
    const [open, setOpen] = React.useState(false);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [pageSize] = React.useState(10);
    const [currentPageNo] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);
    const [totalRecord, setTotalRecord] = React.useState(0);
    const [order] = React.useState<any>('desc');
    const [orderBy] = React.useState<any>('CreatedAt');
    const filterType: any[] = ['All'];
    const [filterTypes, setfilterType] = React.useState([]);
    const [searchKey, setSearchKey] = React.useState<any>();
    const [status, setStatus] = React.useState('');
    const [isBindFilterTypes, setIsBindFilterTypes] = React.useState(true);
    
    const getSettings = (query, type?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.SETTINGS_USERS, null, null, query, {
            ETId: 49,
            SV: 1
        }).then(res => {
            props.toggleLoader(false);
            if (isBindFilterTypes) {
                res.data.data.data.forEach((item: any) => {
                    if (!filterType.includes(item.Type)) {
                        filterType.push(item.Type);

                    }
                });
                setfilterType(filterType);
                setIsBindFilterTypes(false);
            }
            setsettings(res.data.data.data);
            setTotalRecord(res.data.totalRecord);
            if (parseInt(res.data.totalRecord) % pageSize === 0) {
                setTotalPage((parseInt(res.data.totalRecord) / pageSize));
            }
            else {
                setTotalPage((Math.floor(parseInt(res.data.totalRecord) / pageSize)) + 1);
            }

        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }

    const handleClose = (text: string) => {
        if (text === 'setting') {
            setOpen(false);
        } else {
            setOpenSetting(false);
        }
    };

    const handleAction = (item, action) => {
        if (action == 'view') {
            setselectedSetting(item);
            setOpen(true);
        }
    }

    useEffect(() => {
        if (pageSize && currentPageNo && orderBy && order) {
            getSettings({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true });
        }
    }, [pageSize, currentPageNo, order, orderBy]);
    const onFilterUserStatus = (status: any) => {
        setStatus(status);
        getSettings({
            pageNo: currentPageNo, pageSize: pageSize,
            orderBy: orderBy, order: order, totalReq: true, searchKey: searchKey, filterStatus: status
        })

    }
    const onSearchData = (searchData: any) => {
        setSearchKey(searchData);
        getSettings({ searchKey: searchData, filterStatus: status, pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true });
    }

    const onCancelSearch = (value: any) => {
        setSearchKey('');
        if (value) {
            getSettings({ filterStatus: status, pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true });
        }
    }

    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='setting-page'>
                        <div className="Innerheading">
                            <SubHeaderComponent
                                headingText={'Configuration Settings'}
                                btnText={'Add setting'}
                                searchLabel={'Name'}
                                showBtn={false}
                                showSearch={true}
                                searchKey={searchKey}
                                showFilter={true}
                                filterHeading={'Filter By Type:'}
                                filterArray={filterTypes}
                                filterUserStatus={(status: any) => onFilterUserStatus(status)}
                                onSearchData={(searchData: any) => onSearchData(searchData)}
                                onCancelSearch={(value: any) => onCancelSearch(value)} />
                            <div className="Innerdetails">

                                <div className="grid">
                                    <DynamicList
                                        dataList={settings}
                                        columnList={[{ name: "Common Name", db: "Comman_Name", sort: true }, { name: "Current Value", db: "Current_Value", sort: true }, { name: "Type", db: "Type", sort: true }, { name: "Start Time", db: "CreatedAt", sort: true }]}
                                        primaryColumn={"id"}
                                        orderIndexedFields={false}
                                        onSortingData={false}
                                        handleAction={(item: any, action: string) => handleAction(item, action)}
                                        showEdit={false}
                                        showAdd={false}
                                        showDelete={false}
                                        showView={true}
                                        showActions={true}
                                        showChangePassword={false}
                                        isOrderIconChange={true}
                                        keysToHide={['']}
                                        fromComponent={'SettingComponent'}
                                        goToPreviousPage={false}
                                        goToNextPage={false}
                                        changePageSize={false}
                                        pageSize={pageSize}
                                        currentPageNo={currentPageNo}
                                        totalPage={totalPage}
                                        totalRecord={totalRecord}
                                        isLessthanTotalElement={false}
                                        SNo={false}
                                        showPagination={false}
                                    />
                                </div>

                                {selectedSetting && <Dialog onClose={() => handleClose('setting')} aria-labelledby="customized-dialog-title" open={open} className="modalpopoup" fullWidth={true} maxWidth={"md"}>
                                    <DialogTitle disableTypography >
                                        <Typography variant="h6">Configuration Settings Detail</Typography>
                                        <IconButton aria-label="close" onClick={() => handleClose('setting')}>
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent dividers >
                                        <Typography gutterBottom>
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell><strong>Common Name</strong></TableCell>
                                                            <TableCell>{selectedSetting.Comman_Name}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><strong>Current Value</strong></TableCell>
                                                            <TableCell>{selectedSetting.Current_Value}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><strong>Type</strong></TableCell>
                                                            <TableCell>{selectedSetting.Type}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><strong>Start Time</strong></TableCell>
                                                            <TableCell>{moment.utc(selectedSetting.CreatedAt).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Typography>

                                    </DialogContent>
                                </Dialog>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingComponent);


interface settingProps {
    toggleLoader: any;
}