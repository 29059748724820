import React from "react";

interface IIconProps {
    iconName?: string,
    size?: string,
    onClick?: () => void,
    className?: string,
    id?:string,
}

function Icon({ iconName, size = "50px", onClick,id,className, ...props }: IIconProps) {
    const style = { "fontSize": size };
    return (
        <i style={style} onClick={onClick} id={id} aria-hidden='true' className={`dx-icon-${iconName} ${className}`} {...props}></i>
    )
}

export default Icon